import React, { useEffect } from "react";
import mainImg from "../../Assests/Services/serviceBanner.png";
import Banner from "../Banner";
import "./service.css";
import info1 from "../../Assests/Services/madInfo1.png";
import info2 from "../../Assests/Services/madInfo2.png";
import info3 from "../../Assests/Services/madInfo3.png";
import info4 from "../../Assests/Services/madInfo4.png";
import info5 from "../../Assests/Services/madInfo5.png";
import { Link } from "react-router-dom";

function MobileAppDev() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Services" />
        {/* <div className="service-main-container"> */}
        <div className="below-banner-mad-container">
          <h1 className="product-banner-heading">
            Mobile Application Development
          </h1>
          <div className="product-inner-container">
            <div className="service-para mt-4 mb-3">
              Crafting mobile applications that resonate with users and drive
              business success is our forte at Aeon. In today's mobile-centric
              world, having a powerful mobile app can be the key to staying
              ahead of the competition and connecting with your customers on a
              deeper level. That's where we come in.
            </div>
            <div className="mad-info-1">
              <img src={info1} alt="Mobile Application Development" />
            </div>
          </div>
        </div>
        <div className="mad-service-second-container">
          <div className="product-banner-heading service-third-heading">
            Our Mobile Application Development Services
          </div>
          <div className="mad-service-info2">
            <img src={info2} alt="mobile app services" />
          </div>
        </div>
        <div className="below-banner-mad-container">
          <div className="product-banner-heading service-third-heading mad-third-heading">
            Mobile Application Development Process:
          </div>
          <div className="mad-service-info2">
            <img src={info3} alt="app development process" />
          </div>
        </div>
        <div className="mad-service-third-container">
          <div className="row" style={{ marginBottom: "100px" }}>
            <div className="col-lg-5 d-flex justify-content-center align-items-center">
              Client Success
            </div>
            <div className="col-lg-7">
              <img style={{ width: "80%" }} src={info4} alt="tmt snt" />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-5 d-flex justify-content-center align-items-center">
              Our Projects
            </div>
            <div className="col-lg-7">
              <img style={{ width: "80%" }} src={info5} alt="tmt snt locbus" />
            </div>
          </div>
        </div>
        <div className="below-banner-mad-container">
          <div className="contact-us-end-container">
            <span className="product-para">
              A number of state transport service providers are bringing their
              operations online using our customizable solutions.
            </span>
            <span className="contact-us-end-text">
              Let us be your partner in bringing your app idea to life and
              creating a mobile experience that sets you apart from the crowd.
            </span>
            <Link className="contactUs-link" to="/contact-us">
              Contact Us!
            </Link>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
}

export default MobileAppDev;
