import React, { useEffect, useState, useCallback } from "react";
import Banner from "../Banner";
import mainImg from "../../Assests/Domains/agriDomainImg.png";
import agriImg from "../../Assests/Domains/agricultureImg.png";
import { Link } from "react-router-dom";
import ReactCardFlip from "react-card-flip";
import info1 from "../../Assests/Domains/agriInfo1.png";
import info2 from "../../Assests/Domains/agriInfo2.png";
import info3 from "../../Assests/Domains/agriInfo3.png";
import info4 from "../../Assests/Domains/agriInfo4.png";
import info5 from "../../Assests/Domains/agriInfo5.png";
import info6 from "../../Assests/Domains/agriInfo6.png";
import info7 from "../../Assests/Domains/agriInfo7.png";
import info8 from "../../Assests/Domains/agriInfo8.png";
import info9 from "../../Assests/Domains/agriInfo9.png";
import info10 from "../../Assests/Domains/agriInfo10.png";
import ExploreDomains from "./ExploreDomains";
import domainVideo from "../../Assests/Domains/Videos/Aeon Agriculture.mp4";
import Modal from "react-bootstrap/Modal";

function Agriculture() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const cards = [
    {
      cardImg: info1,
      frontText: "Integration with IoT Sensors and Devices",
      backText:
        "Utilize smart farming technologies to monitor soil moisture, crop health, and livestock conditions in real-time.",
    },
    {
      cardImg: info2,
      frontText: "Field Mapping and GPS Integration",
      backText:
        "Leverage precision agriculture techniques to map fields accurately, optimize planting patterns, and reduce waste",
    },
    {
      cardImg: info3,
      frontText: "Weather Monitoring and Forecasting",
      backText:
        "Access real-time weather data and forecasts to make informed decisions about planting, irrigation, and harvesting.",
    },
    {
      cardImg: info4,
      frontText: "Crop Monitoring and Management",
      backText:
        "Implement advanced crop management systems to monitor growth stages, detect diseases early, and optimize yield.",
    },
    {
      cardImg: info5,
      frontText: "Automation",
      backText:
        "Automate routine farming tasks such as irrigation, fertilization, and pest control to increase efficiency and reduce labor costs.",
    },

    {
      cardImg: info6,
      frontText: "Resource Management",
      backText:
        "Manage water, nutrients, and other resources effectively to ensure sustainable and efficient use.",
    },
    {
      cardImg: info7,
      frontText: "Supply Chain Management",
      backText:
        "Streamline the supply chain from farm to market, reducing delays and ensuring the freshness of produce.",
    },
    {
      cardImg: info8,
      frontText: "Market Analysis",
      backText:
        "Utilize data analytics to understand market trends, demand patterns, and pricing strategies.",
    },
    {
      cardImg: info9,
      frontText: "Data Reporting and Intelligence",
      backText:
        "Access comprehensive reports and data insights to make data-driven decisions and improve overall farm management",
    },
    {
      cardImg: info10,
      frontText: "Support and Training",
      backText:
        "Benefit from our extensive support and training programs to get the most out of our solutions.",
    },
  ];

  const [flippedStates, setFlippedStates] = useState(
    Array(cards.length).fill(false)
  );

  const handleMouseEnter = (index) => {
    setFlippedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = true;
      return newStates;
    });
  };

  // const handleClick = (index) => {
  //   setFlippedStates((prevStates) => {
  //     const newStates = [...prevStates];
  //     newStates[index] = !newStates[index];
  //     return newStates;
  //   });
  // };

  const handleMouseLeave = (index) => {
    setFlippedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = false;
      return newStates;
    });
  };

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  return (
    <div>
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Domains" />
        <div className="below-banner-container domain-main-container">
          <h1 className="product-banner-heading">Agriculture</h1>
          <div className="product-inner-container">
            <div className="product-para mt-4 mb-4">
              Agriculture, as an industry, is still largely dependent on
              traditional methods of farming and human labor. Integration of
              technology into agriculture may seem like a far-fetched idea to
              some, but at Aeon, we believe it is not only possible but
              essential. Our solutions have become an empowering force for
              farmers and agribusinesses, helping them optimize operations,
              increase productivity, and maximize profitability.
            </div>
            <div className="domain-info1">
              <div className="domain-info1-img mb-5">
                <img src={agriImg} alt="aviation" />
                <div className="circle-anim" onClick={handleShow}>
                  <div className="discover-now-btn">
                    <div className="step">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Bold"
                        viewBox="0 0 24 24"
                        width="60"
                        height="35"
                        fill="#37466F"
                      >
                        <path d="M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z" />
                      </svg>
                      <div className="ripple"></div>
                      <div className="ripple"></div>
                      <div className="ripple"></div>
                    </div>
                  </div>
                </div>
                <div className="opaque-screen domain-opaque-film"></div>
                <Modal show={show} onHide={handleClose} centered size="lg">
                  <Modal.Body className="domain-video-modal">
                    <div>Click outside the box to close</div>
                    <video
                      src={domainVideo}
                      autoPlay
                      loop
                      controls
                      controlsList="nodownload"
                      muted
                    />
                  </Modal.Body>
                </Modal>
              </div>
            </div>
            <div className="sub-heading-product  mb-2">
              Our Commitment to Sustainable and Efficient Agriculture
            </div>
            <div className="product-para mb-4">
              With a focus on sustainability and efficiency, we aim to
              revitalize agriculture by providing innovative solutions:
            </div>

            <div className="flipcards-main-container">
              {cards.map((card, index) => (
                <div
                  key={index}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave(index)}
                  className="flipcards-agri-container"
                >
                  <ReactCardFlip
                    isFlipped={flippedStates[index]}
                    flipDirection="horizontal"
                  >
                    <div className="front-card card">
                      <div className="card-content">
                        <img
                          className="card-content-img"
                          src={card.cardImg}
                          alt="agriculture"
                        />
                        <p>{card.frontText}</p>
                      </div>
                    </div>
                    <div className="back-card card">
                      <div className="card-content">
                        <p>{card.backText}</p>
                      </div>
                    </div>
                  </ReactCardFlip>
                </div>
              ))}
            </div>
            <div className="sub-heading-product mt-5 mb-2">
              Our Current Focus
            </div>
            <div className="product-para mb-4">
              We are currently developing assignment-based software solutions
              for a major agricultural giant in the US. This software is
              designed to streamline their operations, improve task management,
              and enhance overall productivity. Soon, we will become their India
              operations partner, further expanding our impact and presence in
              the agricultural technology sector.
            </div>
            <div className="sub-heading-product mt-5 mb-2">
              The Future of Agritech with Aeon
            </div>
            <div className="contact-us-end-container">
              <div className="product-para">
                At Aeon, we are at the forefront of the Agritech revolution. Our
                goal is to harness the power of technology to create smarter,
                more sustainable agricultural practices. By integrating
                cutting-edge technologies into traditional farming methods, we
                aim to improve efficiency, reduce environmental impact, and
                ensure food security for future generations.
              </div>
              <span className="contact-us-end-text">
                Join us on this journey and see how our technology can transform
                your agricultural practices, making them more efficient,
                profitable and sustainable.
              </span>
              <Link className="contactUs-link" to="/contact-us">
                Get in touch!
              </Link>
            </div>
            <div className="explore-domains">
              <div className="heading">
                <h2>Explore Our Other Domains</h2>
              </div>
              <ExploreDomains />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Agriculture;
