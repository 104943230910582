import React, { useEffect, useState } from "react";
import mainImg from "../../Assests/Domains/evDomainImg.png";
import "./domain.css";
import elecricVehicleImg from "../../Assests/Domains/elecricVehicle.png";
import Banner from "../Banner";
import { Link } from "react-router-dom";
import info1 from "../../Assests/Domains/evInfo1.png";
import info2 from "../../Assests/Domains/evInfo2.png";
import info3 from "../../Assests/Domains/evInfo3.png";
import info4 from "../../Assests/Domains/evInfo4.png";
import ExploreDomains from "./ExploreDomains";
import Aos from "aos";
import "aos/dist/aos.css";
import domainVideo from "../../Assests/Domains/Videos/Aeon Electric Vehicle.mp4";
import Modal from "react-bootstrap/Modal";

function ElecricVehicle() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);
  return (
    <div>
      <div className="aboutUs-main-container miningDomain">
        <Banner mainImg={mainImg} title="Domains" />
        <div className="below-banner-container domain-main-container">
          <h1 className="domain-banner-heading">Electric Vehicle</h1>
          <div className="domain-inner-container">
            <div className="domain-para">
              With a worldwide push towards sustainability and climate friendly
              alternatives, the e-vehicle industry is all set to become the next
              big thing. Aeon has worked with up-and-coming players in this
              industry, helping them with web and cloud based solutions to
              establish their place in the market.
            </div>

            <div className="domain-info1">
              <div className="domain-info1-img mining">
                <img src={elecricVehicleImg} alt="Electric Vehicle" />
                <div className="circle-anim" onClick={handleShow}>
                  <div className="discover-now-btn">
                    <div className="step">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Bold"
                        viewBox="0 0 24 24"
                        width="60"
                        height="35"
                        fill="#37466F"
                      >
                        <path d="M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z" />
                      </svg>
                      <div className="ripple"></div>
                      <div className="ripple"></div>
                      <div className="ripple"></div>
                    </div>
                  </div>
                </div>
                <div className="opaque-screen domain-opaque-film"></div>
                <Modal show={show} onHide={handleClose} centered size="lg">
                  <Modal.Body className="domain-video-modal">
                    <div>Click outside the box to close</div>
                    <video
                      src={domainVideo}
                      autoPlay
                      loop
                      controls
                      controlsList="nodownload"
                      muted
                    />
                  </Modal.Body>
                </Modal>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div
                  className="domain-descrip-left evDomain-descrip-left"
                  data-aos="fade-right"
                >
                  <h1 className="sub-heading-product product-descrip-heading">
                    Increase engagement
                  </h1>
                  <div className="product-para">
                    Creating user-friendly web and mobile app interfaces for
                    your customers, our team of capable tech and marketing
                    professionals can help you reach wider audiences. Our
                    customizable ERPs can help you implement sales and service
                    management solutions to improve user experience.
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mining-img" data-aos="fade-left">
                  <img src={info1} alt="Increase engagement" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mining-img" data-aos="fade-right">
                  <img src={info2} alt="Streamlined logistics" />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="domain-descrip-left evDomain-descrip-left"
                  data-aos="fade-left"
                >
                  <h1 className="sub-heading-product product-descrip-heading">
                    Streamlined logistics
                  </h1>
                  <div className="product-para">
                    With solutions to monitor the worldwide production, storage
                    and distribution of your EVs, our ERP management tools allow
                    you to seamlessly scale your business to match up to the
                    demands of your consumer base.
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="domain-descrip-left evDomain-descrip-left"
                  data-aos="fade-right"
                >
                  <h1 className="sub-heading-product product-descrip-heading">
                    Intelligent interfaces
                  </h1>
                  <div className="product-para">
                    Dashboard software integrations allow users to get real-time
                    insights into vehicle status, GPS location, energy
                    consumption, driving range distance, and charging
                    performance. This information can also be made available on
                    a mobile app, along with a find-my-vehicle feature. Along
                    with functions like charging management, charging station
                    locator and integration with home charging solutions, the
                    combined look and feel of the vehicle maximizes the usage
                    and efficiency of the EVs.
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className=" mining-img" data-aos="fade-left">
                  <img src={info3} alt="Intelligent interfaces" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className=" mining-img" data-aos="fade-right">
                  <img src={info4} alt="Fleet management systems" />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="domain-descrip-left evDomain-descrip-left"
                  data-aos="fade-left"
                >
                  <h1 className="sub-heading-product product-descrip-heading">
                    Fleet management systems
                  </h1>
                  <div className="product-para">
                    For public transport EVs, integrated functionalities such as
                    route optimization, driver behavior monitoring, vehicle
                    diagnostics, and predictive maintenance are implemented to
                    reduce operating costs and maximize fleet efficiency.
                    Centralized management tools can increase logistical
                    efficiency while providing a delightful passenger
                    experience.
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="contact-us-end-container">
                  <span className="product-para">
                    A number of state transport service providers are bringing
                    their operations online using our customizable solutions.
                  </span>
                  <span className="contact-us-end-text">
                    Let us know how we can help.
                  </span>
                  <Link className="contactUs-link" to="/contact-us">
                    Get in touch!
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="explore-domains">
            <div className="heading">
              <h2>Explore Our Other Domains</h2>
            </div>
            <ExploreDomains />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ElecricVehicle;
