import React, { useEffect } from "react";
import mainImg from "../../Assests/Services/serviceBanner.png";
import Banner from "../Banner";
import "./service.css";
import { Link } from "react-router-dom";
import info1 from "../../Assests/Services/sisInfo1.png";
import info2 from "../../Assests/Services/sisInfo2.png";
import info3 from "../../Assests/Services/sisInfo3.png";
import info4 from "../../Assests/Services/sisInfo4.png";
import info5 from "../../Assests/Services/sisInfo5.png";
import arrowImg from "../../Assests/arrowImg.png";

function SystemIntegration() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Services" />
        <div className="below-banner-mad-container">
          <h1 className="product-banner-heading">
            System Integration Services (SIS)
          </h1>
          <div className="service-para">
            In today's digital world, businesses rely on multiple systems and
            applications to keep things running smoothly. But let's be honest,
            getting all these different systems to "talk" to each other can be a
            real headache. That's where Aeon's System Integration Services (SIS)
            come in – we're the translators that help your systems communicate
            effectively, allowing your business to operate like a well-oiled
            machine. it.
          </div>
        </div>
        <div className="mad-service-second-container">
          <div className="product-banner-heading mad-third-heading dls-second-heading mb-5">
            How We Do It
          </div>
          <div className="text-start pmc-approach-points-container">
            <div className="pmc-approach-points-heading">
              1. Getting to Know Your Systems
            </div>
            <div className="pmc-approach-points mx-3 d-flex">
              <div className="bullet-point"></div>
              <span>
                We start by getting up close and personal with your existing
                systems, applications, and data sources. Think of it as a
                friendly introduction before we play matchmaker.
              </span>
            </div>
            <div className="pmc-approach-points mx-3 d-flex mt-3">
              <div className="bullet-point"></div>
              <span>
                We also take a good look at how your business processes work, so
                we understand where and how your systems need to connect.
              </span>
            </div>
          </div>
          <div className="text-start pmc-approach-points-container mt-5">
            <div className="pmc-approach-points-heading">
              2. Designing the Perfect Integration Plan
            </div>

            <div className="pmc-approach-points mx-3 d-flex">
              <div className="bullet-point"></div>
              <span>
                Based on our discoveries, we architect a robust integration plan
                that's tailored just for you. Think of it as a customized
                blueprint for your systems to live in harmony.
              </span>
            </div>
            <div className="pmc-approach-points d-flex mx-3 mt-3">
              <div className="bullet-point"></div>
              <span>
                We'll recommend the best integration platform or middleware
                solution, so your systems have a common language to communicate
                in.
              </span>
            </div>
          </div>
          <div className="text-start pmc-approach-points-container mt-5">
            <div className="pmc-approach-points-heading">
              3. Making the Connections
            </div>

            <div className="pmc-approach-points mx-3 d-flex">
              <div className="bullet-point"></div>
              <span>
                Our team of integration experts will map and transform your
                data, so all your systems can understand each other, no matter
                their native language.
              </span>
            </div>
            <div className="pmc-approach-points d-flex mx-3 mt-3">
              <div className="bullet-point"></div>
              <span>
                We'll develop custom interfaces and adapters to facilitate
                seamless communication and data exchange between your systems.
              </span>
            </div>
            <div className="pmc-approach-points d-flex mx-3 mt-3">
              <div className="bullet-point"></div>
              <span>
                And of course, we'll rigorously test everything to ensure your
                integrated systems are reliable and secure.
              </span>
            </div>
          </div>
          <div className="text-start pmc-approach-points-container mt-5">
            <div className="pmc-approach-points-heading">
              4. Keeping Things Running Smoothly
            </div>

            <div className="pmc-approach-points mx-3 d-flex">
              <div className="bullet-point"></div>
              <span>
                Once we've connected your systems, we'll coordinate the
                deployment and integration, ensuring minimal disruption to your
                operations.
              </span>
            </div>
            <div className="pmc-approach-points d-flex mx-3 mt-3">
              <div className="bullet-point"></div>
              <span>
                But our job doesn't end there. Our support team will keep a
                watchful eye on your integrated systems, providing proactive
                maintenance and troubleshooting whenever needed.
              </span>
            </div>
          </div>
        </div>
        <div className="below-banner-mad-container">
          <div className="product-banner-heading mad-third-heading dls-second-heading mb-5">
            Our Integration Expertise
          </div>
          <div className="pd-first-info d-flex justify-content-center align-items-center sis-expertise">
            <div>
              <img src={info1} alt="System Integration Service" />
            </div>
            <div>
              <img src={info2} alt="System Integration Service" />
            </div>
            <div>
              <img src={info3} alt="System Integration Service" />
            </div>
            <div>
              <img src={info4} alt="System Integration Service" />
            </div>
            <div>
              <img src={info5} alt="System Integration Service" />
            </div>
          </div>
          <div className="product-banner-heading text-start dls-second-heading mb-5">
            Why Choose Aeon for System Integration?
          </div>
          <div className="why-sis-container">
            <div className="text-start pmc-approach-points-container">
              <div className="pmc-approach-points mx-3 d-flex align-items-start">
                <div className="arrow-point">
                  <img src={arrowImg} alt="arrowImg" />
                </div>
                <span className="service-para">
                  Our battle-tested integration methodology has proven
                  successful time and time again.
                </span>
              </div>
              <div className="pmc-approach-points mx-3 d-flex align-items-start mt-4">
                <div className="arrow-point">
                  <img src={arrowImg} alt="arrowImg" />
                </div>
                <span className="service-para">
                  We're vendor-agnostic, so you can trust our unbiased
                  recommendations.
                </span>
              </div>
              <div className="pmc-approach-points mx-3 d-flex align-items-start mt-4">
                <div className="arrow-point">
                  <img src={arrowImg} alt="arrowImg" />
                </div>
                <span className="service-para">
                  Our solutions are designed to grow with your business,
                  ensuring long-term compatibility.
                </span>
              </div>
              <div className="pmc-approach-points mx-3 d-flex align-items-start mt-4">
                <div className="arrow-point">
                  <img src={arrowImg} alt="arrowImg" />
                </div>
                <span className="service-para">
                  Our integration specialists bring deep industry knowledge to
                  the table.
                </span>
              </div>
              <div className="pmc-approach-points mx-3 d-flex align-items-start mt-4">
                <div className="arrow-point">
                  <img src={arrowImg} alt="arrowImg" />
                </div>
                <span className="service-para">
                  We're committed to quality, ensuring the reliability and
                  security of your integrated systems.{" "}
                </span>
              </div>
            </div>
          </div>
          <div className="contact-us-end-container mt-5">
            <span className="service-para">
              Ready to bring harmony to your system chaos? Get in touch with us
              today, and let's start connecting the dots for a more efficient
              and streamlined digital ecosystem in your business.
            </span>

            <Link className="contactUs-link" to="/contact-us">
              Contact Us!
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SystemIntegration;
