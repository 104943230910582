import React, { useEffect, useState } from "react";
import mainImg from "../../Assests/Domains/foodDomainImg.png";
import "./domain.css";
import FoodManufacturingImg from "../../Assests/Domains/foodManufacturing.png";
import Banner from "../Banner";
import info3 from "../../Assests/Domains/foodInfo3.png";
import info1 from "../../Assests/Domains/foodInfo1.png";
import { Link } from "react-router-dom";
import info2 from "../../Assests/Domains/foodInfo2.png";
import ExploreDomains from "./ExploreDomains";
import Aos from "aos";
import "aos/dist/aos.css";
import domainVideo from "../../Assests/Domains/Videos/Aeon Food.mp4";
import Modal from "react-bootstrap/Modal";

function FoodManufacturing() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);
  return (
    <div>
      <div className="aboutUs-main-container miningDomain">
        <Banner mainImg={mainImg} title="Domains" />
        <div className="below-banner-container domain-main-container">
          <h1 className="domain-banner-heading">Food Manufacturing</h1>
          <div className="domain-inner-container">
            <div className="domain-para">
              Dealing with a high volume of perishable goods, time becomes a
              deciding factor in the Food manufacturing industry. At Aeon, we
              know how much efficiency in the supply chain matters to meet the
              demand for fresh, ready-to-eat food products. With our resource
              management solutions, we help optimize your processes and minimize
              wastage.
            </div>

            <div className="domain-info1">
              <div className="domain-info1-img mining">
                <img src={FoodManufacturingImg} alt="Food Manufacturing" />
                <div className="circle-anim" onClick={handleShow}>
                  <div className="discover-now-btn">
                    <div className="step">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Bold"
                        viewBox="0 0 24 24"
                        width="60"
                        height="35"
                        fill="#37466F"
                      >
                        <path d="M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z" />
                      </svg>
                      <div className="ripple"></div>
                      <div className="ripple"></div>
                      <div className="ripple"></div>
                    </div>
                  </div>
                </div>

                <div className="opaque-screen domain-opaque-film"></div>
                <Modal show={show} onHide={handleClose} centered size="lg">
                  <Modal.Body className="domain-video-modal">
                    <div>Click outside the box to close</div>
                    <video
                      src={domainVideo}
                      autoPlay
                      loop
                      controls
                      controlsList="nodownload"
                      muted
                    />
                  </Modal.Body>
                </Modal>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="domain-descrip-left" data-aos="fade-right">
                  <h1 className="sub-heading-product product-descrip-heading">
                    Optimize production
                  </h1>
                  <div className="product-para">
                    Track all your operations conveniently with our material
                    management tool. Easily manage your production line
                    processes like raw material sourcing, processing, packaging,
                    and quality checks.
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className=" mining-img" data-aos="fade-left">
                  <img src={info1} alt="Optimize production" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className=" mining-img" data-aos="fade-right">
                  <img src={info2} alt="Monitor Product movement" />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="domain-descrip-left" data-aos="fade-left">
                  <h1 className="sub-heading-product product-descrip-heading">
                    Monitor Product movement
                  </h1>
                  <div className="product-para">
                    Take stock of your inventory, raise supply orders, or create
                    automated invoices, with easy-to-use inventory management
                    and sales distribution tools. Streamline transport and
                    logistics efficiently to ensure timely product deliveries
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="domain-descrip-left" data-aos="fade-right">
                  <h1 className="sub-heading-product product-descrip-heading">
                    Plan ahead!
                  </h1>
                  <div className="product-para">
                    Use our business intelligence tools to gather insight into
                    consumption and demand data to understand and effectively
                    match up your supply. Use targeted marketing strategies and
                    seamlessly upscale distribution by employing AI analytics
                    and Big data computations.
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className=" mining-img" data-aos="fade-left">
                  <img src={info3} alt="business intelligence tools" />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="contact-us-end-container">
                  <span className="product-para">
                    At Aeon, we have a solution for all your business demands.
                  </span>
                  <span className="contact-us-end-text">
                    Join us in revolutionizing the way food is created,
                    distributed and enjoyed.
                  </span>
                  <Link className="contactUs-link" to="/contact-us">
                    Get in touch!
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="explore-domains">
            <div className="heading">
              <h2>Explore Our Other Domains</h2>
            </div>
            <ExploreDomains />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FoodManufacturing;
