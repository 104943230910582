import React, { useEffect } from "react";
import mainImg from "../../Assests/Services/serviceBanner.png";
import Banner from "../Banner";
import "./service.css";
import phoneImg from "../../Assests/Domains/transportInfo3.png";
import idms0 from "../../Assests/Services/idms0.png";
import idms01 from "../../Assests/Services/idms01.png";
import idms1 from "../../Assests/Services/idms1.png";
import idms2 from "../../Assests/Services/idms2.png";
import idms3 from "../../Assests/Services/idms3.png";
import idms4 from "../../Assests/Services/idms4.png";
import idms5 from "../../Assests/Services/idms5.png";
import idms6 from "../../Assests/Services/idms6.png";
import idms7 from "../../Assests/Services/idms7.png";
import idms8 from "../../Assests/Services/idms8.png";
import clientNoteImg from "../../Assests/Services/idms-client-note.png";
import tmtLogo from "../../Assests/Services/tmtLogo.png";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

function Idms() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Services" />
        <div className="below-banner-container service-main-container">
          <div className="product-banner-heading">
            Integrated Depot Management Solution
          </div>
          <div className="product-inner-container row px-5">
            <div className="col-lg-6 d-flex justify-content-center align-items-center">
              <div className="idms-head-text">
                Have you considered ways to make travel better for passengers
                and run depot operations more efficiently?
              </div>
            </div>
            <div className="col-lg-6 d-flex justify-content-end align-items-center">
              <img className="idms0" src={idms0} alt="idmsImg" />
            </div>
          </div>
        </div>
        <div className="idms-service-second-container">
          <div className="product-banner-heading service-second-heading">
            Integrated Mobility Solutions
          </div>
          <div className="product-inner-container service-inner-container">
            <div className="idms01">
              <img src={idms01} alt="someImg" />
            </div>
            <div className="service-sub-heading">
              Fluid transportation for people and operations - the smarter way
              to go.
            </div>
          </div>
        </div>
        <div className="below-banner-container service-main-container">
          <div className="product-banner-heading service-third-heading">
            <span>Upgrade Your Public</span>
            <span> Transportation Services</span>
          </div>
          <div className="product-inner-container row">
            <div className="col-lg-6 ldms-images">
              <img src={phoneImg} alt="e-ticketing" />
            </div>
            <div className="col-lg-6 idms-descrip">
              <div className="service-para">
                Our solutions streamline fleet, route, schedule, and fare
                management, tailored to your specific needs, whether you're a
                local transit agency or a private transportation provider.
              </div>
            </div>
          </div>
        </div>
        <div className="idms-service-fourth-container">
          <div className="product-banner-heading service-third-heading">
            Our Achievements
            <span className="achievement-border"></span>
          </div>
          <div className="achievement-container">
            <div className="row">
              <div className="col-lg-6 d-flex justify-content-center align-items-start">
                <div className="achievements-text">
                  Aeon has won the <span className="green-highlight">MoUD</span>{" "}
                  award for the best{" "}
                  <span className="green-highlight">
                    Intelligent Transport System Project
                  </span>{" "}
                  for its{" "}
                  <span className="green-highlight">
                    Sikkim Nationalised Transport IDMS{" "}
                  </span>
                  project.
                </div>
              </div>
              <div className="col-lg-6 d-flex justify-content-center align-items-start">
                <div className="achievements-text">
                  Aeon has been awarded the{" "}
                  <span className="green-highlight">SKOCH</span> award for its{" "}
                  <span className="green-highlight">
                    ITS solution implemented
                  </span>{" "}
                  for{" "}
                  <span className="green-highlight">
                    Thane Municipal Transport (TMT).
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="below-banner-container service-main-container idms-service-fifth-container">
          <div className="product-banner-heading service-third-heading">
            Why Mobility Solution?
          </div>

          <div className="idms-service-infograph row">
            <div
              className="col-lg-4 idms-infograph"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <img src={idms1} alt="System Maintenance" />
              <span>Major reduction in System Maintenance</span>
            </div>
            <div
              className="col-lg-4 idms-infograph"
              data-aos="fade-zoom-in"
              data-aos-duration="1500"
            >
              <img src={idms2} alt="data protection" />
              <span>Highly secured data protection</span>
            </div>
            <div
              className="col-lg-4 idms-infograph"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <img src={idms3} alt="Disaster recovery" />
              <span>
                Highly scalable and very easy Disaster recovery in case of an
                emergency
              </span>
            </div>
          </div>
        </div>
        <div className="idms-service-sixth-container">
          <div className="product-banner-heading service-third-heading">
            Real-Time Monitoring and Response System
          </div>
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ gap: "15rem" }}
          >
            <div className="">
              <img
                className="info-image-idms"
                src={idms4}
                alt="Real time vahicle tracking"
              />
            </div>
            <div className="">
              <img
                className="info-image-idms"
                src={idms5}
                alt="Emergency response systems"
              />
            </div>
          </div>
        </div>
        <div className="idms-service-seventh-container">
          <div className="product-banner-heading service-third-heading">
            Unified Mobility Approach
          </div>
          <div className="d-flex justify-content-center align-items-center gap-5">
            <img
              className="idms6-images "
              src={idms6}
              alt="Unified Mobility"
              data-aos="fade-up"
              data-aos-duration="1500"
            />
            <img
              className="idms6-images "
              src={idms7}
              alt="Unified Mobility"
              data-aos="fade-down"
              data-aos-duration="1500"
            />
            <img
              className="idms6-images "
              src={idms8}
              alt="Unified Mobility"
              data-aos="fade-up"
              data-aos-duration="1500"
            />
          </div>
        </div>
        <div className="idms-service-sixth-container ">
          <div className="idms-service-last-container">
            <div className="idms-client-story">
              <span>Client Success Stories</span>
            </div>
            <div className="client-note-container">
              <img className="client-note-img" src={clientNoteImg} alt="tmt" />
              <div className="client-note">
                <div className="client-note-text">
                  <span>Thane Municipal Transport System</span>
                  <span>Transforming Transport with Aeon's IDMS</span>
                  <span>
                    At Aeon, we are proud to have partnered with the Thane
                    Municipal Transport System to implement our Integrated Depot
                    Management System (IDMS). This initiative has modernized
                    their transport operations, enhancing efficiency, security,
                    and scalability. Our innovative solutions have set new
                    standards in their transport infrastructure, significantly
                    improving overall performance and passenger satisfaction.
                  </span>
                </div>
                <img src={tmtLogo} alt="tmt-logo" />
              </div>
            </div>
          </div>
          <div className="contact-us-end-container mt-5 ">
            <span className="service-para">
              At Aeon, we are passionate about transforming transportation
              systems and enhancing the passenger experience.
            </span>
            <span className="contact-us-end-text">
              Together, we can build smarter, more efficient transportation
              networks for a better tomorrow.
            </span>
            <Link className="contactUs-link" to="/contact-us">
              Contact Us!
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Idms;
