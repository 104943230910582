import React, { useEffect } from "react";
import mainImg from "../../Assests/Products/productMobileBanner.png";
import Banner from "../Banner";
import "./products.css";
import info1 from "../../Assests/Products/info1.png";
import productImg1 from "../../Assests/Products/product1.png";
import productImg2 from "../../Assests/Products/product2.png";
import productImg3 from "../../Assests/Products/product3.png";
import productImg4 from "../../Assests/Products/product4.png";
import productImg5 from "../../Assests/Products/product5.png";
import productImg6 from "../../Assests/Products/product6.png";
import { Link, useLocation } from "react-router-dom";
import productVideo1 from "../../Assests/Products/MobileTkt1.mp4";
import productVideo2 from "../../Assests/Products/MobileTkt2.mp4";
import productVideo3 from "../../Assests/Products/MobileTkt3.mp4";

function ProductPage() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Products" />
        <div className="below-banner-container product-below-banner-container">
          <h1 className="product-banner-heading">
            Mobile Ticketing Solution for Public Transport
          </h1>
          <div className="product-inner-container px-5">
            <div className="sub-heading-product">
              AEON's Mobile Ticketing Solution: Simplifying Public Transport for
              All
            </div>
            <div className="product-para mt-3 mb-5">
              In today's rapidly changing landscape, efficiency is everything –
              especially when it comes to getting from point A to point B.
              Public transportation serves as the lifeblood of cities,
              connecting people to their destinations. However, traditional
              ticketing systems can often be cumbersome and time-consuming,
              leaving passengers frustrated and transit operators overwhelmed.
            </div>
            <div className="product-info1-img">
              <img src={info1} alt="Public Transport" />
            </div>
            <div className="product-para mt-5 mb-5">
              That's where AEON steps in with our innovative mobile ticketing
              solution. Designed to streamline the ticketing process and enhance
              the overall travel experience, our Android-based app empowers
              transit staff to efficiently manage ticket sales directly from
              their smartphones. With just a few taps, conductors can issue
              tickets, track sales, and ensure a seamless journey for
              passengers.
            </div>
            <div className="product-descrip-container">
              <div className="product-descrip-left">
                <div className="sub-heading-product product-descrip-heading">
                  Conductor
                </div>
                <div className="product-para">
                  Our solution goes beyond simple ticketing. By seamlessly
                  integrating with existing ERP systems, our app provides
                  conductors with real-time access to route details and ticket
                  data, enabling them to make informed decisions on the go.
                  Features like personal cash management and route selection
                  further enhance efficiency, allowing conductors to focus on
                  providing top-notch service to passengers.
                </div>
              </div>
              {/* <div className="product-descrip-right product-descrip-right1">
                <img src={productImg1} alt="POS machine" />
              </div> */}
              <div className="product-descrip-right product-descrip-right1 pVideo-two">
                <video
                  src={productVideo1}
                  controls={false}
                  autoPlay={true}
                  loop
                  muted
                />
              </div>
            </div>
            <div className="product-images">
              <img src={productImg2} alt="POS machine" />
              <img src={productImg3} alt="POS machine" />
              <img src={productImg4} alt="POS machine" />
            </div>
            <div className="product-descrip-container">
              <div className="product-descrip-left tablet-img pVideo-two">
                <video
                  src={productVideo2}
                  controls={false}
                  autoPlay={true}
                  loop
                  muted
                />
              </div>

              <div className="product-descrip-right">
                {/* <img src={productImg5} alt="conductor" /> */}
                <div className="sub-heading-product product-descrip-heading">
                  Admin
                </div>
                <div className="product-para">
                  For administrators, our solution offers a suite of tools to
                  streamline operations and maintain system integrity. From
                  updating route and fare data to managing app releases, our web
                  portal provides centralized access to essential backend
                  services, ensuring smooth operations day in and day out.
                </div>
              </div>
            </div>
            <div className="product-descrip-container product-abcd">
              <div className="product-descrip-left">
                <div className="sub-heading-product product-descrip-heading">
                  PASSENGERS
                </div>
                <div className="product-para">
                  For passengers, the benefits are clear. No more waiting in
                  long lines or searching for exact change – with AEON's mobile
                  ticketing app, passengers can purchase tickets digitally,
                  validate them seamlessly, and enjoy a stress-free journey from
                  start to finish.
                </div>
                <div className="product-para">
                  So whether you're a transit operator looking to modernize your
                  ticketing system or a passenger seeking a more convenient way
                  to travel, AEON's mobile ticketing solution is here to
                  simplify public transport for all.
                </div>
              </div>
              <div className="product-descrip-right product-descrip-right1 pVideo-two">
                <video
                  src={productVideo3}
                  controls={false}
                  autoPlay={true}
                  loop
                  muted
                />
              </div>
            </div>
            <div className="contact-us-end-container">
              <span className="contact-us-end-text">
                Join us in revolutionizing the way we move and make every
                journey a smooth one.
              </span>
              <Link className="contactUs-link" to="/contact-us">
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProductPage;
