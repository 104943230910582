import React, { useEffect, useState } from "react";
import mainImg from "../../Assests/Domains/bankDomainImg.png";
import "./domain.css";
import main2Img from "../../Assests/Domains/banking.png";
import Banner from "../Banner";
import info3 from "../../Assests/Domains/bankInfo3.png";
import info1 from "../../Assests/Domains/bankInfo1.png";
import { Link } from "react-router-dom";
import info2 from "../../Assests/Domains/bankInfo2.png";
import info4 from "../../Assests/Domains/bankInfo4.png";
import ExploreDomains from "./ExploreDomains";
import Aos from "aos";
import "aos/dist/aos.css";
import domainVideo from "../../Assests/Domains/Videos/Aeon Banking.mp4";
import Modal from "react-bootstrap/Modal";

function Banking() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  return (
    <div>
      <div className="aboutUs-main-container miningDomain">
        <Banner mainImg={mainImg} title="Domains" />
        <div className="below-banner-container domain-main-container">
          <h1 className="domain-banner-heading">Banking</h1>
          <div className="domain-inner-container">
            <div className="domain-para">
              Banking systems demand a resilient and fortified infrastructure.
              At Aeon, our expertise lies in crafting and implementing secure
              banking and payment solutions for our clients, ensuring
              reliability and ease of use. Experience the transformative
              potential of our innovative technologies, tailored to meet the
              dynamic needs of the banking sector.
            </div>

            <div className="domain-info1">
              <div className="domain-info1-img mining">
                <img src={main2Img} alt="Banking" />
                <div className="circle-anim" onClick={handleShow}>
                  <div className="discover-now-btn">
                    <div className="step">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Bold"
                        viewBox="0 0 24 24"
                        width="60"
                        height="35"
                        fill="#37466F"
                      >
                        <path d="M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z" />
                      </svg>
                      <div className="ripple"></div>
                      <div className="ripple"></div>
                      <div className="ripple"></div>
                    </div>
                  </div>
                </div>

                <div className="opaque-screen domain-opaque-film"></div>
                <Modal show={show} onHide={handleClose} centered size="lg">
                  <Modal.Body className="domain-video-modal">
                    <div>Click outside the box to close</div>
                    <video
                      src={domainVideo}
                      autoPlay
                      loop
                      controls
                      controlsList="nodownload"
                      muted
                    />
                  </Modal.Body>
                </Modal>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 mb-5">
                <div className="domain-descrip-left" data-aos="fade-right">
                  <div className="sub-heading-product product-descrip-heading">
                    Usability and User-Experience
                  </div>
                  <div className="product-para">
                    Increase engagement with our customized and user-friendly
                    interfaces and dashboards for mobile banking applications
                    and online banking portals.
                  </div>
                  <div className="product-para">
                    Handle high traffic without system glitches with advanced
                    performance optimization techniques.
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-5">
                <div className=" mining-img" data-aos="fade-left">
                  <img src={info1} alt="Usability and User-Experience" />
                </div>
              </div>
              <div className="col-lg-6 mb-5">
                <div className=" mining-img" data-aos="fade-right">
                  <img src={info2} alt="CARDS AND PAYMENT SOLUTIONS" />
                </div>
              </div>
              <div className="col-lg-6 mb-5">
                <div className="domain-descrip-left" data-aos="fade-left">
                  <div className="sub-heading-product product-descrip-heading">
                    Cards and Payment solutions
                  </div>
                  <div className="product-para">
                    A majority of customers rely on cashless payment systems
                    today. Our digital payment solutions deliver fast and secure
                    transacting options to users across multiple devices,
                    enabling a reliable experience and customer retention.
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-5">
                <div className="domain-descrip-left" data-aos="fade-right">
                  <div className="sub-heading-product product-descrip-heading">
                    Enhanced Security
                  </div>
                  <div className="product-para">
                    Prevent cyber-attacks and safeguard sensitive data with our
                    diverse variety of cybersecurity solutions, like intrusion
                    detection systems, firewalls, and encryption technologies.
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mb-5">
                <div className=" mining-img" data-aos="fade-left">
                  <img src={info3} alt="Enhanced Security" />
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <div className=" mining-img" data-aos="fade-right">
                  <img src={info4} alt="Portfolio management solutions" />
                </div>
              </div>
              <div className="col-lg-6 mb-4">
                <div className="domain-descrip-left" data-aos="fade-left">
                  <div className="sub-heading-product product-descrip-heading">
                    Portfolio management solutions
                  </div>
                  <div className="product-para">
                    Our investment management solutions with accessibility
                    across devices have made it easy for both investors and
                    sales-partners to venture into the stocks and mutual-funds
                    markets. Our data-analysis and automation solutions further
                    help identifying customers interested in investment markets,
                    allowing targeted engagement opportunities. Features like
                    customizable dashboard, wish lists, and stock screeners
                    increase usability and user-retention and contribute to
                    continued expansion of your user base.
                  </div>
                </div>
              </div>
              <div className="col-lg-12 mb-5">
                <div className="">
                  <div className="sub-heading-product product-descrip-heading">
                    Comprehensive solutions
                  </div>
                  <div className="product-para mb-3">
                    Transform your banking operations with a comprehensive suite
                    of services, including
                  </div>
                  <div className="">
                    <ul>
                      <li>Customer Relationship Management (CRM) Software</li>
                      <li>Loan Management Systems</li>
                      <li>Block chain technology</li>
                      <li>
                        Data Analytics and Business Intelligence Tools and
                        more...
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="contact-us-end-container">
                  <span className="contact-us-end-text">
                    Experience our trusted solutions and craft a remarkable and
                    reliable banking experience, with Aeon.
                  </span>
                  <Link className="contactUs-link" to="/contact-us">
                    Get in touch!
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="explore-domains">
            <div className="heading">
              <h2>Explore Our Other Domains</h2>
            </div>
            <ExploreDomains />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Banking;
