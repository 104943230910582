import React, { useEffect, useState } from "react";
import mainImg from "../../Assests/Domains/transportDomainImg.png";
import "./domain.css";
import transport1 from "../../Assests/Domains/transportImg.png";
import Banner from "../Banner";
import info3 from "../../Assests/Domains/transportInfo3.png";
import info1 from "../../Assests/Domains/transportInfo1.jpg";
import { Link } from "react-router-dom";
import info2 from "../../Assests/Domains/tranportInfo2.png";
import ExploreDomains from "./ExploreDomains";
import Aos from "aos";
import "aos/dist/aos.css";
import domainVideo from "../../Assests/Domains/Videos/Aeon Transport.mp4";
import Modal from "react-bootstrap/Modal";

function Transportation() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  return (
    <div>
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Domains" />
        <div className="below-banner-container domain-main-container">
          <h1 className="product-banner-heading">Transportation</h1>
          <div className="product-inner-container">
            <div className="product-para mt-4 mb-4">
              Transportation is the lifeblood of modern society, connecting
              people and goods across vast networks. For smooth operations in
              this dynamic enterprise, it is crucial to manage bookings and
              journeys on multiple routes on a daily basis.
            </div>
            <div className="product-para mb-5">
              Leave it to us! - Aeon offers integrated ERP and mobile app
              solutions for you to successfully meet the organizational needs of
              this industry, so that you can focus on planning and expanding
              your business.
            </div>

            <div className="domain-info1">
              <div className="domain-info1-img">
                <img src={transport1} alt="produtImg" />
                <div className="circle-anim" onClick={handleShow}>
                  <div className="discover-now-btn">
                    <div className="step">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Bold"
                        viewBox="0 0 24 24"
                        width="60"
                        height="35"
                        fill="#37466F"
                      >
                        <path d="M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z" />
                      </svg>
                      <div className="ripple"></div>
                      <div className="ripple"></div>
                      <div className="ripple"></div>
                    </div>
                  </div>
                </div>
                <div className="opaque-screen domain-opaque-film"></div>
              </div>

              <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Body className="domain-video-modal">
                  <div>Click outside the box to close</div>
                  <video
                    src={domainVideo}
                    autoPlay
                    loop
                    controls
                    controlsList="nodownload"
                    muted
                  />
                </Modal.Body>
              </Modal>
            </div>

            <div className="product-descrip-container domain-descrip-container">
              <div className="product-descrip-left" data-aos="fade-right">
                <h1 className="sub-heading-product product-descrip-heading">
                  Node Management Solutions
                </h1>
                <div className="product-para">
                  A customized ERP solution allows you to monitor and manage
                  your transport fleet centrally. Generate insights from route
                  traffic data to spread out your operations productively.
                </div>
              </div>
              <div
                className="product-descrip-right node-img"
                data-aos="fade-left"
              >
                <img src={info1} alt="Node Management Solutions" />
              </div>
            </div>
            <div className="product-descrip-container domain-descrip-container">
              <div
                className="product-descrip-right tablet-img"
                data-aos="fade-right"
              >
                <img src={info2} alt="cashless" />
              </div>
              <div className="product-descrip-left" data-aos="fade-left">
                <h1 className="sub-heading-product product-descrip-heading">
                  Go cashless!
                </h1>
                <div className="product-para">
                  Smooth payment integrations for Online ticketing, POS
                  terminals and Automatic Fare Collection System allow secure
                  online transactions at any point in the user’s journey.
                </div>
              </div>
            </div>
            <div className="product-descrip-container domain-descrip-container">
              <div className="product-descrip-left" data-aos="fade-right">
                <h1 className="sub-heading-product product-descrip-heading">
                  Manage user experience
                </h1>
                <div className="product-para">
                  Mobile device integrations offering customized experiences for
                  different kinds of users, to effectively manage all journey
                  and booking related processes.
                </div>
              </div>
              <div className="product-descrip-right" data-aos="fade-left">
                <img src={info3} alt="user experience" />
              </div>
            </div>
            <div className="contact-us-end-container">
              <span className="product-para">
                A number of state transport service providers are bringing their
                operations online using our customizable solutions.
              </span>
              <span className="contact-us-end-text">
                Find out how you can benefit from our services.
              </span>
              <Link className="contactUs-link" to="/contact-us">
                Get in touch!
              </Link>
            </div>
            <div className="explore-domains">
              <div className="heading">
                <h2>Explore Our Other Domains</h2>
              </div>
              <ExploreDomains />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Transportation;
