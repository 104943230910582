import React, { useEffect, useState } from "react";
import Banner from "../Banner";
import mainImg from "../../Assests/Domains/aviationDomainImg.jpg";
import aviation1 from "../../Assests/Domains/aviationImg.png";
import { Link } from "react-router-dom";
import info1 from "../../Assests/Domains/aviationInfo1.jpg";
import info2 from "../../Assests/Domains/aviationInfo2.jpg";
import info3 from "../../Assests/Domains/aviationInfo3.jpg";
import ExploreDomains from "./ExploreDomains";
import Aos from "aos";
import "aos/dist/aos.css";
import domainVideo from "../../Assests/Domains/Videos/Aeon Aviation.mp4";
import Modal from "react-bootstrap/Modal";

function Aviation() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);
  return (
    <div>
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Domains" />
        <div className="below-banner-container domain-main-container">
          <h1 className="product-banner-heading">Aviation</h1>
          <div className="product-inner-container">
            <div className="product-para mt-4 mb-4">
              A lucrative career option, Aviation is sought after by many people
              around the world. In the last 3 years, Aeon has made its mark in
              the online aviation training space, delivering versatile online
              training for pilots, ground staff, airport management and other
              aviation related services.
            </div>
            <div className="product-para mb-5">
              Our catalogue of customized services also include staff scheduling
              management software for airport crews, RFID integrations for
              Automated baggage handling, and IoT integrations for Smart Airport
              services
            </div>

            <div className="domain-info1">
              <div className="domain-info1-img">
                <img src={aviation1} alt="aviation" />
                <div className="circle-anim" onClick={handleShow}>
                  <div className="discover-now-btn">
                    <div className="step">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Bold"
                        viewBox="0 0 24 24"
                        width="60"
                        height="35"
                        fill="#37466F"
                      >
                        <path d="M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z" />
                      </svg>
                      <div className="ripple"></div>
                      <div className="ripple"></div>
                      <div className="ripple"></div>
                    </div>
                  </div>
                </div>
                <div className="opaque-screen domain-opaque-film"></div>
              </div>
              <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Body className="domain-video-modal">
                  <div>Click outside the box to close</div>
                  <video
                    src={domainVideo}
                    autoPlay
                    loop
                    controls
                    controlsList="nodownload"
                    muted
                  />
                </Modal.Body>
              </Modal>
            </div>

            <div className="product-descrip-container domain-descrip-container aviation-descrip-container">
              <div className="product-descrip-left" data-aos="fade-right">
                <div className="sub-heading-product product-descrip-heading">
                  Comprehensive training
                </div>
                <div className="product-para mb-4">
                  We offer customized courses for all the different kinds of
                  aviation professionals, covering a wide range of topics like
                  flight operations, ground operations, safety procedures,
                  regulatory compliance, and customer service.
                </div>
                <div className="product-para">
                  Our courses are filled with scenarios based questions,
                  activities, case studies and frequent quizzes to ensure
                  continued learner engagement with the course materials. Guided
                  trainings with response-based scenarios provide real-time
                  situational knowledge, allowing your learners to easily
                  imagine themselves in real-life situations and adapt to them.
                </div>
              </div>
              <div
                className="product-descrip-right aviaInfo-img"
                data-aos="fade-left"
              >
                <img src={info1} alt="comprehensive training" />
              </div>
            </div>
            <div className="product-descrip-container domain-descrip-container aviation-descrip-container">
              <div
                className="product-descrip-right aviaInfo-img"
                data-aos="fade-right"
              >
                <img src={info2} alt="Digital learning solutions" />
              </div>
              <div className="product-descrip-left" data-aos="fade-left">
                <div className="sub-heading-product product-descrip-heading">
                  Digital learning solutions
                </div>
                <div className="product-para">
                  Our integrated learning management system (LMS) allows course
                  moderators to monitor learner progress through the courses and
                  address potential areas of improvement. Large scale
                  enrollments can be effectively managed with our training
                  management system (TMS), ensuring seamless registrations and
                  timely assessments for aviation training organizations of all
                  sizes.
                </div>
              </div>
            </div>
            <div className="product-descrip-container domain-descrip-container aviation-descrip-container">
              <div className="product-descrip-left" data-aos="fade-right">
                <div className="sub-heading-product product-descrip-heading">
                  Management solutions
                </div>
                <div className="product-para">
                  At Aeon, we excel in creating dedicated web, cloud, and
                  application based tools to manage operations for airport
                  services. With our hardware integration prowess, we can apply
                  IoT, AI and big data to optimize airport operations like smart
                  security, automated check-in, and automated baggage handling
                  services. We also provide a comprehensive suite of ERP
                  services for Crew scheduling, Customer relationship
                  management, and Risk management
                </div>
              </div>
              <div
                className="product-descrip-right aviaInfo-img"
                data-aos="fade-left"
              >
                <img src={info3} alt="Management solutions" />
              </div>
            </div>
            <div className="contact-us-end-container">
              <span className="contact-us-end-text">
                Take off on a journey of growth and learning with Aeon. Find out
                more about our offerings!
              </span>
              <Link className="contactUs-link" to="/contact-us">
                Get in touch!
              </Link>
            </div>
            <div className="explore-domains">
              <div className="heading">
                <h2>Explore Our Other Domains</h2>
              </div>
              <ExploreDomains />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Aviation;
