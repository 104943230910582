import React, { useEffect, useState } from "react";
import mainImg from "../../Assests/AboutUs/aboutUsMain.png";
import "./aboutUs.css";
import Banner from "../Banner";
import element1 from "../../Assests/AboutUs/element1.png";
import element2 from "../../Assests/AboutUs/element2.png";
import aboutUsSecond from "../../Assests/AboutUs/groupImg.png";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import infographicImg from "../../Assests/AboutUs/infograph.png";
import missionImg from "../../Assests/AboutUs/missionImg.png";
import visionImg from "../../Assests/AboutUs/visionImg.png";

function AboutUs() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const [startCounting, setStartCounting] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const belowBannerContainer = document.querySelector(
        ".aboutUs-mission-vission"
      );
      const aboutUsContainer = document.querySelector(
        ".aboutUs-third-container"
      );

      if (belowBannerContainer && aboutUsContainer) {
        const rect = belowBannerContainer.getBoundingClientRect();
        // Check if the bottom of below-banner-container is above the viewport
        if (rect.bottom < window.innerHeight) {
          setStartCounting(true);
          // Remove event listener once counting starts
          window.removeEventListener("scroll", handleScroll);
        }
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up function to remove event listener
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="About Us" />
        <div className="below-banner-container">
          <img className="element1" src={element1} alt="element1" />
          <img className="element2" src={element2} alt="element2" />
          <div className="aboutUs-container1">
            <div className="aboutUs-left-text">
              <div className="aboutUs-head">
                <span>Who we are</span>
                <div className="heading-underline"></div>
              </div>

              <span className="product-para">
                When we first opened our doors back in 2005, we had a simple
                mission - to empower businesses of all shapes and sizes with
                cutting-edge technology that would transform the way they
                operate. 19 years later, that mission has only grown stronger.
              </span>
              <span className="product-para">
                As an IT solutions provider, we've had the privilege of working
                alongside a diverse array of clients, from the ready-to-eat
                market and retail giants to banking institutions and
                manufacturing powerhouses. Our team of tech wizards has
                designed, developed, and deployed solutions that have
                streamlined workflows, enhanced productivity, and unlocked new
                avenues for growth across industries.
              </span>
              <span className="product-para">
                But we're not just another software company churning out generic
                products. At Aeon, we pride ourselves on crafting niche
                solutions that are as unique as the businesses we serve. Whether
                it's a mobile app, a web-based platform, or an embedded
                technology marvel, we approach every project with the same level
                of passion and innovation.
              </span>
              <span className="product-para">
                Just like the Olympic athletes we admire, we're driven by the
                pursuit of excellence. Our team embodies the spirit of "Faster,
                Higher, Stronger," constantly pushing boundaries and embracing
                innovation. From AI and automation to cloud computing, we're at
                the forefront of technology, crafting cutting-edge solutions
                that empower our clients to stay ahead in today's competitive
                landscape.
              </span>
            </div>
            <div className="aboutUs-right-text">
              <img src={aboutUsSecond} alt="aboutUsImg" />
            </div>
          </div>
          <div className="aboutUs-second-container">
            <span className="product-para">
              At Aeon, we're all about the power of partnerships. Our
              collaborations with industry giants like CIRT and GAA demonstrate
              our dedication to delivering top-notch IT solutions. Whether we're
              supporting state transport projects or enhancing aviation
              training, our goal is to earn the trust of every client.
            </span>
            <span className="product-para">
              At the core of our operations lies our commitment to providing
              reasonable and affordable services, guaranteeing peace of mind for
              our clients. Through cultivating an environment that encourages
              intrapreneurship, we're not only developing solutions but also
              cultivating opportunities for growth and advancement for our team
              members.
            </span>
            <span className="product-para">
              So, if you're looking for an IT partner that will approach your
              challenges with the same level of dedication, creativity, and
              drive, look no further.
            </span>
          </div>
        </div>
        <div className="aboutUs-mission-vission">
          <div className="mission-vission-container">
            <img src={missionImg} alt="mission" />
            <div className="mission-container">
              <div className="mission-heading">MISSION</div>
              <div className="product-para">
                Our mission is to lead digital transformation, providing
                automation solutions that digitize processes across sectors
                while reducing the carbon footprint. We pledge to offer
                affordable IT solutions and create job opportunities, all while
                nurturing innovation within our organization. Committed to
                serving as a catalyst for progress, we aim to embody an enduring
                commitment to technological advancement.
              </div>
            </div>
          </div>
          <div className="mission-vission-container">
            <img src={visionImg} alt="vision" />
            <div className="mission-container">
              <div className="mission-heading">VISION</div>
              <div className="product-para">
                Our vision is to be a global leader in innovation, advancing
                society through sustainable, technology-powered solutions. We
                prioritize both people and technology, empowering individuals
                and businesses worldwide. Through encouraging creativity, we
                envision a future where Aeon's impact transcends borders,
                fueling prosperity and lasting change.
              </div>
            </div>
          </div>
        </div>
        <div className="aboutUs-third-container">
          <div className="aboutUs-counts-container">
            <div className="aboutUs-counts-inner-container">
              <div className="aboutUs-count">
                <span className="counts">
                  {startCounting && <CountUp end={150} duration={4} />}+
                </span>

                <span className="counts-text">Employees</span>
              </div>
              <div className="aboutUs-count">
                <span className="counts">
                  {startCounting && <CountUp end={80} duration={4} />}+
                </span>
                <span className="counts-text">
                  Successful client relationship
                </span>
              </div>
              <div className="aboutUs-count">
                <span className="counts">
                  {startCounting && <CountUp end={19} duration={4} />}+
                </span>
                <span className="counts-text">Years in Business</span>
              </div>
            </div>
          </div>
          <div className="aboutUs-third-inner-container">
            <div className="aboutUs-head">
              <span>Why choose aeon?</span>
              <div className="heading-underline"></div>
            </div>
            <img src={infographicImg} alt="whyChooseAeon" />
            <span className="leap-text">Ready to take a leap?</span>
            <span className="contact-us-end-text">
              Contact us today to see how we can help you.
            </span>
            <Link className="contactUs-link" to="/contact-us">
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
