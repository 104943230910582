import React, { useEffect } from "react";
import mainImg from "../../Assests/Products/productDepotBanner.png";
import Banner from "../Banner";
import "./products.css";
import depoImg1 from "../../Assests/Products/depoImg1.png";
import depoImg2 from "../../Assests/Products/depoImg2.png";
import depoImg3 from "../../Assests/Products/depoImg3.png";
import depoImg4 from "../../Assests/Products/depoImg4.png";
import depoImg5 from "../../Assests/Products/depoImg5.png";
import depoImg6 from "../../Assests/Products/depoImg6.png";
import info1 from "../../Assests/Products/depoInfoImg.png";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import productVideo from "../../Assests/Products/Depot Management.mp4";

function DepoManageProduct() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);
  return (
    <div>
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Products" />
        <div className="below-banner-container product-below-banner-container">
          <h1 className="product-banner-heading">Depot Management</h1>
          <div className="product-video-container">
            <video
              src={productVideo}
              controls={false}
              autoPlay={true}
              loop
              muted
            />
          </div>
          <div className="product-inner-container">
            <div className="sub-heading-product">
              Our Expertise in Public Transport
            </div>
            <div className="product-para mt-4 mb-4">
              Thinking about upgrading your public transportation system?
            </div>
            <div className="product-para mb-5">
              Discover AEON's Advanced Public Transport Solution. Designed to
              boost efficiency and passenger satisfaction while simplifying
              management. Join us in reshaping urban mobility for the better.
            </div>
            <div className="depo-product-img-container">
              <div className="depo-img">
                <img
                  src={depoImg1}
                  alt="depo management system"
                  data-aos="zoom-in"
                  data-aos-duration="200"
                />
                <img
                  src={depoImg2}
                  alt="avls"
                  data-aos="zoom-in"
                  data-aos-duration="800"
                />
                <img
                  src={depoImg3}
                  alt="command control center"
                  data-aos="zoom-in"
                  data-aos-duration="1400"
                />
              </div>
              <div className="depo-img depo-image2">
                <img
                  src={depoImg4}
                  alt="pis"
                  data-aos="fade-zoom-in"
                  data-aos-duration="200"
                />
                <img
                  src={depoImg5}
                  alt="intelligent transport system"
                  data-aos="fade-zoom-in"
                  data-aos-duration="800"
                />
                <img
                  src={depoImg6}
                  alt="afcs"
                  data-aos="fade-zoom-in"
                  data-aos-duration="1400"
                />
              </div>
            </div>
            <div className="sub-heading-product depo-second-heading">
              Here’s what sets us apart!
            </div>

            <div className="product-info1-img">
              <img src={info1} alt="depo-management" />
            </div>
            <div className="product-para mt-5 mb-5">
              With AEON's public transport solution, you're not just getting a
              system – you're getting a partner committed to your success.
            </div>
            <div className="contact-us-end-container">
              <span className="contact-us-end-text">
                Let's elevate your public transportation experience together!
              </span>
              <Link className="contactUs-link" to="/contact-us">
                Get in touch!
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DepoManageProduct;
