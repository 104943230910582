import http from "../http-common";

const getAllJobs = (id) => {
  return http.post("/Jobs/GetJobOpenings", id);
};

const userJobRequest = (data) => {
  return http.post("/Candidate/newapplyEmail", data);
};

const addNewCandidate = (data) => {
  return http.post("/Candidate/AddCandidate", data);
};

const getJobById = (id) => {
  return http.post("/Jobs/jobDetails", id);
};

const CareerService = {
  getAllJobs,
  userJobRequest,
  addNewCandidate,
  getJobById,
};

export default CareerService;
