import React, { useEffect, useState } from "react";
import "./career.css";
import Banner from "../Banner";
import mainImg from "../../Assests/careerMainImg.png";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import CareerService from "../../ApiService/career.service";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

function Careers() {
  const navigate = useNavigate();
  const [jobDetails, setJobDetails] = useState([]);
  const [searchedValue, setSearchedValue] = useState("");
  const [selectedJobCat, setSelectedJobCat] = useState("");
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedJobType, setSelectedJobType] = useState("");
  const [selectedEmpType, setSelectedEmpType] = useState("");
  const [message, setMessage] = useState(false);

  const [jobCategories, setJobCategories] = useState([
    "UI/UX Designer",
    "Dot NET Developer",
    "Front End Developer",
    "Full Stack Developer",
    "E-Learning Developer",
    "Animator",
    "Mobile App Developer",
    "Instructional Designer",
  ]);

  const [jobLocations, setJobLocations] = useState([
    "Pune",
    "Mumbai",
    "Thane",
    "Ahemdabad",
    "Indore",
    "Sikkim",
  ]);

  const [jobTypes, setJobTypes] = useState(["Full Time", "Freelancer"]);

  const [empyTypes, setEmpTypes] = useState(["Intern", "Permanent"]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleSearch = (e) => {
    setSearchedValue(e.target.value);
  };

  const handleNavigate = (data) => {
    navigate(`/applyNow/1/${data.post_id}`);
  };

  const handleJobCatChange = (jobCat) => {
    setSelectedJobCat((prevJobCat) => (prevJobCat === jobCat ? "" : jobCat));
  };

  const handleLocationChange = (location) => {
    setSelectedLocations((prevLocations) => {
      if (prevLocations.includes(location)) {
        return prevLocations.filter((loc) => loc !== location);
      } else {
        return [...prevLocations, location];
      }
    });
  };

  const handleJobTypeChange = (jobType) => {
    setSelectedJobType((prevJobType) =>
      prevJobType === jobType ? "" : jobType
    );
  };

  const handleEmpTypeChange = (empType) => {
    setSelectedEmpType((prevEmpType) =>
      prevEmpType === empType ? "" : empType
    );
  };

  useEffect(() => {
    fetchAllJobs();
  }, [
    searchedValue,
    selectedJobCat,
    selectedLocations,
    selectedJobType,
    selectedEmpType,
  ]);

  const fetchAllJobs = () => {
    const data = {
      Status: "1",
    };
    CareerService.getAllJobs(data).then((response) => {
      const filteredData = response.data.filter((job) => {
        return (
          (searchedValue === "" ||
            Object.values(job).some(
              (value) =>
                value && value.toString().toLowerCase().includes(searchedValue)
            )) &&
          (selectedJobCat === "" ||
            job.job_Title
              .toLowerCase()
              .includes(selectedJobCat.toLowerCase())) &&
          (selectedLocations.length === 0 ||
            selectedLocations.some(
              (loc) => loc.toLowerCase() === job.loc.toLowerCase()
            )) &&
          (selectedJobType === "" ||
            job.job_type.toLowerCase() === selectedJobType.toLowerCase()) &&
          (selectedEmpType === "" ||
            job.employment_type.toLowerCase() === selectedEmpType.toLowerCase())
        );
      });
      if (filteredData.length != 0) {
        setMessage(false);
        setJobDetails(filteredData);
      } else {
        setMessage(true);
      }
    });
  };

  return (
    <div>
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Careers" />
        <div className="below-banner-container">
          <div className="aboutUs-container1">
            <div className="aboutUs-left-text">
              <div className="aboutUs-head">
                <span>Be a part of our mission</span>
                <div className="heading-underline"></div>
              </div>
              <span className="service-para">
                We're looking for passionate people to join us on our mission.
                We value flat hierarchies, clear communication, and full
                ownership and responsibility.
              </span>
            </div>
          </div>
          <div className="careers-main-container">
            <div className="career-inner-container">
              <div className="career-box-1">
                <div className="search-container">
                  <label>Search</label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      placeholder="Type here..."
                      aria-label="Recipient's username"
                      aria-describedby="basic-addon2"
                      name="search"
                      value={searchedValue}
                      onChange={handleSearch}
                    />
                    <InputGroup.Text id="basic-addon2">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 29.534 29.534"
                        >
                          <g
                            id="search-interface-symbol"
                            transform="translate(0.5 0.5)"
                          >
                            <path
                              id="Path_8337"
                              data-name="Path 8337"
                              d="M10.69-.5h2.423a12.574,12.574,0,0,1,9.662,6.172,12.321,12.321,0,0,1,.692,10.671,13.363,13.363,0,0,1-2.278,3.576q3.835,3.82,7.7,7.614a.687.687,0,0,0,.144.231V28.4a1.038,1.038,0,0,1-.635.635h-.635a1.625,1.625,0,0,1-.346-.2Q23.6,25.052,19.747,21.3A12.833,12.833,0,0,1,7,23.006,12.466,12.466,0,0,1-.5,12.882V10.575A12.368,12.368,0,0,1,6.48.682,14.966,14.966,0,0,1,10.69-.5ZM11.5,1.4a10.748,10.748,0,0,1,9.085,4.5,10.289,10.289,0,0,1,1.038,9.748,10.46,10.46,0,0,1-7.412,6.143,10.653,10.653,0,0,1-9.518-2.6A10.261,10.261,0,0,1,2.182,7.922a10.435,10.435,0,0,1,7.47-6.259A16.375,16.375,0,0,1,11.5,1.4Z"
                              fill="#fff"
                              fill-rule="evenodd"
                              opacity="0.966"
                            />
                          </g>
                        </svg>
                      </span>
                    </InputGroup.Text>
                  </InputGroup>
                </div>
                <div className="grey-line"></div>
                <div className="filters-container">
                  <Sidebar
                    width="100%"
                    backgroundColor="white"
                    className="sidebar-lib"
                  >
                    <Menu>
                      <SubMenu
                        label="Job Category"
                        className="sub-item-menu"
                        defaultOpen
                      >
                        {Array.isArray(jobCategories)
                          ? jobCategories.map((jobCat) => (
                              <MenuItem className="menu-item">
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectedJobCat === jobCat}
                                    onChange={() => handleJobCatChange(jobCat)}
                                  />
                                  {jobCat}
                                </label>
                              </MenuItem>
                            ))
                          : null}
                      </SubMenu>
                      <SubMenu label="Location" className="sub-item-menu">
                        {Array.isArray(jobLocations)
                          ? jobLocations.map((loc) => (
                              <MenuItem className="menu-item">
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectedLocations.includes(loc)}
                                    onChange={() => handleLocationChange(loc)}
                                  />
                                  {loc}
                                </label>
                              </MenuItem>
                            ))
                          : null}
                      </SubMenu>
                      <SubMenu label="Job Type" className="sub-item-menu">
                        {Array.isArray(jobTypes)
                          ? jobTypes.map((type) => (
                              <MenuItem className="menu-item">
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectedJobType === type}
                                    onChange={() => handleJobTypeChange(type)}
                                  />
                                  {type}
                                </label>
                              </MenuItem>
                            ))
                          : null}
                      </SubMenu>
                      <SubMenu
                        label="Employment Type"
                        className="sub-item-menu"
                      >
                        {Array.isArray(empyTypes)
                          ? empyTypes.map((type) => (
                              <MenuItem className="menu-item">
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={selectedEmpType === type}
                                    onChange={() => handleEmpTypeChange(type)}
                                  />
                                  {type}
                                </label>
                              </MenuItem>
                            ))
                          : null}
                      </SubMenu>
                    </Menu>
                  </Sidebar>
                </div>
              </div>

              <div className="career-box-2">
                {message ? (
                  <div className="career-box career-subhead text-center">
                    No data found
                  </div>
                ) : Array.isArray(jobDetails) ? (
                  jobDetails.map((data) => (
                    <div className="career-box" key={data.id}>
                      <div className="blue-border"></div>
                      <div className="career-heading">
                        <div
                          className="career-subhead"
                          style={{ textTransform: "capitalize" }}
                        >
                          {data.job_Title}
                        </div>
                        <div className="career-location">
                          <span>
                            <svg
                              id="location_1_"
                              data-name="location (1)"
                              xmlns="http://www.w3.org/2000/svg"
                              width="15"
                              height="15"
                              viewBox="0 0 19.495 22.194"
                            >
                              <g
                                id="Group_16028"
                                data-name="Group 16028"
                                transform="translate(0)"
                              >
                                <path
                                  id="Path_8340"
                                  data-name="Path 8340"
                                  d="M39.639-.5H41.2a7.247,7.247,0,0,1,5.223,11.141q-1.129,1.725-2.232,3.468a21.944,21.944,0,0,1,3.381.932A5.424,5.424,0,0,1,49.8,16.579a2.184,2.184,0,0,1-.173,2.644,6.569,6.569,0,0,1-2.8,1.582,21.5,21.5,0,0,1-4.985.889H38.988a21.8,21.8,0,0,1-4.595-.759,9.847,9.847,0,0,1-2.514-1.127,2.457,2.457,0,0,1-1.192-2.449,2.621,2.621,0,0,1,.759-1.235A7.24,7.24,0,0,1,34,14.78q1.311-.387,2.644-.672-1.127-1.821-2.319-3.6a7.415,7.415,0,0,1-.52-6.632A7.272,7.272,0,0,1,39.639-.5Zm.13,1.344a5.915,5.915,0,0,1,5.744,2.991,6.045,6.045,0,0,1-.087,5.852q-2.516,3.893-5.007,7.8-2.491-3.91-5.007-7.8a6.045,6.045,0,0,1-.087-5.852A5.92,5.92,0,0,1,39.769.844ZM37.211,15.409a.392.392,0,0,1,.325.087q1.206,1.946,2.493,3.836a.729.729,0,0,0,.932-.152Q42.147,17.349,43.3,15.5a.217.217,0,0,1,.2-.087,14.993,14.993,0,0,1,3.685.932,4.022,4.022,0,0,1,1.5,1.019.8.8,0,0,1,.13.65,2.815,2.815,0,0,1-.542.629,8.26,8.26,0,0,1-2.644,1.127,22.29,22.29,0,0,1-6.589.564,17.082,17.082,0,0,1-5.158-1,5.122,5.122,0,0,1-1.582-.932,2.805,2.805,0,0,1-.26-.39.966.966,0,0,1,.043-.52,3.929,3.929,0,0,1,1.582-1.149A16.12,16.12,0,0,1,37.211,15.409Z"
                                  transform="translate(-30.673 0.5)"
                                  fill-rule="evenodd"
                                  opacity="0.963"
                                />
                              </g>
                              <g
                                id="Group_16029"
                                data-name="Group 16029"
                                transform="translate(6.497 3.925)"
                              >
                                <path
                                  id="Path_8341"
                                  data-name="Path 8341"
                                  d="M183.28,90.063a3.293,3.293,0,0,1,2.384,5.96,3.524,3.524,0,0,1-3.164.3,3.274,3.274,0,0,1-1.886-3.75A3.355,3.355,0,0,1,183.28,90.063Zm.13,1.344a1.972,1.972,0,0,0-1.5,2.3,1.926,1.926,0,1,0,3.771-.78A1.966,1.966,0,0,0,183.41,91.407Z"
                                  transform="translate(-180.551 -90.044)"
                                  fill-rule="evenodd"
                                  opacity="0.964"
                                />
                              </g>
                            </svg>
                            <span style={{ textTransform: "capitalize" }}>
                              {data.loc}
                            </span>
                          </span>
                          <span>
                            <svg
                              id="clock_1_"
                              data-name="clock (1)"
                              xmlns="http://www.w3.org/2000/svg"
                              width="13"
                              height="13"
                              viewBox="0 0 22.194 22.194"
                            >
                              <g
                                id="Group_16026"
                                data-name="Group 16026"
                                transform="translate(0)"
                              >
                                <path
                                  id="Path_8338"
                                  data-name="Path 8338"
                                  d="M9.643-.5h1.907a11.2,11.2,0,0,1,9.3,6.762,12.1,12.1,0,0,1,.845,3.381v1.907a11.2,11.2,0,0,1-6.762,9.3,12.1,12.1,0,0,1-3.381.845H9.643a11.2,11.2,0,0,1-9.3-6.762A12.1,12.1,0,0,1-.5,11.551V9.643A11.2,11.2,0,0,1,6.262.345,12.1,12.1,0,0,1,9.643-.5Zm.433,1.734a9.44,9.44,0,0,1,8.691,4.812A9.35,9.35,0,0,1,9.253,19.852a9.467,9.467,0,0,1-6.827-4.7A9.336,9.336,0,0,1,2.6,5.742,9.477,9.477,0,0,1,10.077,1.234Z"
                                  transform="translate(0.5 0.5)"
                                  fill-rule="evenodd"
                                  opacity="0.971"
                                />
                              </g>
                              <g
                                id="Group_16027"
                                data-name="Group 16027"
                                transform="translate(10.228 5.115)"
                              >
                                <path
                                  id="Path_8339"
                                  data-name="Path 8339"
                                  d="M236.231,117.5a.9.9,0,0,1,.932.65q.022,2.471.043,4.942l3.208,2.428a.923.923,0,0,1,.043,1.17.994.994,0,0,1-1.235.152l-3.425-2.558a.882.882,0,0,1-.282-.368q-.107-2.879-.043-5.765A.852.852,0,0,1,236.231,117.5Z"
                                  transform="translate(-235.449 -117.5)"
                                  fill-rule="evenodd"
                                  opacity="0.976"
                                />
                              </g>
                            </svg>
                            <span>{data.created_on}</span>
                          </span>
                        </div>
                        <div className="lightGrey-line"></div>
                      </div>
                      <div>Experience: {data.experience} years</div>
                      <div>{data.job_desc}</div>
                      <div className="apply-now">
                        <button onClick={() => handleNavigate(data)}>
                          Apply Now
                        </button>
                      </div>
                    </div>
                  ))
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Careers;
