import React, { useEffect } from "react";
import mainImg from "../../Assests/Products/productLmsBanner.png";
import Banner from "../Banner";
import "./products.css";
import LMSProduct1 from "../../Assests/Products/LMSProduct1.png";
import LMSProduct2 from "../../Assests/Products/LMSProduct2.png";
import LMSProduct3 from "../../Assests/Products/LMSProduct3.png";
import LMSProduct4 from "../../Assests/Products/lmsProduct4.png";
import { Link } from "react-router-dom";
import productVideo1 from "../../Assests/Products/LMS1.mp4";
import productVideo2 from "../../Assests/Products/LMS2.mp4";
import productVideo3 from "../../Assests/Products/LMS3.mp4";
import productVideo4 from "../../Assests/Products/LMS4.mp4";

function LMS() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Products" />
        <div className="below-banner-container product-below-banner-container">
          <h1 className="product-banner-heading">Learning Management System</h1>
          <div className="product-inner-container">
            <div className="product-para mt-4 mb-5">
              Our core mission of advancing digitization and ensuring
              accessibility shapes every aspect of what we do. We are dedicated
              to transforming your organization's learning and development
              strategies through cutting-edge technology and inclusive design.
              Recognizing the vital role of continuous learning in today's
              competitive landscape, our platform is designed to empower your
              team with efficient and effective learning solutions. Whether
              you're integrating new team members, enhancing the skills of
              current staff, or implementing professional development programs,
              Aeon LMS provides the essential tools to drive your organization
              forward.
            </div>
            <div className="row mb-5">
              <div className="col-lg-12">
                <div className="product-sptext px-5">
                  <h3>
                    Trusted by leading institutions such as{" "}
                    <span>Gulf Aviation Academy</span> & <span>GMR</span>, we
                    specialize in offering a wide range of courses.
                  </h3>
                </div>
              </div>
            </div>

            <div className="product-heading mb-5">Features</div>

            <div className="row mb-4 align-items-center">
              <div className="col-lg-6">
                {/* <div className="product-img">
                  <img src={LMSProduct1} width="90%" alt="LMS Features" />
                </div> */}
                <div className="product-video-container pVideo-two">
                  <video
                    src={productVideo1}
                    controls={false}
                    autoPlay={true}
                    loop
                    muted
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <h1 className="product-subheading">Tailored Branding</h1>
                <div className="product-para mt-4">
                  Aeon LMS allows complete <strong>customization</strong> and{" "}
                  <strong>white-labeling</strong> , enabling you to align the
                  platform with your brand identity. From logos and color
                  schemes to custom domains and interfaces, every aspect of the
                  LMS can be tailored to reflect your organization’s unique
                  character. This not only enhances brand consistency but also
                  fosters a sense of ownership and familiarity among learners.
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-12">
                <div className="product-para mt-4 mb-4">
                  <strong>SCORM Compatibility </strong>
                  <div>
                    Our LMS platform ensures seamless integration and delivery
                    of standardized e-learning content by supporting SCORM 1.2
                    packages. This compatibility empowers organizations to
                    leverage a wide range of existing e-learning resources.
                  </div>
                </div>

                <div className="product-para mt-4 mb-4">
                  <strong>xAPI </strong>
                  <div>
                    Our advanced technology of xAPI supports a wide range of
                    learning activities, including mobile learning, simulations,
                    virtual worlds, serious games, real-world activities,
                    experiential learning, social learning, offline learning,
                    and collaborative learning. This flexibility allows you to
                    create engaging and effective learning experiences that
                    cater to diverse learning styles and needs. By adopting our
                    LMS, you gain the ability to effectively utilize and manage
                    diverse e-learning content, fostering a more engaging and
                    comprehensive learning experience.
                  </div>
                </div>
              </div>
            </div>
            <h1 className="product-subheading">
              Automate Administrative Tasks
            </h1>
            <div className="product-para mt-4 mb-4">
              Aeon LMS simplifies and automates various administrative tasks,
              ensuring a smooth and efficient learning journey for your
              employees.
            </div>
            <div className="row mb-5 align-items-center">
              <div className="col-lg-6">
                <div className="product-para mt-4 mb-4">
                  <strong>Course Marketing: </strong> Automated marketing tools
                  help promote your courses to the right audience, increasing
                  enrollment and engagement.
                </div>
                <div className="product-para mt-4 mb-4">
                  <strong> Learner Notifications: </strong> Automatically send
                  notifications to learners about course updates, deadlines, and
                  upcoming events, keeping them informed and engaged.
                </div>
                <div className="product-para mt-4 mb-4">
                  <strong> Completion Reminders: </strong> Ensure learners
                  complete their courses on time with automated reminders,
                  reducing dropout rates and enhancing course completion rates.
                </div>
              </div>
              <div className="col-lg-6">
                {/* <img
                  src={LMSProduct2}
                  width="90%"
                  alt="img"
                  style={{ marginLeft: "30px" }}
                /> */}
                <div className="product-video-container pVideo-two">
                  <video
                    src={productVideo2}
                    controls={false}
                    autoPlay={true}
                    loop
                    muted
                  />
                </div>
              </div>
            </div>
            <div className="product-para mt-4 mb-5">
              These automated processes reduce the administrative burden on your
              team, allowing them to focus on more strategic tasks.
            </div>
            <div className="row mb-5 align-items-center">
              <div className="col-lg-6">
                {/* <img src={LMSProduct3} width="90%" alt="img" /> */}
                <div className="product-video-container pVideo-two">
                  <video
                    src={productVideo3}
                    controls={false}
                    autoPlay={true}
                    loop
                    muted
                  />
                </div>
              </div>
              <div className="col-lg-6">
                <div className="product-subheading">Continuous Improvement</div>
                <div className="product-para mt-4 mb-4">
                  <strong>Adaptive and Evolving Platform: </strong> Aeon LMS is
                  designed to grow with your organization, continuously
                  improving to meet your evolving needs.
                </div>
                <div className="product-para mt-4 mb-4">
                  <strong> Integration with Payment Gateways: </strong>{" "}
                  Seamlessly manage transactions and course payments, providing
                  a hassle-free experience for both administrators and learners.
                </div>
                <div className="product-para mt-4 mb-4">
                  <strong> Enhanced Reports and Analytics: </strong> Gain
                  personalized insights into learner progress and course
                  effectiveness with advanced reporting and analytics tools.
                  These insights enable data-driven decisions, helping you
                  refine your training programs for better outcomes.
                </div>
              </div>
            </div>
            <div className="product-subheading">
              Comprehensive Learning Tools
            </div>
            <div className="product-para mt-4 mb-4">
              Support for Blended Learning: Aeon LMS offers a wide range of
              tools to support various learning modalities, ensuring a
              comprehensive learning experience:
            </div>
            <div className="row align-items-center gx-5">
              <div className="col-lg-6">
                <div className="product-para mt-4 mb-4">
                  <strong>Virtual Classrooms: </strong> Facilitate interactive
                  and engaging learning sessions with integrated virtual
                  classroom capabilities.
                </div>
                <div className="product-para mt-4 mb-4">
                  <strong> Automatic Course Deactivation: </strong>
                  Automatically deactivate courses that are outdated or no
                  longer relevant, ensuring your course catalog remains current
                  and valuable.
                </div>
                <div className="product-para mt-4 mb-4">
                  <strong> E-Store Integration: </strong>
                  Provide easy access to learning resources and materials
                  through an integrated e-store, simplifying the process of
                  purchasing and distributing learning content.
                </div>
              </div>
              <div className="col-lg-6 mb-5">
                {/* <img
                  src={LMSProduct4}
                  width="80%"
                  alt="learning management system"
                  style={{ marginLeft: "40px" }}
                /> */}
                <div className="product-video-container pVideo-two">
                  <video
                    src={productVideo4}
                    controls={false}
                    autoPlay={true}
                    loop
                    muted
                  />
                </div>
              </div>
            </div>

            <div className="contact-us-end-container">
              <span className="product-para">
                Transform your team's learning experience with Aeon LMS. Get
                started today to see how our platform can boost productivity and
                growth. Contact us now for a personalized demo!
              </span>
              <Link className="contactUs-link" to="/contact-us">
                Request a Demo
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LMS;
