import React, { useEffect, useState, useRef } from "react";
import mainImg from "../../Assests/Domains/eCommDomainImg.png";
import "./domain.css";
import ECommerceImg from "../../Assests/Domains/e-commerce.png";
import Banner from "../Banner";
import info3 from "../../Assests/Domains/transportInfo3.png";
import info1 from "../../Assests/Domains/transportInfo1.jpg";
import { Link } from "react-router-dom";
import info2 from "../../Assests/Domains/tranportInfo2.png";
import Aos from "aos";
import "aos/dist/aos.css";

import EcomInfoGraph1 from "../../Assests/Domains/ecommerce/1.png";
import EcomInfoGraph2 from "../../Assests/Domains/ecommerce/2.png";
import EcomInfoGraph3 from "../../Assests/Domains/ecommerce/3.png";
import EcomInfoGraph4 from "../../Assests/Domains/ecommerce/4.png";
import EcomInfoGraph5 from "../../Assests/Domains/ecommerce/5.png";
import EcomInfoGraph6 from "../../Assests/Domains/ecommerce/6.png";
import EcomInfoGraph7 from "../../Assests/Domains/ecommerce/7.png";
import EcomInfoGraph8 from "../../Assests/Domains/ecommerce/8.png";
import ExploreDomains from "./ExploreDomains";
import domainVideo from "../../Assests/Domains/Videos/Aeon ECommerce.mp4";
import Modal from "react-bootstrap/Modal";

function ECommerce() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  return (
    <div>
      <div className="aboutUs-main-container miningDomain">
        <Banner mainImg={mainImg} title="Domains" />
        <div className="below-banner-container domain-main-container">
          <h1 className="domain-banner-heading">E-Commerce</h1>
          <div className="domain-inner-container">
            <div className="domain-para">
              In the E-commerce game, staying ahead of the competition means
              harnessing the latest innovations to drive sales, enhance customer
              experiences, and optimize operations. Aeon’s tailored website and
              app development solutions can empower your ventures to thrive in
              the digital marketplace
            </div>

            <div className="domain-info1">
              <div className="domain-info1-img mining">
                <img src={ECommerceImg} alt="E-Commerce" />
                <div className="circle-anim" onClick={handleShow}>
                  <div className="discover-now-btn">
                    <div className="step">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Bold"
                        viewBox="0 0 24 24"
                        width="60"
                        height="35"
                        fill="#37466F"
                      >
                        <path d="M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z" />
                      </svg>
                      <div className="ripple"></div>
                      <div className="ripple"></div>
                      <div className="ripple"></div>
                    </div>
                  </div>
                </div>

                <div className="opaque-screen domain-opaque-film"></div>
                <Modal show={show} onHide={handleClose} centered size="lg">
                  <Modal.Body className="domain-video-modal">
                    <div>Click outside the box to close</div>
                    <video
                      src={domainVideo}
                      autoPlay
                      loop
                      controls
                      controlsList="nodownload"
                      muted
                    />
                  </Modal.Body>
                </Modal>
              </div>
            </div>

            <div className="domain-subheading">
              <h3>
                We believe in giving our clients experiences that they keep
                wanting to come back for.
              </h3>
            </div>

            <div className="domain-subpara">
              In the past, we have successfully created e-commerce websites for
              food and pet care products. Our meticulously designed interfaces
              have contributed to their continued customer base.
            </div>
            <div className="domain-subpara">
              Create exciting and reliable shopping experiences for your
              customers, while ensuring a resilient and easy-to-manage
              infrastructure behind-the-scenes.
            </div>

            <div className="">
              <div className="">
                <div className="domain-descrip">
                  <div className="sub-heading-product product-descrip-heading">
                    Our comprehensive e-commerce platforms include:
                  </div>
                  <div className="new-ecomm-container-info">
                    <div className="depo-img depo-image2 ecomm-img mb-4 mt-5 ">
                      <div
                        className="infOrder2"
                        data-aos="zoom-in"
                        data-aos-duration="500"
                      >
                        <div className="infograph-out">
                          <div className="infograph">
                            <div className="infograph-text">
                              <span>Inventory Management Systems</span>
                            </div>
                            <div className="infograph-img">
                              <img
                                src={EcomInfoGraph2}
                                width={165}
                                alt="Inventory Management Systems"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={"infOrder4"}
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <div className="infograph-out">
                          <div className="infograph">
                            <div className="infograph-text">
                              <span>Billing and Order Management</span>
                            </div>
                            <div className="infograph-img">
                              <img
                                src={EcomInfoGraph4}
                                width={165}
                                alt="Billing and Order Management"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={"infOrder6"}
                        data-aos="zoom-in"
                        data-aos-duration="1500"
                      >
                        <div className="infograph-out">
                          <div className="infograph">
                            <div className="infograph-text">
                              <span>Customer Support Integrations</span>
                            </div>
                            <div className="infograph-img">
                              <img
                                src={EcomInfoGraph6}
                                width={165}
                                alt="Customer Support Integrations"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={"infOrder8"}
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                      >
                        <div className="infograph-out">
                          <div className="infograph">
                            <div className="infograph-text">
                              <span>Analytics and Reporting</span>
                            </div>
                            <div className="infograph-img">
                              <img
                                src={EcomInfoGraph8}
                                width={165}
                                alt="Analytics and Reporting"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="depo-img ecomm-img">
                      <div
                        className={"infOrder1"}
                        data-aos="zoom-in"
                        data-aos-duration="500"
                      >
                        <div className="infograph-out2">
                          <div className="infograph">
                            <div className="infograph-img">
                              <img
                                src={EcomInfoGraph1}
                                width={165}
                                alt="Custom UI/ UX Solutions"
                              />
                            </div>
                            <div className="infograph-text">
                              <span>Custom UI/ UX Solutions</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={"infOrder3"}
                        data-aos="zoom-in"
                        data-aos-duration="1000"
                      >
                        <div className="infograph-out2">
                          <div className="infograph">
                            <div className="infograph-img">
                              <img
                                src={EcomInfoGraph3}
                                width={165}
                                alt="Payment Gateway Integrations"
                              />
                            </div>
                            <div className="infograph-text">
                              <span>Payment Gateway Integrations</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={"infOrder5"}
                        data-aos="zoom-in"
                        data-aos-duration="1500"
                      >
                        <div className="infograph-out2">
                          <div className="infograph">
                            <div className="infograph-img">
                              <img
                                src={EcomInfoGraph5}
                                width={165}
                                alt="Shipping Vendor Management"
                              />
                            </div>
                            <div className="infograph-text">
                              <span>Shipping Vendor Management</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={"infOrder7"}
                        data-aos="zoom-in"
                        data-aos-duration="2000"
                      >
                        <div className="infograph-out2">
                          <div className="infograph">
                            <div className="infograph-img">
                              <img
                                src={EcomInfoGraph7}
                                width={165}
                                alt="Digital Training Solutions"
                              />
                            </div>
                            <div className="infograph-text">
                              <span>Digital Training Solutions</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <div className="contact-us-end-container">
                  <span className="product-para">
                    Invite profitability and sustainable growth by collaborating
                    with Aeon.
                  </span>
                  <Link className="contactUs-link" to="/contact-us">
                    Get in touch!
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="explore-domains">
            <div className="heading">
              <h2>Explore Our Other Domains</h2>
            </div>
            <ExploreDomains />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ECommerce;
