import React, { useEffect, useState } from "react";
import mainImg from "../Assests/contactUs.png";
import Banner from "./Banner";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import thankYouImg from "../Assests/thankYou.png";
import Modal from "react-bootstrap/Modal";
import ContactService from "../ApiService/contact.service";
import { RotatingLines } from "react-loader-spinner";

function ContactUs() {
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: "",
    mobileNum: "",
    email: "",
    companyName: "",
    jobTitle: "",
    companySize: "",
    country: "",
  });

  const inputHandler = (e) => {
    const { name, value } = e.target;
    const mobileNumRegex = /^\d{0,10}$/;

    if (name === "mobileNum" && !mobileNumRegex.test(value)) {
      return;
    }
    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      toast("*Please fill all required fields!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return;
    } else {
      setLoading(true);
      handleShow();
      ContactService.submitRequestDemoData(userDetails)
        .then(
          (response) => {
            if (response.data === 1) {
              setLoading(false);
              setUserDetails({
                name: "",
                mobileNum: "",
                email: "",
                companyName: "",
                jobTitle: "",
                companySize: "",
                country: "",
              });
            }
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            toast(`${resMessage}`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setLoading(false);
            handleClose();
          }
        )
        .catch((e) => console.log(e));
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleClose = () => {
    setShow(false);
    setLoading(false);
  };
  const handleShow = () => setShow(true);

  return (
    <div>
      <ToastContainer />

      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Get In Touch" />
        <div className="below-banner-mad-container">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div className="row gx-5 px-5 pt-5">
              <div className="col-lg-6 mb-5">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Name<span className="red-star">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Enter your name"
                    required
                    name="name"
                    value={userDetails.name}
                    onChange={inputHandler}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6 mb-5">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Mobile No.</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Type here..."
                    // required
                    name="mobileNum"
                    value={userDetails.mobileNum}
                    onChange={inputHandler}
                  />
                </Form.Group>
              </div>

              <div className="col-lg-12 mb-5">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Email Id<span className="red-star">*</span>
                  </Form.Label>
                  <Form.Control
                    type="email"
                    // placeholder="Type here..."
                    required
                    name="email"
                    value={userDetails.email}
                    onChange={inputHandler}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6 mb-5">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Job Title</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Type here..."
                    // required
                    name="jobTitle"
                    value={userDetails.jobTitle}
                    onChange={inputHandler}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6 mb-5">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Company Name<span className="red-star">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Type here..."
                    required
                    name="companyName"
                    value={userDetails.companyName}
                    onChange={inputHandler}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6 mb-5">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>
                    Company Size (Number of employee)
                    {/* <span className="red-star">*</span> */}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Type here..."
                    // required
                    name="companySize"
                    value={userDetails.companySize}
                    onChange={inputHandler}
                  />
                </Form.Group>
              </div>
              <div className="col-lg-6 mb-5">
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Country</Form.Label>
                  <Form.Control
                    type="text"
                    // placeholder="Type here..."
                    // required
                    name="country"
                    value={userDetails.country}
                    onChange={inputHandler}
                  />
                </Form.Group>
              </div>
            </div>
            <button type="submit" className="contactUs-link mt-3">
              Submit
            </button>
          </Form>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body
          className="d-flex justify-content-center align-items-center"
          style={{ height: "400px", width: "500px" }}
        >
          {loading ? (
            <div className="spinner-container">
              <RotatingLines
                visible={true}
                height="80"
                width="80"
                color="#37466F"
                strokeColor="#37466F"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          ) : (
            <img width="90%" src={thankYouImg} alt="img" />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ContactUs;
