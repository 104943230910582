import React, { useEffect } from "react";
import mainImg from "../../Assests/Services/serviceBanner.png";
import Banner from "../Banner";
import "./service.css";
import { Link } from "react-router-dom";
import pmcImg from "../../Assests/Services/pmcImg.png";
import pmcImg2 from "../../Assests/Services/pmcImg2.png";
import info1 from "../../Assests/Services/pmcInfo1.png";
import info2 from "../../Assests/Services/pmcInfo2.png";
import info3 from "../../Assests/Services/pmcInfo3.png";
import info4 from "../../Assests/Services/pmcInfo4.png";
import info5 from "../../Assests/Services/pmcInfo5.png";
import info6 from "../../Assests/Services/pmcInfo6.png";
import info7 from "../../Assests/Services/pmcInfo7.png";
import info8 from "../../Assests/Services/pmcInfo8.png";
import info9 from "../../Assests/Services/pmcInfo9.png";

function ProjectConsultancy() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Services" />
        <div className="below-banner-mad-container">
          <h1 className="product-banner-heading">
            IT Project Management Consultancy
          </h1>
          <div className="row gx-5 justify-content-between align-items-center">
            <div className="col-lg-7 service-para">
              At Aeon, we provide specialized IT Project Management Consultancy
              services designed to help organizations navigate the complexities
              of IT projects. Our approach ensures that projects are completed
              on time, within budget, and meet the highest quality standards.
              Here’s how we do it:
            </div>
            <div className="col-lg-5 dls-image1">
              <img src={pmcImg} alt="Project Management Consultancy " />
            </div>
          </div>
        </div>
        <div className="mad-service-second-container">
          <div className="product-banner-heading mad-third-heading dls-second-heading mb-5">
            Our Approach
          </div>
          <div className="text-start pmc-approach-points-container">
            <div className="pmc-approach-points-heading">
              1. Initial Assessment and Planning
            </div>
            <div className="pmc-approach-points mx-3">
              <span className="pmc-approach-points-label">
                Needs Analysis:{" "}
              </span>
              <span>
                We start by understanding your project requirements, objectives,
                and constraints. This involves detailed discussions with key
                stakeholders to define the project scope, deliverables, and
                expected outcomes.
              </span>
            </div>
            <div className="pmc-approach-points mx-3 mt-3">
              <span className="pmc-approach-points-label">
                Strategic Planning:{" "}
              </span>
              <span>
                Based on the initial assessment, we create a comprehensive
                project plan. This plan includes project phases, milestones,
                resource allocation, and risk management strategies to address
                potential challenges proactively.
              </span>
            </div>
          </div>
          <div className="text-start pmc-approach-points-container mt-5">
            <div className="pmc-approach-points-heading">
              2. Project Execution and Monitoring
            </div>
            <div className="pmc-approach-points mx-3">
              <span className="pmc-approach-points-label">
                Agile Methodology:{" "}
              </span>
              <span>
                We use agile project management methodologies that allow for
                flexibility and iterative progress. This helps us adapt to
                changes quickly and deliver incremental value throughout the
                project lifecycle.
              </span>
            </div>
            <div className="pmc-approach-points mx-3 mt-3">
              <span className="pmc-approach-points-label">
                Resource Management:{" "}
              </span>
              <span>
                Efficient management of resources is crucial for project
                success. We utilize advanced tools to ensure optimal use of
                personnel, technology, and financial resources.
              </span>
            </div>
            <div className="pmc-approach-points mx-3 mt-3">
              <span className="pmc-approach-points-label">
                Continuous Monitoring:{" "}
              </span>
              <span>
                We implement robust monitoring and reporting mechanisms to track
                project progress against the planned schedule and budget.
                Regular status updates and performance metrics keep all
                stakeholders informed.{" "}
              </span>
            </div>
          </div>
          <div className="text-start pmc-approach-points-container mt-5">
            <div className="pmc-approach-points-heading">
              3. Quality Assurance and Risk Management
            </div>
            <div className="pmc-approach-points mx-3">
              <span className="pmc-approach-points-label">
                Quality Control:{" "}
              </span>
              <span>
                We follow stringent quality assurance protocols to ensure all
                deliverables meet defined standards and client expectations.
                This includes regular quality checks, testing phases, and
                validation procedures.
              </span>
            </div>
            <div className="pmc-approach-points mx-3 mt-3">
              <span className="pmc-approach-points-label">
                Risk Management:{" "}
              </span>
              <span>
                We identify and mitigate risks using advanced risk management
                frameworks. This helps us anticipate potential issues and
                develop contingency plans to address them proactively.
              </span>
            </div>
          </div>
          <div className="text-start pmc-approach-points-container mt-5">
            <div className="pmc-approach-points-heading">
              4. Communication and Collaboration
            </div>
            <div className="pmc-approach-points mx-3">
              <span className="pmc-approach-points-label">
                Stakeholder Engagement:{" "}
              </span>
              <span>
                Effective communication is key to successful project management.
                We establish clear communication channels and protocols to
                ensure consistent and transparent dialogue with all
                stakeholders.
              </span>
            </div>
            <div className="pmc-approach-points mx-3 mt-3">
              <span className="pmc-approach-points-label">
                Collaborative Tools:{" "}
              </span>
              <span>
                We use the latest collaboration tools to facilitate seamless
                interaction among project teams, enabling real-time updates,
                document sharing, and collaborative problem-solving.
              </span>
            </div>
          </div>
          <div className="text-start pmc-approach-points-container mt-5">
            <div className="pmc-approach-points-heading">
              5. Project Closure and Evaluation
            </div>
            <div className="pmc-approach-points mx-3">
              <span className="pmc-approach-points-label">
                Completion and Handover:{" "}
              </span>
              <span>
                Upon project completion, we ensure a smooth transition and
                handover of deliverables. This includes comprehensive
                documentation, training sessions, and support to ensure the
                client team is fully equipped to manage the new system.
              </span>
            </div>
            <div className="pmc-approach-points mx-3 mt-3">
              <span className="pmc-approach-points-label">
                Post-Implementation Review:{" "}
              </span>
              <span>
                We conduct a thorough post-implementation review to evaluate the
                project’s success against initial objectives. This involves
                gathering feedback, analyzing performance data, and identifying
                areas for improvement.
              </span>
            </div>
          </div>
        </div>
        <div className="below-banner-mad-container">
          <div className="product-banner-heading mad-third-heading dls-second-heading mb-3">
            Serving a Leading Agritech Company
          </div>
          <div className="row gx-5 justify-content-between align-items-center mb-5">
            <div className="col-lg-7 service-para">
              We are currently providing IT Project Management Consultancy
              services to a prominent Agritech company, leveraging our expertise
              to support their innovative projects in agricultural technology.
              This partnership underscores our commitment to delivering tailored
              solutions that drive business success and technological
              advancement in the Agritech sector.
            </div>
            <div className="col-lg-5 dls-image1">
              <img src={pmcImg2} alt="image" />
            </div>
          </div>
          <div className="product-banner-heading mad-third-heading dls-second-heading mb-3">
            Technical Expertise
          </div>
          <div className="pmc-expertise-info d-flex justify-content-center align-items-center">
            <img src={info1} alt="Project Management Tools" />
            <img src={info2} alt="Cloud Integration" />
            <img src={info3} alt="Cyber Security" />
            <img src={info4} alt="Data Analytics" />
            <img src={info5} alt="Software developmemt cycle" />
          </div>
        </div>
        <div className="mad-service-second-container">
          <div className="product-banner-heading service-third-heading">
            <div>Maximizing Value through Effective IT Project Delivery </div>
          </div>
          <div className="product-banner-heading mad-third-heading dls-second-heading mb-3">
            Why Choose Aeon?
          </div>
          <div className="pmc-expertise-info d-flex justify-content-center align-items-center mb-5">
            <img src={info6} alt="why choose aeon" />
            <img src={info7} alt="why choose aeon" />
            <img src={info8} alt="why choose aeon" />
            <img src={info9} alt="why choose aeon" />
          </div>
          <div className="service-para">
            We understand that effective IT project management is critical to
            achieving business objectives and staying competitive in today’s
            fast-paced digital landscape. Our consultancy services provide the
            strategic guidance, technical expertise, and hands-on support needed
            to ensure your IT projects succeed. Let us help you navigate the
            complexities of IT project management and achieve your goals with
            confidence.
          </div>
          <div className="contact-us-end-container mt-5">
            <span className="contact-us-end-text">
              For more information on how our IT Project Management Consultancy
              services can benefit your organization, contact us.
            </span>
            <Link className="contactUs-link" to="/contact-us">
              Contact Us!
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectConsultancy;
