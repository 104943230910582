import React, { useEffect } from "react";
import mainImg from "../../Assests/Services/serviceBanner.png";
import Banner from "../Banner";
import "./service.css";
import { Link } from "react-router-dom";
import dlsImg1 from "../../Assests/Services/dlsImg1.png";
import info1 from "../../Assests/Services/dlsInfo1.png";
import info2 from "../../Assests/Services/dlsInfo2.png";
import info3 from "../../Assests/Services/dlsInfo3.png";
import info4 from "../../Assests/Services/dlsInfo4.png";
import info5 from "../../Assests/Services/dlsInfo5.png";
import info6 from "../../Assests/Services/dlsInfo6.png";
import info7 from "../../Assests/Services/dlsInfo7.png";
import info8 from "../../Assests/Services/dlsInfo8.png";
import info9 from "../../Assests/Services/dlsInfo9.png";

function DigitalLearnSolution() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Services" />
        <div className="below-banner-mad-container">
          <h1 className="product-banner-heading">Digital Learning Solutions</h1>
          <div className="row gx-5 justify-content-between align-items-center">
            <div className="col-lg-7 service-para">
              At the core of our mission is the transformation of education
              through digitization. Imagine building a solid structure with just
              two key elements. In the world of learning programs, that's
              exactly what our journey has been about – creating a strong
              foundation with our Learning Management System (LMS) and Training
              Management System (TMS) solutions. Our goal is to facilitate the
              digitization of learning experiences, ensuring that managing
              instructor-led training becomes less daunting and more effective
              in the digital age.
            </div>
            <div className="col-lg-5 dls-image1">
              <img src={dlsImg1} alt="Digital Learning Solutions" />
            </div>
            <div className="col-lg-12 service-para mt-4">
              With our tools, we bring clarity and efficiency to this
              transformation, making the shift from traditional to digital
              learning smooth and seamless.
            </div>
          </div>
        </div>
        <div className="mad-service-second-container">
          <div className="product-banner-heading mad-third-heading dls-second-heading mb-5">
            From Traditional to Digital: LMS and TMS
          </div>
          <div className="service-para">
            Think of a world where learning is as easy as flipping through a
            digital manual. With our tailored LMS and TMS solutions, we've
            helped organizations across various industries streamline their
            operations and elevate their training methodologies to new heights.
          </div>
          <div className="dls-service-info2">
            <img src={info1} alt="lms and tms" />
          </div>
        </div>
        <div className="below-banner-mad-container">
          <div className="product-banner-heading dls-sub-heading">
            <div>Pioneering Digital Learning: </div>
            <div>Transforming Education, One Click at a Time.</div>
          </div>
          <div className="service-para">
            Think of a world where learning is as easy as flipping through a
            digital manual. With our tailored LMS and TMS solutions, we've
            helped organizations across various industries streamline their
            operations and elevate their training methodologies to new heights.
          </div>
          <div className="dls-service-info3">
            <img src={info2} alt="Digital Learning" />
          </div>
        </div>
        <div className="mad-service-third-container">
          <div className="text-start">Our Projects</div>
          <div className="service-para mt-5 dls-projects-sub-head">
            From Skies to Screens: Aeon's Commitment to Digital Education
            Evolution
          </div>
          <div className="dls-projects-container">
            <div className="row gx-4">
              <div className="col-4 col-lg-4">
                <img width="90%" src={info3} alt="Pilot Training" />
                <div className="mt-3 mb-4">
                  <div>Pilot Training</div>
                  <div>(UPRT)</div>
                </div>
              </div>
              <div className="col-4 col-lg-4">
                <img width="90%" src={info4} alt="Ground Staff Training" />
                <div className="mt-3 mb-4">
                  {" "}
                  <div>Ground Staff Training</div>
                  <div>(Marshalling)</div>
                </div>
              </div>
              <div className="col-4 col-lg-4">
                <img width="90%" src={info5} alt="Aviation Security" />
                <div className="mt-3 mb-4">
                  <div>Aviation Security</div>
                  <div>(ASA)</div>
                </div>
              </div>

              <div className="col-4 col-lg-4">
                <img width="90%" src={info6} alt="Airport Operations" />
                <div className="mt-3 mb-4">
                  <div>Airport Operations</div>
                  <div>(DPH)</div>
                </div>
              </div>
              <div className="col-4 col-lg-4 mb-0">
                <img width="90%" src={info7} alt="Professional Development" />
                <div className="mt-3 mb-4">
                  <div>Professional Development</div>
                  <div>(MCW)</div>
                </div>
              </div>
              <div className="col-4 col-lg-4 mb-0">
                <img width="90%" src={info8} alt="POSH" />
                <div className="mt-3 mb-4">POSH</div>
              </div>
            </div>
          </div>
        </div>
        <div className="below-banner-mad-container">
          <div className="row gx-5 justify-content-between align-items-center">
            <div className="col-lg-6 service-para">
              Our Learning & Training Management solutions are designed to
              support educational institutions, corporate training programs, and
              everything in between. By combining innovative technology with a
              deep understanding of educational needs, we help organizations
              create better learning environments and achieve their goals.
            </div>
            <div className="col-lg-6 dls-image1">
              <img src={info9} alt="lms" />
            </div>
          </div>
          <div className="contact-us-end-container mt-5">
            <span className="contact-us-end-text">
              Let's work together to improve education for everyone. We want to
              create a future where people can learn without limits.
            </span>
            <Link className="contactUs-link" to="/contact-us">
              Contact Us!
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DigitalLearnSolution;
