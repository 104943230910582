import React, { useEffect, useState, useRef } from "react";
import "./style.css";
import { Link } from "react-router-dom";
import aboutUsImg from "../Assests/aboutUsImg.png";
import Aos from "aos";
import "aos/dist/aos.css";
import domain1 from "../Assests/domain1.png";
import domain2 from "../Assests/domain2.png";
import domain3 from "../Assests/domain3.png";
import domain4 from "../Assests/domain4.png";
import domain5 from "../Assests/domain5.png";
import domain6 from "../Assests/domain6.png";
import domain7 from "../Assests/domain7.png";
import domain8 from "../Assests/domain8.png";
import servicePolygon from "../Assests/Services/servicePolygon.png";
import service1 from "../Assests/Services/service1.png";
import service2 from "../Assests/Services/service2.png";
import service3 from "../Assests//Services/service3.png";
import service4 from "../Assests/Services/service4.png";
import service5 from "../Assests/Services/service5.png";
import service6 from "../Assests/Services/service6.png";
import service7 from "../Assests/Services/service7.png";
import service8 from "../Assests/Services/service8.png";
import service9 from "../Assests/Services/service9.png";
import service11 from "../Assests/Services/service1.1.gif";
import service12 from "../Assests/Services/service2.1.gif";
import service13 from "../Assests/Services/service3.1.gif";
import service14 from "../Assests/Services/service4.1.gif";
import service15 from "../Assests/Services/service5.1.gif";
import service16 from "../Assests/Services/service6.1.gif";
import service17 from "../Assests/Services/service7.1.gif";
import service18 from "../Assests/Services/service8.1.gif";
import service19 from "../Assests/Services/service9.1.gif";
import mobileTktGold from "../Assests/Products/mobileTktGold.gif";
import mobileTktBlue from "../Assests/Products/mobileTktImg.png";
import lmsBlue from "../Assests/Products/lmsBlueImg.png";
import lmsGold from "../Assests/Products/lmsGold.gif";
import tmsBlue from "../Assests/Products/tmsBlueImg.png";
import tmsGold from "../Assests/Products/tmsGold.gif";
import depotBlue from "../Assests/Products/depotBlueImg.png";
import depotGold from "../Assests/Products/depotGold.gif";
import puneMap from "../Assests/puneMap.png";
import mumbaiMap from "../Assests/mumbaiMap.png";
import indoreMap from "../Assests/indoreMap.png";
import gujaratMap from "../Assests/gujaratMap.png";
import usaMap from "../Assests/usaMap.png";
import canadaMap from "../Assests/canadaMap.png";
import bahrainMap from "../Assests/bahrainMap.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Form } from "react-bootstrap";
import imagesData from "../data/images.json";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import contactUsImg from "../Assests/contactUsImg.png";
import thankYouImg from "../Assests/thankYou.png";
import Modal from "react-bootstrap/Modal";
import ContactService from "../ApiService/contact.service";
import { RotatingLines } from "react-loader-spinner";
import { Carousel } from "react-bootstrap";
import homeImg from "../Assests/homeImg.png";
import sliderImg1 from "../Assests/sliderImg1.png";
import sliderImg2 from "../Assests/sliderImg2.png";
import sliderImg3 from "../Assests/sliderImg3.png";

function Homescreen() {
  const [locations, setLocations] = useState([
    "India",
    "USA",
    "Canada",
    "Bahrain",
  ]);
  const [addressLoc, setAddressLoc] = useState([
    "Pune",
    "Mumbai",
    "Gujarat",
    "Indore",
  ]);

  const [show, setShow] = useState(false);
  const [activeTag, setActiveTag] = useState("India");
  const [activeLoc, setActiveLoc] = useState("Pune");
  const [mapImg, setMapImg] = useState(puneMap);
  const [validated, setValidated] = useState(false);
  const [clientImages, setClientImages] = useState(imagesData);
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    name: "",
    mobileNum: "",
    jobTitle: "",
    email: "",
    message: "",
  });

  const handleTagClick = (tag) => {
    setActiveTag(tag);
    if (tag === "India") {
      setMapImg(puneMap);
      setActiveLoc("Pune");
    } else if (tag === "USA") {
      setMapImg(usaMap);
    } else if (tag === "Canada") {
      setMapImg(canadaMap);
    } else if (tag === "Bahrain") {
      setMapImg(bahrainMap);
    }
  };

  const handleLocClick = (loc) => {
    setActiveLoc(loc);
    if (loc === "Pune") {
      setMapImg(puneMap);
    } else if (loc === "Mumbai") {
      setMapImg(mumbaiMap);
    } else if (loc === "Gujarat") {
      setMapImg(gujaratMap);
    } else if (loc === "Indore") {
      setMapImg(indoreMap);
    }
  };

  useEffect(() => {
    Aos.init();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      toast("*Please fill all required fields!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return;
    } else {
      setLoading(true);
      handleShow();
      ContactService.submitContactUsData(userDetails)
        .then(
          (response) => {
            if (response.data === 1) {
              setLoading(false);
              setUserDetails({
                name: "",
                mobileNum: "",
                jobTitle: "",
                email: "",
                message: "",
              });
            }
          },
          (error) => {
            const resMessage =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();

            toast(`${resMessage}`, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            setLoading(false);
            handleClose();
          }
        )
        .catch((e) => console.log(e));
    }
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    const mobileNumRegex = /^\d{0,10}$/;

    if (name === "mobileNum" && !mobileNumRegex.test(value)) {
      return;
    }
    setUserDetails({ ...userDetails, [name]: value });
  };

  const groupedClientImages = [];
  for (let i = 0; i < clientImages.length; i += 10) {
    groupedClientImages.push(clientImages.slice(i, i + 10));
  }

  const handleClose = () => {
    setShow(false);
    setLoading(false);
  };
  const handleShow = () => setShow(true);

  const [activeServiceId, setActiveServiceId] = useState("");
  const [activeProductId, setActiveProductId] = useState("");

  const handleMouseHover = (id) => {
    setActiveServiceId(id);
    setActiveProductId(id);
  };

  const handleMouseLeave = () => {
    setActiveServiceId("");
    setActiveProductId("");
  };

  return (
    <div>
      {/* <div className="home-container" id="home">
        <div className="opaque-screen"></div>
        <div className="inner-container">
          <h1>Simplify, Delight, Thrive:</h1>
          <h1 className="mb-4">Aeon's Approach to Business Solutions</h1>
          <span>
            Get ready for smoother operations, happier customers, and
            unstoppable growth with Aeon.
          </span>
          <span>
            We keep it simple, delighting you every step of the way so your
            business can thrive like never before.
          </span>
          <div className="discover-now-btn mt-5">
            <div className="step">
              <div className="discover-now-text">Coming Soon...</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Bold"
                viewBox="0 0 24 24"
                width="60"
                height="35"
                fill="#37466F"
              >
                <path d="M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z" />
              </svg>
              <div className="ripple"></div>
              <div className="ripple"></div>
              <div className="ripple"></div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="home-container" id="home">
        <Carousel
          style={{ height: "100vh" }}
          fade
          controls={false}
          pause={false}
          interval={2000}
        >
          <Carousel.Item style={{ height: "100vh" }}>
            <img className="d-block w-100" src={homeImg} alt="First slide" />
            <div className="opaque-screen"></div>
            <Carousel.Caption>
              <div className="inner-container">
                <h1>Simplify, Delight, Thrive:</h1>
                <h1 className="mb-4">Aeon's Approach to Business Solutions</h1>
                <span>
                  Get ready for smoother operations, happier customers, and
                  unstoppable growth with Aeon.
                </span>
                <span>
                  We keep it simple, delighting you every step of the way so
                  your business can thrive like never before.
                </span>
                {/* <div className="discover-now-btn mt-5">
                  <div className="step">
                    <div className="discover-now-text">Coming Soon...</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Bold"
                      viewBox="0 0 24 24"
                      width="60"
                      height="35"
                      fill="#37466F"
                    >
                      <path d="M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z" />
                    </svg>
                    <div className="ripple"></div>
                    <div className="ripple"></div>
                    <div className="ripple"></div>
                  </div>
                </div> */}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item style={{ height: "100vh" }}>
            <img
              className="d-block w-100"
              src={sliderImg1}
              alt="Second slide"
            />
            <div className="opaque-screen"></div>
            <Carousel.Caption>
              <div className="inner-container">
                <h1 className="mb-4">Digital Experiences that Communicates</h1>
                {/* <h1 className="mb-4"></h1> */}
                <span>Your vision, our expertise.</span>
                <span>
                  Crafting digital experiences that captivate and engage.
                </span>

                {/* <div className="discover-now-btn mt-5">
                  <div className="step">
                    <div className="discover-now-text">Coming Soon...</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Bold"
                      viewBox="0 0 24 24"
                      width="60"
                      height="35"
                      fill="#37466F"
                    >
                      <path d="M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z" />
                    </svg>
                    <div className="ripple"></div>
                    <div className="ripple"></div>
                    <div className="ripple"></div>
                  </div>
                </div> */}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item style={{ height: "100vh" }}>
            <img className="d-block w-100" src={sliderImg2} alt="Third slide" />
            <div className="opaque-screen"></div>
            <Carousel.Caption>
              <div className="inner-container">
                <h1 className="mb-4">Your Digital Tale, Well Designed.</h1>
                {/* <h1 className="mb-4">Aeon's Approach to Business Solutions</h1> */}
                <span>
                  Bring your content to life with our captivating animations.
                </span>
                <span> Learn and engage like never before.</span>
                {/* <div className="discover-now-btn mt-5">
                  <div className="step">
                    <div className="discover-now-text">Coming Soon...</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Bold"
                      viewBox="0 0 24 24"
                      width="60"
                      height="35"
                      fill="#37466F"
                    >
                      <path d="M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z" />
                    </svg>
                    <div className="ripple"></div>
                    <div className="ripple"></div>
                    <div className="ripple"></div>
                  </div>
                </div> */}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item style={{ height: "100vh" }}>
            <img
              className="d-block w-100"
              src={sliderImg3}
              alt="Fourth slide"
            />
            <div className="opaque-screen"></div>
            <Carousel.Caption>
              <div className="inner-container">
                <h1>From Concept to Creation,</h1>
                <h1 className="mb-4">Perfectly Crafted.</h1>
                <span>From imagination to innovation.</span>
                <span>
                  AEON crafts user experiences that exceed expectations.
                </span>
                {/* <div className="discover-now-btn mt-5">
                  <div className="step">
                    <div className="discover-now-text">Coming Soon...</div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      id="Bold"
                      viewBox="0 0 24 24"
                      width="60"
                      height="35"
                      fill="#37466F"
                    >
                      <path d="M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z" />
                    </svg>
                    <div className="ripple"></div>
                    <div className="ripple"></div>
                    <div className="ripple"></div>
                  </div>
                </div> */}
              </div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      <div className="aboutUs-container" id="aboutUs">
        <div className="aboutUs-inner-container row">
          <div className="aboutUs-left col-lg-6">
            <h2 className="sub-heading">
              About Us
              <span className="heading-underline"></span>
            </h2>
            <h3 className="mb-1">Feeling like your current</h3>
            <h3 className="mb-4"> tech setup is holding you back?</h3>
            <p className="mb-4">
              Welcome to Aeon, where we merge creativity with practical
              solutions. We are not just another tech company – we are your
              partners in success. With over 19 years of expertise, we excel in
              crafting tailored solutions that propel your business forward.
            </p>
            <p>
              Imagine your business running like clockwork, customers satisfied
              at every touchpoint, and growth becoming a seamless part of your
              journey – all made possible with Aeon's personalized tech
              solutions crafted just for you. From seamless integrations to
              intuitive interfaces, we make technology work for you, not against
              you.
            </p>
            <div className="explore-more mt-4">
              <Link to="/about-us">Explore More</Link>
            </div>
          </div>
          <div className="aboutUs-right col-lg-6">
            <img
              // data-aos="zoom-in"
              // data-aos-duration="3000"
              src={aboutUsImg}
              alt="aboutUsImg"
            />
          </div>
        </div>
      </div>
      <div className="domains-container" id="domains">
        <h2 className="domain-heading">Our Domains</h2>
        <div className="domains-inner-container">
          <div className="domain-first">
            <div className="domain">
              <div className="domain-left">
                <img src={domain1} alt="domainImg" />
              </div>
              <div className="domain-right1">
                <div className="domain-right1-inner">
                  <h4 className="mb-3">Transportation</h4>
                  <p>
                    With more than 15 years of experience in providing seamless
                    transport solutions and efficient ticketing services, we are
                    constantly working to simplify experiences for commuters and
                    transport service providers. From online ticketing to
                    intelligent vehicle tracking, our comprehensive depot
                    management solution aims to make travel easier. Make Aeon a
                    part of your journey to experience convenient and reliable
                    transport management.
                  </p>
                  <span>
                    <Link to="/transportationDomain">Learn More</Link>
                    <svg
                      id="_01_align_center"
                      data-name="01 align center"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.441"
                      height="8.349"
                      viewBox="0 0 14.441 8.349"
                    >
                      <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M18.926,9.433l-3.778-3.14L13.9,7.327l2.9,2.409H5V11.2H16.8l-2.9,2.409,1.245,1.034,3.778-3.14A1.3,1.3,0,0,0,18.926,9.433Z"
                        transform="translate(-5 -6.293)"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div className="domain domain-left-conatiner">
              <div className="domain-left1">
                <div className="domain-left1-inner">
                  <h4 className="mb-3">Aviation</h4>
                  <p>
                    Take-off on a journey of growth with our training solutions
                    for aviation professionals. At Aeon, we're committed to
                    elevating expertise for multiple roles in this industry
                    through innovative e-learning courses and customized
                    Learning Management System (LMS). Combined with our tech
                    solution offerings for business management, our
                    comprehensive package will make sure that your ventures soar
                    to greater heights
                  </p>
                  <span>
                    <svg
                      id="_01_align_center"
                      data-name="01 align center"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.441"
                      height="8.349"
                      viewBox="0 0 14.441 8.349"
                    >
                      <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M5.515,9.433l3.778-3.14,1.245,1.034L7.64,9.736h11.8V11.2H7.64l2.9,2.409L9.294,14.642,5.515,11.5A1.3,1.3,0,0,1,5.515,9.433Z"
                        transform="translate(-5 -6.293)"
                      />
                    </svg>
                    <Link to="/aviationDomain">Learn More</Link>
                  </span>
                </div>
              </div>
              <div className="domain-left-img">
                <img src={domain2} alt="domainImg" />
              </div>
            </div>
          </div>

          <div className="domain-second">
            <div className="domain">
              <div className="domain-left">
                <img src={domain3} alt="domainImg" />
              </div>
              <div className="domain-right1">
                <div className="domain-right1-inner">
                  <h4 className="mb-3">Agriculture</h4>
                  <p>
                    Organize your agricultural endeavors with our technology
                    solutions and management consultancy services. Our work with
                    bio-chemical manufacturing companies has helped advance
                    farming by enabling informed decision making to optimize
                    resources effectively. Experience easy integration of
                    technology and agriculture by collaborating with Aeon.
                  </p>
                  <span>
                    <Link to="/agricultureDomain">Learn More</Link>
                    <svg
                      id="_01_align_center"
                      data-name="01 align center"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.441"
                      height="8.349"
                      viewBox="0 0 14.441 8.349"
                    >
                      <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M18.926,9.433l-3.778-3.14L13.9,7.327l2.9,2.409H5V11.2H16.8l-2.9,2.409,1.245,1.034,3.778-3.14A1.3,1.3,0,0,0,18.926,9.433Z"
                        transform="translate(-5 -6.293)"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div className="domain domain-left-conatiner">
              <div className="domain-left1">
                <div className="domain-left1-inner">
                  <h4 className="mb-3">Mining</h4>
                  <p>
                    Upgrade your mining operations with our extensive suite of
                    resource management solutions, designed specifically for the
                    industry's unique needs. These solutions have made the
                    mining processes more efficient, at the same time ensuring
                    optimized vendor management.
                  </p>
                  <span>
                    <svg
                      id="_01_align_center"
                      data-name="01 align center"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.441"
                      height="8.349"
                      viewBox="0 0 14.441 8.349"
                    >
                      <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M5.515,9.433l3.778-3.14,1.245,1.034L7.64,9.736h11.8V11.2H7.64l2.9,2.409L9.294,14.642,5.515,11.5A1.3,1.3,0,0,1,5.515,9.433Z"
                        transform="translate(-5 -6.293)"
                      />
                    </svg>
                    <Link to="/mining">Learn More</Link>
                  </span>
                </div>
              </div>
              <div className="domain-left-img">
                <img src={domain4} alt="domainImg" />
              </div>
            </div>
          </div>

          <div className="domain-second">
            <div className="domain">
              <div className="domain-left">
                <img src={domain5} alt="domainImg" />
              </div>
              <div className="domain-right1">
                <div className="domain-right1-inner">
                  <h4 className="mb-3">Electric Vehicle</h4>
                  <p>
                    Drive your EV business forward with a robust web
                    infrastructure. We help you craft user-friendly interfaces
                    that enhance customer engagement and drive sales. Our suite
                    of tools help you manage all aspects of your business.
                    Partner with Aeon to establish digital presence and
                    accelerate your success.
                  </p>
                  <span>
                    <Link to="/elecricVehicle">Learn More</Link>
                    <svg
                      id="_01_align_center"
                      data-name="01 align center"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.441"
                      height="8.349"
                      viewBox="0 0 14.441 8.349"
                    >
                      <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M18.926,9.433l-3.778-3.14L13.9,7.327l2.9,2.409H5V11.2H16.8l-2.9,2.409,1.245,1.034,3.778-3.14A1.3,1.3,0,0,0,18.926,9.433Z"
                        transform="translate(-5 -6.293)"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div className="domain domain-left-conatiner">
              <div className="domain-left1">
                <div className="domain-left1-inner">
                  <h4 className="mb-3">Food Manufacturing</h4>
                  <p>
                    Aeon offers a variety of tech solutions for the ready-to-eat
                    Food manufacturing industry. From simplifying inventory
                    management and production to optimizing sales distribution
                    and logistics solutions, we enable you to streamline every
                    step of the process, from production line to consumer plate.
                  </p>
                  <span>
                    <svg
                      id="_01_align_center"
                      data-name="01 align center"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.441"
                      height="8.349"
                      viewBox="0 0 14.441 8.349"
                    >
                      <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M5.515,9.433l3.778-3.14,1.245,1.034L7.64,9.736h11.8V11.2H7.64l2.9,2.409L9.294,14.642,5.515,11.5A1.3,1.3,0,0,1,5.515,9.433Z"
                        transform="translate(-5 -6.293)"
                      />
                    </svg>
                    <Link to="/foodManufacturing">Learn More</Link>
                  </span>
                </div>
              </div>
              <div className="domain-left-img">
                <img src={domain6} alt="domainImg" />
              </div>
            </div>
          </div>

          <div className="domain-third">
            <div className="domain">
              <div className="domain-left">
                <img src={domain7} alt="domainImg" />
              </div>
              <div className="domain-right1">
                <div className="domain-right1-inner">
                  <h4 className="mb-3">Banking</h4>
                  <p>
                    Construct a resilient ecosystem for financial and banking
                    services with Aeon. Our comprehensive solutions encompass
                    everything from digital banking platforms to secure payment
                    systems, customized to your user base, enhancing convenience
                    and security for your customers.
                  </p>
                  <span>
                    <Link to="/banking">Learn More</Link>
                    <svg
                      id="_01_align_center"
                      data-name="01 align center"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.441"
                      height="8.349"
                      viewBox="0 0 14.441 8.349"
                    >
                      <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M18.926,9.433l-3.778-3.14L13.9,7.327l2.9,2.409H5V11.2H16.8l-2.9,2.409,1.245,1.034,3.778-3.14A1.3,1.3,0,0,0,18.926,9.433Z"
                        transform="translate(-5 -6.293)"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div className="domain domain-left-conatiner">
              <div className="domain-left1">
                <div className="domain-left1-inner">
                  <h4 className="mb-3">E-Commerce</h4>
                  <p>
                    Create an organized and user-friendly interface for your
                    E-commerce ventures with Aeon. Our smart inventory, vendor,
                    and distribution management solutions help you organize your
                    resources. Join forces with Aeon and raise the productivity
                    of your online marketplace.
                  </p>
                  <span>
                    <svg
                      id="_01_align_center"
                      data-name="01 align center"
                      xmlns="http://www.w3.org/2000/svg"
                      width="14.441"
                      height="8.349"
                      viewBox="0 0 14.441 8.349"
                    >
                      <path
                        id="Path_4"
                        data-name="Path 4"
                        d="M5.515,9.433l3.778-3.14,1.245,1.034L7.64,9.736h11.8V11.2H7.64l2.9,2.409L9.294,14.642,5.515,11.5A1.3,1.3,0,0,1,5.515,9.433Z"
                        transform="translate(-5 -6.293)"
                      />
                    </svg>
                    <Link to="/eCommerce">Learn More</Link>
                  </span>
                </div>
              </div>
              <div className="domain-left-img">
                <img src={domain8} alt="domainImg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="services-container" id="services">
        <h2 className="service-heading">Our Expertise</h2>
        <div className="service-inner-container">
          <div className="services-blog mb-5">
            <div className="service">
              <Link
                to="/idms"
                onMouseEnter={() => handleMouseHover("1")}
                onMouseLeave={handleMouseLeave}
              >
                <img className="polygonImg" src={servicePolygon} alt="img" />
                <div className="test-service">
                  {activeServiceId === "1" ? (
                    <img
                      className="polygonService active"
                      src={service11}
                      alt="service1"
                    />
                  ) : (
                    <img
                      className="polygonService"
                      src={service1}
                      alt="service1"
                    />
                  )}
                </div>
              </Link>
              <span className="service-tile-title">
                <span>Integrated Depot Management</span>
                <span>Solution (IDMS)</span>
              </span>
            </div>
            <div className="service">
              <Link
                to="/cloud"
                onMouseEnter={() => handleMouseHover("2")}
                onMouseLeave={handleMouseLeave}
              >
                <img className="polygonImg" src={servicePolygon} alt="img" />
                <div className="test-service">
                  {activeServiceId === "2" ? (
                    <img
                      className="polygonService small-service-img active"
                      style={{ left: "25px" }}
                      src={service12}
                      alt="service2"
                    />
                  ) : (
                    <img
                      className="polygonService small-service-img"
                      src={service2}
                      alt="service2"
                    />
                  )}
                </div>
              </Link>
              <span className="service-tile-title">
                <span>Cloud - Based ERP</span>
                <span>Solutions</span>
              </span>
            </div>
            <div className="service">
              <Link
                to="/iotIntegration"
                onMouseEnter={() => handleMouseHover("3")}
                onMouseLeave={handleMouseLeave}
              >
                <img className="polygonImg" src={servicePolygon} alt="img" />
                <div className="test-service">
                  {activeServiceId === "3" ? (
                    <img
                      className="polygonService active"
                      src={service13}
                      alt="service2"
                    />
                  ) : (
                    <img
                      className="polygonService"
                      src={service3}
                      alt="service2"
                    />
                  )}
                </div>
              </Link>
              <span>IoT Integration</span>
            </div>
            <div className="service">
              <Link
                to="/mobile-application"
                onMouseEnter={() => handleMouseHover("4")}
                onMouseLeave={handleMouseLeave}
              >
                <img className="polygonImg" src={servicePolygon} alt="img" />
                <div className="test-service">
                  {activeServiceId === "4" ? (
                    <img
                      className="polygonService active small-service-img ps-3"
                      src={service14}
                      alt="service2"
                    />
                  ) : (
                    <img
                      className="polygonService small-service-img ps-2"
                      src={service4}
                      alt="service2"
                    />
                  )}
                </div>
              </Link>
              <span className="service-tile-title">
                <span>Mobile Application</span>
                <span>Development</span>
              </span>
            </div>
            <div className="service">
              <Link
                to="/digitalLearningSolution"
                onMouseEnter={() => handleMouseHover("5")}
                onMouseLeave={handleMouseLeave}
              >
                <img className="polygonImg" src={servicePolygon} alt="img" />
                <div className="test-service">
                  {activeServiceId === "5" ? (
                    <img
                      className="polygonService active"
                      src={service15}
                      alt="service2"
                    />
                  ) : (
                    <img
                      className="polygonService"
                      src={service5}
                      alt="service2"
                    />
                  )}
                </div>
              </Link>
              <span className="service-tile-title">
                <span>Digital Learning</span>
                <span>Solutions</span>
              </span>
            </div>
          </div>
          <div className="services-blog">
            <div className="service">
              <Link
                to="/managementConsultancy"
                onMouseEnter={() => handleMouseHover("6")}
                onMouseLeave={handleMouseLeave}
              >
                <img className="polygonImg" src={servicePolygon} alt="img" />
                <div className="test-service">
                  {activeServiceId === "6" ? (
                    <img
                      className="polygonService active small-service-img"
                      src={service16}
                      alt="service2"
                    />
                  ) : (
                    <img
                      className="polygonService small-service-img"
                      src={service6}
                      alt="service2"
                    />
                  )}
                </div>
              </Link>
              <span className="service-tile-title">
                <span>IT Project Management </span>
                <span>Consultancy</span>
              </span>
            </div>
            <div className="service">
              <Link
                to="/projectDevelopment"
                onMouseEnter={() => handleMouseHover("7")}
                onMouseLeave={handleMouseLeave}
              >
                <img className="polygonImg" src={servicePolygon} alt="img" />
                <div className="test-service">
                  {activeServiceId === "7" ? (
                    <img
                      className="polygonService active"
                      src={service17}
                      alt="service2"
                    />
                  ) : (
                    <img
                      className="polygonService"
                      src={service7}
                      alt="service2"
                    />
                  )}
                </div>
              </Link>
              <span className="service-tile-title">
                <span>Product</span>
                <span>Development</span>
              </span>
            </div>
            <div className="service">
              <Link
                to="/SIS"
                onMouseEnter={() => handleMouseHover("8")}
                onMouseLeave={handleMouseLeave}
              >
                <img className="polygonImg" src={servicePolygon} alt="img" />
                <div className="test-service">
                  {activeServiceId === "8" ? (
                    <img
                      className="polygonService active"
                      src={service18}
                      alt="service2"
                    />
                  ) : (
                    <img
                      className="polygonService"
                      src={service8}
                      alt="service2"
                    />
                  )}
                </div>
              </Link>
              <span className="service-tile-title">
                <span>System Integration</span>
                <span> Services (SIS)</span>
              </span>
            </div>
            <div className="service">
              <Link
                to="/uiUxDesign"
                onMouseEnter={() => handleMouseHover("9")}
                onMouseLeave={handleMouseLeave}
              >
                <img className="polygonImg" src={servicePolygon} alt="img" />
                <div className="test-service">
                  {activeServiceId === "9" ? (
                    <img
                      className="polygonService active"
                      src={service19}
                      alt="service2"
                    />
                  ) : (
                    <img
                      className="polygonService"
                      src={service9}
                      alt="service2"
                    />
                  )}
                </div>
              </Link>
              <span className="service-tile-title">
                <span>UI/UX Design</span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="products-container" id="products">
        <h2 className="product-heading">Our Products</h2>
        <div className="products-inner-container">
          <div className="product-box-container first-box-container">
            <div
              className="product-box"
              onMouseEnter={() => handleMouseHover("p1")}
              onMouseLeave={handleMouseLeave}
            >
              <div className="product-box-inner">
                {activeProductId === "p1" ? (
                  <img
                    className="product-gold-img active"
                    src={mobileTktGold}
                    alt="img"
                  />
                ) : (
                  <img src={mobileTktBlue} alt="img" />
                )}

                <span>Mobile Ticketing Solution for Public Transport</span>
                <div>
                  Simplify your travel with AEON's Mobile Ticketing Solution.
                  Conductors easily sell tickets, passengers enjoy stress-free
                  journeys, and administrators manage operations centrally.
                  Embrace the future of transportation with AEON.
                </div>
              </div>
              <Link to="/mobileTicketingSolution">Explore</Link>
            </div>
            <div
              className="product-box"
              onMouseEnter={() => handleMouseHover("p2")}
              onMouseLeave={handleMouseLeave}
            >
              <div className="product-box-inner">
                {activeProductId === "p2" ? (
                  <img
                    className="product-gold-img active"
                    src={lmsGold}
                    alt="img"
                  />
                ) : (
                  <img src={lmsBlue} alt="img" />
                )}

                <span>Learning Management System</span>
                <div>
                  Enhance your organization's learning initiatives with Aeon
                  LMS. Featuring personalized branding, efficient
                  administration, and interactive blended learning, our
                  comprehensive solution meets all your training needs. Let’s
                  build a brighter, more knowledgeable future together.
                </div>
              </div>
              <Link to="/lms">Explore</Link>
            </div>
          </div>
          <div className="product-box-container">
            <div
              className="product-box"
              onMouseEnter={() => handleMouseHover("p3")}
              onMouseLeave={handleMouseLeave}
            >
              <div className="product-box-inner">
                {activeProductId === "p3" ? (
                  <img
                    className="product-gold-img active"
                    src={tmsGold}
                    alt="img"
                  />
                ) : (
                  <img src={tmsBlue} alt="img" />
                )}

                <span>Training Management System</span>
                <div>
                  Managing training schedules, asset tracking, and compliance
                  can be challenging. Our Training Management Software (TMS)
                  brings order to the chaos. Simplify your processes with our
                  practical TMS solution.
                </div>
              </div>
              <Link to="/tms">Explore</Link>
            </div>

            <div
              className="product-box"
              onMouseEnter={() => handleMouseHover("p4")}
              onMouseLeave={handleMouseLeave}
            >
              <div className="product-box-inner">
                {activeProductId === "p4" ? (
                  <img
                    className="product-gold-img active"
                    src={depotGold}
                    alt="img"
                  />
                ) : (
                  <img src={depotBlue} alt="img" />
                )}

                <span>Depot Management</span>
                <div>
                  Is your city's transit system due for an upgrade? AEON's
                  Public Transport Solution enhances operations and passenger
                  satisfaction with optimized fleet management and seamless
                  experiences. Let's modernize urban mobility for a better
                  tomorrow.
                </div>
              </div>
              <Link to="/depoManagement">Explore</Link>
            </div>
          </div>
        </div>
      </div>
      <div className="clients-container" id="clients">
        <h2 className="client-heading">Our Clients</h2>
        <div className="client-inner-container">
          <div className="client-box">
            <div className="client-carousel-container">
              <div class="logos">
                <div class="logos-slide">
                  {groupedClientImages.map((group, index) =>
                    group.map(
                      (image, subIndex) =>
                        image && (
                          <img
                            src={process.env.PUBLIC_URL + image}
                            alt={`Client ${index * 10 + subIndex + 1}`}
                            className="img-fluid"
                          />
                        )
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="presence-container" id="presence">
        <h2 className="presence-heading">OUR PRESENCE</h2>
        <div className="presence-inner-container">
          <div className="presence-navbar-container">
            <div className="presence-navbar">
              {locations.map((tag) => (
                <div
                  key={tag}
                  className={`nav-item ${activeTag === tag ? "active" : ""}`}
                  onClick={() => handleTagClick(tag)}
                >
                  {tag}
                </div>
              ))}
            </div>
            <div className="horizontal-line"></div>
          </div>
          <div
            className={
              activeTag === "India"
                ? "presence-map-section presence-map-section-india"
                : "presence-map-section"
            }
          >
            {activeTag === "India" ? (
              <div className="presence-map-left">
                <div className="presence-navbar presence-navbar-loc">
                  {addressLoc.map((loc) => (
                    <div
                      key={loc}
                      className={`nav-item ${
                        activeLoc === loc ? "active" : ""
                      }`}
                      onClick={() => handleLocClick(loc)}
                    >
                      {loc}
                    </div>
                  ))}
                </div>
                {activeLoc === "Pune" ? (
                  <div className="presence-address-container mt-5">
                    <div className="presence-address-heading mb-2">
                      <span>Aeon Software Pvt. Ltd.</span>
                    </div>
                    <div className="presence-address">
                      Unit No.507, Lunkad Sky Vista, Lohegaon, Viman Nagar Pune-
                      411014
                    </div>
                  </div>
                ) : activeLoc === "Mumbai" ? (
                  <>
                    <div className="presence-address-container mt-5">
                      <div className="presence-address-heading mb-2">
                        <span>Aeon Software Pvt. Ltd.</span>
                        <span> (Head Office)</span>
                      </div>
                      <div className="presence-address">
                        A-203, Everest Garden, Shanti Nagar, MIDC, Mahakali
                        Caves Road, Andheri (E), Mumbai- 400093
                      </div>
                      <div className="presence-address">
                        +91-2265749443 / +91-2240045378
                      </div>
                    </div>
                    {/* <div className="presence-address-container mt-5">
                      <div className="presence-address-heading mb-2">
                        <span>Aeon Software Pvt. Ltd. </span>
                        <span>(Thane Office)</span>
                      </div>
                      <div className="presence-address">
                        507, MAHANT CHAMBERS, Plot A 315, Rd Number 34, Wagle
                        Estate, Jai Bhavani Nagar, MIDC Industrial Area, Thane
                        West, Thane, Maharashtra 400604
                      </div>
                    </div> */}
                  </>
                ) : activeLoc === "Gujarat" ? (
                  <div className="presence-address-container mt-5">
                    <div className="presence-address-heading mb-2">
                      <span>Aeon Software Pvt. Ltd.</span>
                    </div>
                    <div className="presence-address">
                      A-506, Titanium Square, Thltej Cross Road, SG Highway,
                      Ahmedabad, Gujarat, 380059.
                    </div>
                  </div>
                ) : (
                  <div className="presence-address-container mt-5">
                    <div className="presence-address-heading mb-2">
                      <span>Aeon Software Pvt. Ltd.</span>
                    </div>
                    <div className="presence-address">
                      A 314- 315, Floor 3, Apollo Gold Plaza, Near DB city,
                      Nipania, Indore.
                    </div>
                  </div>
                )}
              </div>
            ) : activeTag === "USA" ? (
              <div className="presence-map-left">
                <div className="presence-address-container">
                  <div className="presence-address-heading mb-2">
                    <span>Aeon Software INC.</span>
                  </div>
                  <div className="presence-address">
                    Fair Lawn, 31, Koenig, New Jersey, 07410
                  </div>
                </div>
              </div>
            ) : activeTag === "Canada" ? (
              <div className="presence-map-left">
                <div className="presence-address-container">
                  <div className="presence-address-heading mb-2">
                    <span>Aeon Software & Consulting INC.</span>
                  </div>
                  <div className="presence-address">
                    915-7805 Bayview Ave, Thornhill, Ontario, Canada L3T 7P7
                  </div>
                </div>
              </div>
            ) : (
              <div className="presence-map-left">
                <div className="presence-address-container">
                  <div className="presence-address-heading mb-2">
                    <span>Aeon Software ME WLL</span>
                  </div>
                  <div className="presence-address">
                    Office Number #242, 2nd Floor, Batelco building, Builg#114,
                    Road#385, Manama Center, Block#304, Manama, Kingdom of
                    Bahrain
                  </div>
                </div>
              </div>
            )}

            <div className="presence-map-right">
              <img src={mapImg} alt="worldMap" />
            </div>
          </div>
        </div>
      </div>
      <div className="contactUs-container" id="contactUs">
        <ToastContainer />

        <div className="contactUs-inner-container">
          <div className="contactUs-left">
            <div>
              <div className="contactUs-sub-heading">
                Your Vision, Our Commitment.
              </div>

              <div className="contactUs-sub-heading">
                Discover What We Can Achieve Together!
              </div>
            </div>
            <div>
              <img width="100%" src={contactUsImg} alt="img" />
            </div>
          </div>
          <div className="contactUs-right">
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-lg-6 mb-5">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Name<span className="red-star">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      // placeholder="Enter your name"
                      required
                      name="name"
                      value={userDetails.name}
                      onChange={inputHandler}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6 mb-5">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>
                      Email Id<span className="red-star">*</span>
                    </Form.Label>
                    <Form.Control
                      type="email"
                      // placeholder="Type here..."
                      required
                      name="email"
                      value={userDetails.email}
                      onChange={inputHandler}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6 mb-5">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Mobile No.</Form.Label>
                    <Form.Control
                      type="text"
                      // placeholder="Type here..."
                      // required
                      name="mobileNum"
                      value={userDetails.mobileNum}
                      onChange={inputHandler}
                    />
                  </Form.Group>
                </div>
                <div className="col-lg-6 mb-5">
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Job Title</Form.Label>
                    <Form.Control
                      type="text"
                      // placeholder="Type here..."
                      // required
                      name="jobTitle"
                      value={userDetails.jobTitle}
                      onChange={inputHandler}
                    />
                  </Form.Group>
                </div>

                <div className="col-lg-12 mb-4">
                  <Form.Group className="" controlId="formBasicEmail">
                    <Form.Label>
                      Message<span className="red-star">*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      maxLength="200"
                      rows={3}
                      placeholder="Type here..."
                      required
                      name="message"
                      value={userDetails.message}
                      onChange={inputHandler}
                    />
                  </Form.Group>
                  <Form.Text id="char-left" muted>
                    Characters left: {200 - userDetails.message.length}
                  </Form.Text>
                </div>
              </div>
              <button type="submit" className="contactUs-link mt-3">
                Get in touch
              </button>
            </Form>
            <Modal show={show} onHide={handleClose} centered>
              <Modal.Body
                className="d-flex justify-content-center align-items-center"
                style={{ height: "400px", width: "500px" }}
              >
                {loading ? (
                  <div className="spinner-container">
                    <RotatingLines
                      visible={true}
                      height="80"
                      width="80"
                      color="#37466F"
                      strokeColor="#37466F"
                      strokeWidth="5"
                      animationDuration="0.75"
                      ariaLabel="rotating-lines-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                ) : (
                  <img width="90%" src={thankYouImg} alt="img" />
                )}
              </Modal.Body>
            </Modal>
          </div>
        </div>
      </div>
      <footer>
        <div className="company-footer">
          <div>@ 2024 Aeon Software | All Rights Reserved</div>
          <div>admin@aeonsoftware.net</div>
          <div>020-48467592</div>
        </div>
      </footer>
    </div>
  );
}

export default Homescreen;
