import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import logo from "../Assests/aeonLogo.png";
import logo2 from "../Assests/aeonLogo2.png";
import logo3 from "../Assests/aeonLogo21.png";
import logoAnimation from "../Assests/logoAnimation.gif";
import "./style.css";
import { useLocation, useNavigate } from "react-router-dom";
import indiaFlag from "../Assests/indianFlag.png";
import canadaFlag from "../Assests/canadaFlag.png";
import bahrainFlag from "../Assests/bahrainFlag.png";
import usaFlag from "../Assests/usaFlag.png";
import downArrow from "../Assests/downArrow.png";

function Navbarr() {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedFlag, setSelectedFlag] = useState(indiaFlag);
  const [openFlags, setOpenFlags] = useState(false);
  const [navbar1, setNavbar1] = useState(false);

  const flagsDetails = [
    {
      id: 1,
      img: indiaFlag,
      title: "India",
      link: "https://aeonsoftware.net/",
    },
    { id: 2, img: canadaFlag, title: "Canada", link: "/" },
    {
      id: 3,
      img: usaFlag,
      title: "USA",
      link: "https://www.aeonsoftwareinc.com/",
    },
    { id: 4, img: bahrainFlag, title: "Bahrain", link: "/" },
  ];

  const handleFlagToggle = () => {
    setOpenFlags(!openFlags);
  };

  const handleFlagChange = (data) => {
    setSelectedFlag(data.img);
    setOpenFlags(false);
    // navigate("https://www.aeonsoftwareinc.com/");
  };

  const handleClickOutside = (event) => {
    if (
      openFlags &&
      wrapperRef.current &&
      !wrapperRef.current.contains(event.target)
    ) {
      setOpenFlags(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openFlags]);

  const wrapperRef = useRef(null);

  const changeBackground = () => {
    if (window.scrollY >= 80) {
      setNavbar1(true);
    } else {
      setNavbar1(false);
    }
  };

  window.addEventListener("scroll", changeBackground);

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className={navbar1 ? "navbar visible active" : "navbar visible"}
    >
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav>
          <Nav.Link href="/">
            <div className="d-flex align-items-center">
              <img
                className="aeonLogo"
                src={navbar1 ? logo2 : logoAnimation}
                alt="logo"
              />
            </div>
          </Nav.Link>
        </Nav>

        <Nav className="ms-auto nav-links ">
          <Nav.Link className="nav-link" href="#home">
            Home
          </Nav.Link>
          <Nav.Link className="nav-link" href="#aboutUs">
            About Us
          </Nav.Link>
          <Nav.Link className="nav-link" href="#domains">
            Domains
          </Nav.Link>
          <Nav.Link className="nav-link" href="#services">
            Services
          </Nav.Link>
          <Nav.Link className="nav-link" href="#products">
            Products
          </Nav.Link>
          <Nav.Link className="nav-link" href="/careers">
            Careers
          </Nav.Link>
          <Nav.Link className="nav-link" href="#contactUs">
            Lets Talk!
          </Nav.Link>
          <Nav className="right-nav-abc">
            <div className="flags-container" onClick={handleFlagToggle}>
              <img className="flag-icon" src={selectedFlag} alt="flags" />
              <img className="down-icon" src={downArrow} alt="img" />
            </div>
          </Nav>
          {openFlags ? (
            <div className="flags-dropdown" ref={wrapperRef}>
              {Array.isArray(flagsDetails)
                ? flagsDetails.map((data, index) => {
                    const isDisabled = ["Canada", "Bahrain"].includes(
                      data.title
                    );
                    return (
                      <a
                        key={index}
                        className="flag-icon-option"
                        // className={`flag-icon-option ${
                        //   selectedFlag === data.img ? "selectd-flag" : ""
                        // } ${isDisabled ? "disabled" : ""}`}
                        // onClick={() => {
                        //   if (!isDisabled) {
                        //     handleFlagChange(data);
                        //   }
                        // }}
                        href={data.link}
                        // target="_blank"
                      >
                        <img className="flag-icon" src={data.img} alt="img" />
                        <span>{data.title}</span>
                      </a>
                    );
                  })
                : null}
            </div>
          ) : (
            <></>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
}

export default Navbarr;
