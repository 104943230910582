import React, { useEffect } from "react";
import mainImg from "../../Assests/Services/serviceBanner.png";
import Banner from "../Banner";
import "./service.css";
import { Link } from "react-router-dom";
import arrowImg from "../../Assests/arrowImg.png";
import info1 from "../../Assests/Services/cloudInfo1.png";
import info2 from "../../Assests/Services/cloudInfo2.png";
import info3 from "../../Assests/Services/cloudInfo3.png";
import info4 from "../../Assests/Services/cloudInfo4.png";
import info5 from "../../Assests/Services/cloudInfo5.png";
import Aos from "aos";
import "aos/dist/aos.css";

function CloudErp() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div>
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Services" />
        <div className="below-banner-mad-container">
          <h1 className="product-banner-heading">Cloud-Based ERP Solutions</h1>
          <div className="service-para mb-4">
            ERP, or Enterprise Resource Planning, is a powerful software tool
            that integrates data from across an organization to streamline
            operations and enhance collaboration between departments.
          </div>
          <div className="service-para">
            Aeon's SaaS-based ERP system is a comprehensive solution that offers
            tailored tools to simplify and optimize a wide range of business
            operations. Our ERP solutions cater to various industries and
            include specialized modules for different business functions.
          </div>
          <div className="cloud-sub-heading text-start mb-2">
            Challenges Addressed by ERP Systems
          </div>
          <div className="service-para">
            Modern businesses encounter several challenges that ERP systems
            effectively address:
          </div>
          <div className="cloud-info-container">
            <div className="cloud-info-inner-container">
              <div className="cloud-info">
                <div className="cloud-info-text">Operational Complexity</div>
                <div>
                  Efficiently manage diverse business processes and resources
                </div>
              </div>
              <img src={info1} alt="Operational Complexity" />
            </div>
            <div
              className="cloud-info-inner-container"
              style={{ marginTop: "30px" }}
            >
              <img src={info2} alt="Data Silos" />
              <div className="cloud-info1">
                <div className="cloud-info-text"> Data Silos</div>
                <div>
                  Consolidate fragmented data to improve decision-making.
                </div>
              </div>
            </div>
            <div className="cloud-info-inner-container">
              <div className="cloud-info">
                <div className="cloud-info-text">Resource Optimization</div>
                <div>
                  Maximize workforce, material, and financial resources.
                </div>
              </div>
              <img src={info3} alt="Resource Optimization" />
            </div>
            <div
              className="cloud-info-inner-container"
              style={{ marginTop: "30px" }}
            >
              <img src={info4} alt="Regulatory Compliance" />
              <div className="cloud-info1">
                <div className="cloud-info-text">Regulatory Compliance</div>
                <div>
                  Ensure adherence to industry standards and regulations.
                </div>
              </div>
            </div>
            <div className="cloud-info-inner-container">
              <div className="cloud-info">
                <div className="cloud-info-text">Scalability</div>
                <div>Scale operations seamlessly to meet growing demands.</div>
              </div>
              <img src={info5} alt="Scalability" />
            </div>
          </div>
        </div>
        <div className="mad-service-second-container">
          <div
            className="cloud-sub-heading cloud-sub-head2 text-start mb-5"
            data-aos="fade-up"
            data-aos-duration="900"
          >
            Benefits of Aeon’s SaaS ERP Solutions
          </div>
          <div className="cloud-points-container">
            <div className="why-sis-container">
              <div className="text-start pmc-approach-points-container">
                <div
                  className="pmc-approach-points-heading mb-4"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  Comprehensive Management Systems
                </div>
                <div
                  className="pmc-approach-points d-flex align-items-start"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div className="arrow-point">
                    <img src={arrowImg} alt="arrowImg" />
                  </div>
                  <span className="service-para">
                    <span className="cloud-point-txt">
                      {" "}
                      Financial Management:
                    </span>{" "}
                    Streamline budgeting, accounting, and financial reporting
                    processes.
                  </span>
                </div>
                <div
                  className="pmc-approach-points d-flex align-items-start mt-3"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div className="arrow-point">
                    <img src={arrowImg} alt="arrowImg" />
                  </div>
                  <span className="service-para">
                    <span className="cloud-point-txt">
                      Inventory Management:{" "}
                    </span>
                    Optimize inventory levels to prevent shortages and reduce
                    carrying costs.
                  </span>
                </div>
                <div
                  className="pmc-approach-points d-flex align-items-start mt-3"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div className="arrow-point">
                    <img src={arrowImg} alt="arrowImg" />
                  </div>
                  <span className="service-para">
                    <span className="cloud-point-txt">
                      Workforce Management:{" "}
                    </span>
                    Automate payroll, attendance tracking, and workforce
                    scheduling.
                  </span>
                </div>
                <div
                  className="pmc-approach-points d-flex align-items-start mt-3"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div className="arrow-point">
                    <img src={arrowImg} alt="arrowImg" />
                  </div>
                  <span className="service-para">
                    <span className="cloud-point-txt"> Asset Management: </span>
                    Track and maintain equipment to extend lifecycle.
                  </span>
                </div>
              </div>
              <div className="text-start pmc-approach-points-container mt-5">
                <div
                  className="pmc-approach-points-heading mb-4"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  Operational Efficiency
                </div>
                <div
                  className="pmc-approach-points d-flex align-items-start"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div className="arrow-point">
                    <img src={arrowImg} alt="arrowImg" />
                  </div>
                  <span className="service-para">
                    <span className="cloud-point-txt">
                      {" "}
                      Process Automation:
                    </span>{" "}
                    Automate routine tasks and workflows to improve efficiency
                    and reduce errors.
                  </span>
                </div>
                <div
                  className="pmc-approach-points d-flex align-items-start mt-3"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div className="arrow-point">
                    <img src={arrowImg} alt="arrowImg" />
                  </div>
                  <span className="service-para">
                    <span className="cloud-point-txt">Data Integration: </span>
                    Integrate data from various sources for real-time insights
                    and informed decision-making.
                  </span>
                </div>
                <div
                  className="pmc-approach-points d-flex align-items-start mt-3"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div className="arrow-point">
                    <img src={arrowImg} alt="arrowImg" />
                  </div>
                  <span className="service-para">
                    <span className="cloud-point-txt">Scalability: </span>
                    Easily scale operations to accommodate growth and changing
                    business needs.
                  </span>
                </div>
              </div>
              <div className="text-start pmc-approach-points-container mt-5">
                <div
                  className="pmc-approach-points-heading mb-4"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  Customer and Supplier Relationship Management
                </div>
                <div
                  className="pmc-approach-points d-flex align-items-start"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div className="arrow-point">
                    <img src={arrowImg} alt="arrowImg" />
                  </div>
                  <span className="service-para">
                    <span className="cloud-point-txt"> CRM:</span> Manage
                    customer interactions and improve customer satisfaction
                    through efficient service delivery.
                  </span>
                </div>
                <div
                  className="pmc-approach-points d-flex align-items-start mt-3"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div className="arrow-point">
                    <img src={arrowImg} alt="arrowImg" />
                  </div>
                  <span className="service-para">
                    <span className="cloud-point-txt">
                      Supplier Management:{" "}
                    </span>
                    Streamline procurement processes and enhance supplier
                    relationships.
                  </span>
                </div>
              </div>
              <div className="text-start pmc-approach-points-container mt-5">
                <div
                  className="pmc-approach-points-heading mb-4"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  Data Analytics and Reporting
                </div>
                <div
                  className="pmc-approach-points d-flex align-items-start"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div className="arrow-point">
                    <img src={arrowImg} alt="arrowImg" />
                  </div>
                  <span className="service-para">
                    <span className="cloud-point-txt">
                      {" "}
                      Business Intelligence:
                    </span>{" "}
                    Utilize data analytics to gain actionable insights for
                    strategic planning and operational improvements.
                  </span>
                </div>
                <div
                  className="pmc-approach-points d-flex align-items-start mt-3"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div className="arrow-point">
                    <img src={arrowImg} alt="arrowImg" />
                  </div>
                  <span className="service-para">
                    <span className="cloud-point-txt">
                      Customizable Reports:{" "}
                    </span>
                    Generate tailored reports to monitor performance indicators.
                  </span>
                </div>
              </div>
              <div className="text-start pmc-approach-points-container mt-5">
                <div
                  className="pmc-approach-points-heading mb-4"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  Compliance and Risk Management
                </div>
                <div
                  className="pmc-approach-points d-flex align-items-start"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div className="arrow-point">
                    <img src={arrowImg} alt="arrowImg" />
                  </div>
                  <span className="service-para">
                    <span className="cloud-point-txt">
                      Regulatory Compliance:{" "}
                    </span>
                    Ensure compliance with industry regulations and standards.
                  </span>
                </div>
                <div
                  className="pmc-approach-points d-flex align-items-start mt-3"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div className="arrow-point">
                    <img src={arrowImg} alt="arrowImg" />
                  </div>
                  <span className="service-para">
                    <span className="cloud-point-txt">Risk Assessment: </span>
                    Identify and mitigate risks through structured processes.
                  </span>
                </div>
              </div>
              <div className="text-start pmc-approach-points-container mt-5">
                <div
                  className="pmc-approach-points-heading mb-4"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  Cloud-Based Accessibility
                </div>
                <div
                  className="pmc-approach-points d-flex align-items-start"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div className="arrow-point">
                    <img src={arrowImg} alt="arrowImg" />
                  </div>
                  <span className="service-para">
                    <span className="cloud-point-txt">Remote Access: </span>
                    Access and manage business data securely from anywhere,
                    facilitating remote work and collaboration.
                  </span>
                </div>
                <div
                  className="pmc-approach-points d-flex align-items-start mt-3"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div className="arrow-point">
                    <img src={arrowImg} alt="arrowImg" />
                  </div>
                  <span className="service-para">
                    <span className="cloud-point-txt">Scalability: </span>
                    Cloud-based infrastructure allows for easy scalability
                    without the need for extensive IT resources.
                  </span>
                </div>
              </div>
              <div className="text-start pmc-approach-points-container mt-5">
                <div
                  className="pmc-approach-points-heading mb-4"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  Enhanced Decision-Making
                </div>
                <div
                  className="pmc-approach-points d-flex align-items-start"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div className="arrow-point">
                    <img src={arrowImg} alt="arrowImg" />
                  </div>
                  <span className="service-para">
                    <span className="cloud-point-txt">
                      Real-Time Insights:{" "}
                    </span>
                    Access real-time data and analytics for informed
                    decision-making.
                  </span>
                </div>
                <div
                  className="pmc-approach-points d-flex align-items-start mt-3"
                  data-aos="fade-up"
                  data-aos-duration="900"
                >
                  <div className="arrow-point">
                    <img src={arrowImg} alt="arrowImg" />
                  </div>
                  <span className="service-para">
                    <span className="cloud-point-txt">
                      Predictive Analytics:{" "}
                    </span>
                    Utilize predictive modeling to anticipate future needs.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="below-banner-mad-container">
          <div className="cloud-sub-heading cloud-sub-head2 text-start mb-5">
            Aeon's Track Record of Success Across Diverse Industries
          </div>
          <div className="service-para">
            Aeon's track record of success spans across various industries and
            sectors. Our integrated ERP solutions have been successfully
            deployed and trusted by public transport agencies such as
          </div>
          <div className="cloud-client-name-list">
            BEST (Mumbai), KDMT (Kalyan), NMMT (Navi Mumbai), TMT (Thane), PMPML
            (Pune), Sikkim Nationalised Transport (SNT), AMTS (Ahmedabad), KMT
            (Kolhapur Municipal Transport), Kadamba Transport (Goa), and Port
            Blair Public Transport (Port Blair Smart City).
          </div>
          <div className="service-para">
            Our tailored solutions also serve industries such as aviation
            training at Gulf Aviation Academy, food manufacturing with Capital
            Foods, and transport logistics in mining industries. Our track
            record is bolstered by accolades and a broad clientele base,
            including federal agencies like the Food and Drug Authority.
          </div>

          <div className="contact-us-end-container mt-5">
            <span className="service-para">
              Contact Aeon today to explore how our ERP solutions can transform
              your operations. Let’s embark on a journey of innovation,
              efficiency, and growth together.
            </span>
            <span className="contact-us-end-text">
              Experience the Aeon advantage and redefine what’s possible for
              your business!
            </span>

            <Link className="contactUs-link" to="/contact-us">
              Contact Us!
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CloudErp;
