import React from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useLocation, useNavigate } from "react-router-dom";
// import aeonLogo from "../Assests/aeonLogo2.png";
import aeonLogo from "../Assests/aeonLogo.png";
import { useState, useEffect } from "react";

function Banner({ mainImg, title }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.clear();
    navigate("/login");
    window.location.reload();
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && location.pathname.includes("/login")) {
      setIsLoggedIn(true);
    }
  }, [isLoggedIn]);

  return (
    <div className="aboutUs-main-img">
      <img src={mainImg} alt="aboutUs" />
      <div className="opaque-screen banner-opaque">
        {location.pathname.includes("/allRequirements") ||
        location.pathname.includes("/humanResourceJobPosting") ||
        isLoggedIn ? (
          <div className="logout-btn" onClick={handleLogout}>
            Logout
          </div>
        ) : (
          <></>
        )}
        <div className="second-banner-logo">
          <a href="/">
            <img src={aeonLogo} alt="aeonLogo" />
          </a>
        </div>
        <h2 className="banner-text">{title}</h2>
        <div>
          {location.pathname.includes("/allRequirements") ||
          location.pathname.includes("/humanResourceJobPosting") ||
          isLoggedIn ? (
            <Breadcrumb>
              <Breadcrumb.Item href="/humanResourceJobPosting">
                Job Posting
              </Breadcrumb.Item>
              <Breadcrumb.Item href="/allRequirements">
                All Requirements
              </Breadcrumb.Item>
            </Breadcrumb>
          ) : (
            <Breadcrumb>
              <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
              <Breadcrumb.Item active>{title}</Breadcrumb.Item>
            </Breadcrumb>
          )}
        </div>
      </div>
    </div>
  );
}

export default Banner;
