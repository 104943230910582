import React, { useEffect, useState } from "react";
import mainImg from "../../Assests/Services/serviceBanner.png";
import Banner from "../Banner";
import "./service.css";
import { Link } from "react-router-dom";

function IoT() {
  const [selectedTab, setSelectedTab] = useState("0");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <div>
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Services" />
        <div className="below-banner-mad-container">
          <h1 className="product-banner-heading">IoT Integration</h1>
          <div className="cloud-sub-heading text-start mb-4">
            Revolutionizing Utilities with IoT: Aeon’s Comprehensive Solutions
          </div>
          <div className="service-para">
            In an increasingly connected world, Aeon Software stands at the
            forefront of technological innovation, offering a suite of IoT
            (Internet of Things) services designed to revolutionise how we
            manage and optimise resources. Among its flagship solutions is
            DropByDrop, a groundbreaking smart water management system. However,
            Aeon Software's IoT expertise extends beyond water management,
            encompassing various applications tailored to enhance efficiency,
            sustainability, and operational excellence across various
            industries.
          </div>
          <div className="cloud-sub-heading text-start mb-4">
            The Power of IoT
          </div>
          <div className="service-para">
            The Internet of Things (IoT) refers to a network of interconnected
            devices that communicate and share data to perform automated tasks
            and provide valuable insights. Aeon Software leverages IoT to create
            intelligent ecosystems where devices and systems work harmoniously
            to optimise performance, reduce waste, and improve decision-making.
            From smart cities to industrial automation, our IoT services
            transform traditional operations into dynamic, data-driven
            processes.
          </div>
        </div>
        <div className="mad-service-second-container iot-service-second-container">
          <div className="row gx-5">
            <div className="iot-tabs-container col-3  text-center">
              <div
                className="iot-tab iot-tab1"
                onClick={() => handleTabClick("0")}
              >
                <div> Comprehensive Hardware</div>
                <div> Integration</div>
              </div>
              <div
                className="iot-tab iot-tab2"
                onClick={() => handleTabClick("1")}
              >
                <div> DropByDrop:</div>
                <div> Smart Water Management</div>
              </div>
              <div
                className="iot-tab iot-tab3"
                onClick={() => handleTabClick("2")}
              >
                <div> IoT Solutions</div>
                <div>for Smart Cities</div>
              </div>
              <div
                className="iot-tab iot-tab4"
                onClick={() => handleTabClick("3")}
              >
                Industrial IoT (IIoT)
              </div>
              <div
                className="iot-tab iot-tab5"
                onClick={() => handleTabClick("4")}
              >
                Healthcare IoT
              </div>
              <div
                className="iot-tab iot-tab6"
                onClick={() => handleTabClick("5")}
              >
                Agricultural IoT
              </div>
              <div
                className="iot-tab iot-tab7"
                onClick={() => handleTabClick("6")}
              >
                Energy and Utilities
              </div>
            </div>
            <div className="col-9">
              {selectedTab === "0" ? (
                <div>
                  <div className="cloud-sub-heading cloud-sub-head2 text-start mb-3">
                    Comprehensive Hardware Integration
                  </div>
                  <div className="service-para">
                    Aeon Software's IoT solutions begin with comprehensive
                    hardware integration. Our portfolio includes advanced
                    sensors, meters, data loggers, and communication devices,
                    each meticulously designed to provide accurate and reliable
                    data. These components form the backbone of our IoT
                    infrastructure, ensuring seamless data collection and
                    transmission.
                  </div>
                  <div className="row px-5 iot-info-first mt-5">
                    <div className="col col-lg-3 ">
                      <div className="iot-info-content">
                        <div className="iot-info-title iot-info1-title">
                          <div>Advanced </div>
                          <div>Sensors</div>
                        </div>
                        <div className="iot-info-descrip">
                          Monitor Parameters such as temperature, humidity,
                          pressure and air quality in real-time.
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-3 ">
                      <div className="iot-info-content">
                        <div className="iot-info-title iot-info2-title">
                          <div>Smart</div>
                          <div>Meters</div>
                        </div>
                        <div className="iot-info-descrip">
                          Provide precise measurements for utilities like water,
                          gas, and electricity, enabling accurate billing and
                          usage tracking.
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-3">
                      <div className="iot-info-content">
                        <div className="iot-info-title iot-info3-title">
                          <div>Data</div>
                          <div>Loggers</div>
                        </div>
                        <div className="iot-info-descrip">
                          Store and transmit data from various sensors, ensuring
                          comprehensive monitoring and analysis.
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-3">
                      <div className="iot-info-content">
                        <div className="iot-info-title iot-info4-title">
                          <div>Communication</div>
                          <div>Devices</div>
                        </div>
                        <div className="iot-info-descrip">
                          Utilize protocols such as LoRa, BLE, ZigBee and Z-wave
                          to facilitate seamless data exchange and connectivity.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : selectedTab === "1" ? (
                <div>
                  <div className="cloud-sub-heading cloud-sub-head2 text-start mb-3">
                    DropByDrop: Smart Water Management
                  </div>
                  <div className="service-para">
                    As one of Aeon Software's flagship IoT services, DropByDrop
                    is a revolutionary system designed to transform water
                    management. At its core, DropByDrop integrates advanced
                    hardware and software solutions to provide unparalleled
                    control and insight into water usage. Key features include
                  </div>
                  <div className="iot-info-first iot-info-second mt-4">
                    <div className="iot-second-infoo">
                      <div className="iot-info2-content iot-info2-content1">
                        <div className="iot-info-title2">
                          <div>
                            <div>State-of-the-Art</div>
                            <div>Water Meters</div>
                          </div>
                        </div>
                        <div className="iot-info2-descrip">
                          Ensure precise measurements for accurate billing and
                          tracking.
                        </div>
                      </div>
                    </div>
                    <div className="iot-second-infoo">
                      <div className="iot-info2-content iot-info2-content2">
                        <div className="iot-info-title2">
                          <div>
                            <div>Quality and </div>
                            <div>pH Sensors</div>
                          </div>
                        </div>
                        <div className="iot-info2-descrip">
                          Monitor water quality in real-time, ensuring safety
                          and compliance.
                        </div>
                      </div>
                    </div>
                    <div className="iot-second-infoo">
                      <div className="iot-info2-content iot-info2-content3">
                        <div className="iot-info-title2">
                          <div>
                            <div>Data </div>
                            <div>Acquisition</div>
                          </div>
                        </div>
                        <div className="iot-info2-descrip">
                          Methods include manual input, image capture, OCR
                          (Optical Character Recognition), and mobile
                          applications.
                        </div>
                      </div>
                    </div>
                    <div className="iot-second-infoo">
                      <div className="iot-info2-content iot-info2-content4">
                        <div className="iot-info-title2">
                          <div>
                            <div>Interoperable</div>
                            <div>Protocols</div>
                          </div>
                        </div>
                        <div className="iot-info2-descrip">
                          Support for Pulse, Modbus, LoRa, BLE, Bluetooth,
                          ZigBee, and Z-wave.
                        </div>
                      </div>
                    </div>
                    <div className="iot-second-infoo">
                      <div className="iot-info2-content iot-info2-content5">
                        <div className="iot-info-title2">
                          <div>
                            <div>Powerful</div>
                            <div>Software Solutions</div>
                          </div>
                        </div>
                        <div className="iot-info2-descrip">
                          Include the DropByDrop platform, comprehensive APIs,
                          Consumer App, and Waterman App for real-time
                          monitoring and control.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="service-para mt-3">
                    By leveraging IoT, DropByDrop enables real-time monitoring,
                    predictive maintenance, and automated control, helping users
                    prevent wastage, detect leaks, and optimise water
                    distribution.
                  </div>
                </div>
              ) : selectedTab === "2" ? (
                <div>
                  <div className="cloud-sub-heading cloud-sub-head2 text-start mb-3">
                    IoT Solutions for Smart Cities
                  </div>
                  <div className="service-para">
                    IoT services are pivotal in developing smart cities, where
                    interconnected devices enhance urban living through improved
                    resource management, safety, and sustainability. Our
                    solutions for smart cities include
                  </div>
                  <div className="row px-5 iot-info-first mt-5">
                    <div className="col col-lg-3 ">
                      <div className="iot-info-content">
                        <div className="iot-info-title iot-info1-title">
                          <div>Smart</div>
                          <div>Lighting</div>
                        </div>
                        <div className="iot-info-descrip">
                          IoT-enabled streetlights that adjust brightness based
                          on ambient light and activity, reducing energy
                          consumption.
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-3 ">
                      <div className="iot-info-content">
                        <div className="iot-info-title iot-info2-title">
                          <div>Environmental</div>
                          <div>Monitoring</div>
                        </div>
                        <div className="iot-info-descrip">
                          Sensors that track air quality, noise levels, and
                          weather conditions, providing data for better urban
                          planning and health.
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-3">
                      <div className="iot-info-content">
                        <div className="iot-info-title iot-info3-title">
                          <div>Traffic</div>
                          <div>Management</div>
                        </div>
                        <div className="iot-info-descrip">
                          IoT systems that monitor and manage traffic flow,
                          reducing congestion and improving transportation
                          efficiency.
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-3">
                      <div className="iot-info-content">
                        <div className="iot-info-title iot-info4-title">
                          <div>Waste</div>
                          <div>Management</div>
                        </div>
                        <div className="iot-info-descrip">
                          Utilize protocols such as LoRa, BLE, ZigBee and Z-wave
                          Smart bins that optimise waste collection routes and
                          schedules, reducing operational costs and
                          environmental impact.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : selectedTab === "3" ? (
                <div>
                  <div className="cloud-sub-heading cloud-sub-head2 text-start mb-3">
                    Industrial IoT (IIoT)
                  </div>
                  <div className="service-para">
                    Industrial IoT solutions cater to manufacturing, logistics,
                    and other industrial sectors, offering real-time monitoring
                    and automation to enhance productivity and safety. Key
                    applications include
                  </div>
                  <div className="iot-info-first iot-info-second mt-5 px-5">
                    <div className="iot-second-infoo">
                      <div className="iot-info2-content iot-info2-content1">
                        <div className="iot-info-title2">
                          <div>
                            <div>Predictive</div>
                            <div>Maintenance</div>
                          </div>
                        </div>
                        <div className="iot-info2-descrip">
                          Sensors monitor equipment health, predicting failures
                          before they occur and minimizing downtime.
                        </div>
                      </div>
                    </div>
                    <div className="iot-second-infoo">
                      <div className="iot-info2-content iot-info2-content2">
                        <div className="iot-info-title2">
                          <div>
                            <div>Asset</div>
                            <div>Tracking</div>
                          </div>
                        </div>
                        <div className="iot-info2-descrip">
                          IoT devices track the location and status of assets in
                          real time, improving inventory management and
                          security.
                        </div>
                      </div>
                    </div>
                    <div className="iot-second-infoo">
                      <div className="iot-info2-content iot-info2-content3">
                        <div className="iot-info-title2">
                          <div>
                            <div>Process</div>
                            <div>Optimization</div>
                          </div>
                        </div>
                        <div className="iot-info2-descrip">
                          Data from IoT sensors optimises production processes,
                          reducing waste and improving efficiency.
                        </div>
                      </div>
                    </div>
                    <div className="iot-second-infoo">
                      <div className="iot-info2-content iot-info2-content4">
                        <div className="iot-info-title2">
                          <div>
                            <div>Process</div>
                            <div>Optimization</div>
                          </div>
                        </div>
                        <div className="iot-info2-descrip">
                          Monitoring and controlling energy usage in real-time
                          to reduce costs and environmental impact.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : selectedTab === "4" ? (
                <div>
                  <div className="cloud-sub-heading cloud-sub-head2 text-start mb-3">
                    Healthcare IoT
                  </div>
                  <div className="service-para">
                    In the healthcare sector, IoT solutions improve patient care
                    and operational efficiency through advanced monitoring and
                    automation
                  </div>
                  <div className="row px-5 iot-info-first mt-5">
                    <div className="col col-lg-3 ">
                      <div className="iot-info-content">
                        <div className="iot-info-title iot-info1-title">
                          <div>Remote Patient</div>
                          <div>Monitoring</div>
                        </div>
                        <div className="iot-info-descrip">
                          IoT devices track vital signs and health metrics,
                          enabling remote care and early intervention.
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-3 ">
                      <div className="iot-info-content">
                        <div className="iot-info-title iot-info2-title">
                          <div>Smart Medical</div>
                          <div>Devices</div>
                        </div>
                        <div className="iot-info-descrip">
                          Connected devices that provide real-time data to
                          healthcare providers, improving treatment accuracy and
                          outcomes.
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-3">
                      <div className="iot-info-content">
                        <div className="iot-info-title iot-info3-title">
                          <div>Facility</div>
                          <div>Management</div>
                        </div>
                        <div className="iot-info-descrip">
                          IoT systems manage hospital resources, including
                          lighting, HVAC, and security, enhancing comfort and
                          safety for patients and staff.
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-3">
                      <div className="iot-info-content">
                        <div className="iot-info-title iot-info4-title">
                          <div>Inventory</div>
                          <div>Management</div>
                        </div>
                        <div className="iot-info-descrip">
                          Automated tracking of medical supplies and equipment,
                          ensuring availability and reducing wastage.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : selectedTab === "5" ? (
                <div>
                  <div className="cloud-sub-heading cloud-sub-head2 text-start mb-3">
                    Agricultural IoT
                  </div>
                  <div className="service-para">
                    IoT services extend to agriculture, where smart farming
                    techniques enhance productivity and sustainability
                  </div>
                  <div className="iot-info-first iot-info-second mt-5 px-5">
                    <div className="iot-second-infoo">
                      <div className="iot-info2-content iot-info2-content1">
                        <div className="iot-info-title2">
                          <div>
                            <div>Precision</div>
                            <div>Farming</div>
                          </div>
                        </div>
                        <div className="iot-info2-descrip">
                          IoT sensors monitor soil conditions, weather, and crop
                          health, providing data for optimized farming
                          practices.
                        </div>
                      </div>
                    </div>
                    <div className="iot-second-infoo">
                      <div className="iot-info2-content iot-info2-content2">
                        <div className="iot-info-title2">
                          <div>
                            <div>Automated</div>
                            <div>Irrigation</div>
                          </div>
                        </div>
                        <div className="iot-info2-descrip">
                          Systems that adjust water delivery based on soil
                          moisture levels, reducing water usage and improving
                          crop yields.
                        </div>
                      </div>
                    </div>
                    <div className="iot-second-infoo">
                      <div className="iot-info2-content iot-info2-content3">
                        <div className="iot-info-title2">
                          <div>
                            <div>Livestock</div>
                            <div>Monitoring</div>
                          </div>
                        </div>
                        <div className="iot-info2-descrip">
                          IoT devices track the health and location of
                          livestock, improving animal welfare and management
                          efficiency.
                        </div>
                      </div>
                    </div>
                    <div className="iot-second-infoo">
                      <div className="iot-info2-content iot-info2-content4">
                        <div className="iot-info-title2">
                          <div>
                            <div>Supply Chain</div>
                            <div>Management</div>
                          </div>
                        </div>
                        <div className="iot-info2-descrip">
                          Real-time tracking of agricultural products from farm
                          to market, ensuring quality and reducing losses.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="cloud-sub-heading cloud-sub-head2 text-start mb-3">
                    Energy and Utilities
                  </div>
                  <div className="service-para">
                    For energy and utility companies, IoT solutions provide
                    comprehensive monitoring and control to enhance efficiency
                    and reliability
                  </div>
                  <div className="row px-5 iot-info-first mt-5">
                    <div className="col col-lg-3 ">
                      <div className="iot-info-content">
                        <div className="iot-info-title iot-info1-title">
                          <div>Smart Grid</div>
                          {/* <div>Monitoring</div> */}
                        </div>
                        <div className="iot-info-descrip">
                          IoT-enabled grids that balance supply and demand,
                          integrating renewable energy sources and improving
                          resilience.
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-3 ">
                      <div className="iot-info-content">
                        <div className="iot-info-title iot-info2-title">
                          <div>Energy</div>
                          <div>Monitoring</div>
                        </div>
                        <div className="iot-info-descrip">
                          Real-time tracking of energy usage, identifying
                          opportunities for conservation and cost savings.
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-3">
                      <div className="iot-info-content">
                        <div className="iot-info-title iot-info3-title">
                          <div>Automated</div>
                          <div>Metering</div>
                        </div>
                        <div className="iot-info-descrip">
                          Remote monitoring and billing for utilities like
                          electricity, gas, and water, reducing manual labour
                          and improving accuracy.
                        </div>
                      </div>
                    </div>
                    <div className="col col-lg-3">
                      <div className="iot-info-content">
                        <div className="iot-info-title iot-info4-title">
                          <div>Demand</div>
                          <div>Response</div>
                        </div>
                        <div className="iot-info-descrip">
                          IoT systems that adjust energy consumption during peak
                          periods, stabilising the grid and reducing costs
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            className="contact-us-end-container mt-5 px-5"
            style={{ paddingTop: "50px" }}
          >
            <span className="contact-us-end-text">
              {" "}
              Join the IoT Revolution.
            </span>
            <span className="service-para">
              With Aeon Software's IoT services, we're not just integrating
              smart technologies - we're transforming how we manage and optimise
              resources. Join us in creating a connected, efficient, and
              sustainable future. Embrace the power of IoT with Aeon, and
              together, let's make a difference - one smart solution at a time.
            </span>
            <span className="contact-us-end-text">
              Embrace the IoT Revolution! Transform Your Operations with Aeon
              Software Today.
            </span>

            <Link className="contactUs-link" to="/contact-us">
              Contact Us!
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default IoT;
