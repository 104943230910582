import React, { useEffect } from "react";
import mainImg from "../../Assests/Services/serviceBanner.png";
import Banner from "../Banner";
import "./service.css";
import { Link } from "react-router-dom";
import info1 from "../../Assests/Services/pdInfo1.png";
import info2 from "../../Assests/Services/pdInfo2.png";
import info3 from "../../Assests/Services/pdInfo3.png";
import info4 from "../../Assests/Services/pdInfo4.png";
import info5 from "../../Assests/Services/pdInfo5.png";
import info6 from "../../Assests/Services/pdInfo6.png";
import info7 from "../../Assests/Services/pdInfo7.png";
import info8 from "../../Assests/Services/pdInfo8.png";
import info9 from "../../Assests/Services/pdInfo9.png";
import info10 from "../../Assests/Services/pdInfo10.png";
import info11 from "../../Assests/Services/pdInfo11.png";
import info12 from "../../Assests/Services/pdInfo12.png";
import info13 from "../../Assests/Services/pdInfo13.png";
import info14 from "../../Assests/Services/pdInfo14.png";

function ProjectDevelopment() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Services" />
        <div className="below-banner-mad-container">
          <h1 className="product-banner-heading">Product Development</h1>
          <div className="service-para">
            At Aeon, we're all about creating tech solutions that don't just
            work—they innovate! We specialize in crafting advanced tools that
            make business operations smoother, boost efficiency, and set the
            stage for sustainable growth. With our knack for excellence and a
            customer-first mindset, we're not just in the game; we're changing
            it.
          </div>
          <div className="pd-first-info d-flex justify-content-center align-items-center mb-5">
            <div>
              <img src={info1} alt="Cost Optimization Guarantee" />
            </div>
            <div>
              <img src={info2} alt="Global Clientele" />
            </div>
            <div>
              <img src={info3} alt="UI/UX design and development" />
            </div>
            <div>
              <img src={info4} alt="Qualitu Assurance" />
            </div>
          </div>
        </div>
        <div className="mad-service-second-container">
          <div className="product-banner-heading mad-third-heading dls-second-heading mb-5">
            Our Approach to Product Development
          </div>
          <div className="service-para">
            We follow a structured approach to product development that ensures
            innovation, quality, and client satisfaction.
          </div>
          <div className="mt-5">
            <img
              className="w-100"
              src={info5}
              alt="product development approach"
            />
          </div>
        </div>
        <div className="below-banner-mad-container">
          <div className="product-banner-heading text-start dls-second-heading mb-5">
            Why Choose Us?
          </div>
          <div className="pd-first-info d-flex justify-content-center align-items-center mb-5 px-5">
            <div>
              <img src={info6} alt="Proven expertise" />
            </div>
            <div>
              <img src={info7} alt="client centric" />
            </div>
            <div>
              <img src={info8} alt="quality assurance" />
            </div>
            <div>
              <img src={info9} alt="innovation leadership" />
            </div>
          </div>
          <div className="product-banner-heading text-start dls-second-heading pd-serve">
            Industries we serve
          </div>
          <div className="px-5 pd-industries-serve d-flex flex-column justify-content-center align-items-center">
            <div className="row gx-5 mb-5">
              <div className="col-12 col-lg-4">
                <img src={info10} alt="Public Transport Solutions" />
                <div className="pd-industry-label">
                  <span className="w-80">Public Transport Solutions</span>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <img src={info11} alt="Education and Training" />
                <div className="pd-industry-label">
                  <span>Education and Training</span>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <img src={info12} alt="Retail and Hospitality" />
                <div className="pd-industry-label">
                  <span>Retail and Hospitality</span>
                </div>
              </div>
            </div>
            <div className="row px-5 gx-5">
              <div className="col-12 col-lg-6">
                <img src={info13} alt="AgriTech" />
                <div className="pd-industry-label">
                  <span>AgriTech</span>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <img src={info14} alt="Energy and Sustainability" />
                <div className="pd-industry-label">
                  <span>Energy and Sustainability</span>
                </div>
              </div>
            </div>
          </div>
          <div className="contact-us-end-container mt-5">
            <span className="service-para">
              For more information on our key products, visit our Product
              Section.
            </span>
            <span className="contact-us-end-text">
              Ready to start your next project? Contact us today to discuss your
              specific needs and how we can bring your vision to life.
            </span>
            <Link className="contactUs-link" to="/contact-us">
              Contact Us!
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectDevelopment;
