import http from "../http-common";

const submitContactUsData = (data) => {
  return http.post("/Candidate/clientreqEmail", data);
};

const submitRequestDemoData = (data) => {
  return http.post("/Candidate/clientdemoEmail", data);
};

const ContactService = {
  submitContactUsData,
  submitRequestDemoData,
};

export default ContactService;
