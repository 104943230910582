import React, { useEffect, useState } from "react";
import "./App.css";
import Homescreen from "./Components/Homescreen";
import Navbarr from "./Components/Navbarr";
import TopNavbar from "./Components/TopNavbar";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import AboutUs from "./Components/AboutUs/AboutUs";
import ProductPage from "./Components/Products/TktProductPage";
import DepoManageProduct from "./Components/Products/DepoManageProduct";
import Transportation from "./Components/Domains/Transportation";
import Aviation from "./Components/Domains/Aviation";
import Agriculture from "./Components/Domains/Agriculture";
import Mining from "./Components/Domains/Mining";
import ElecricVehicle from "./Components/Domains/ElecricVehicle";
import FoodManufacturing from "./Components/Domains/FoodManufacturing";
import Banking from "./Components/Domains/Banking";
import ECommerce from "./Components/Domains/ECommerce";
import LMS from "./Components/Products/LMS";
import TMS from "./Components/Products/TMS";
import Idms from "./Components/Services/Idms";
import MobileAppDev from "./Components/Services/MobileAppDev";
import DigitalLearnSolution from "./Components/Services/DigitalLearnSolution";
import ProjectConsultancy from "./Components/Services/ProjectConsultancy";
import ProjectDevelopment from "./Components/Services/ProjectDevelopment";
import SystemIntegration from "./Components/Services/SystemIntegration";
import UiDesign from "./Components/Services/UiDesign";
import CloudErp from "./Components/Services/CloudErp";
import IoT from "./Components/Services/IoT";
import ContactUs from "./Components/ContactUs";
import Careers from "./Components/Careers/Careers";
import CareerDescription from "./Components/Careers/CareerDescription";
import HrLogin from "./Components/HR/HrLogin";
import JobPost from "./Components/HR/JobPost";
import AllJobs from "./Components/HR/AllJobs";

function App() {
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setIsLoggedIn(true);
    }
  }, [isLoggedIn]);

  return (
    <div className="App">
      {location.pathname === "/" ||
      location.pathname.includes("/e-governance") ? (
        <Navbarr />
      ) : (
        <></>
      )}

      <Routes>
        <Route path="/" element={<Homescreen />} />
        <Route path="/e-governance" element={<Homescreen />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        {/* Domains */}
        <Route path="/transportationDomain" element={<Transportation />} />
        <Route path="/aviationDomain" element={<Aviation />} />
        <Route path="/agricultureDomain" element={<Agriculture />} />
        <Route path="/mining" element={<Mining />} />
        <Route path="/elecricVehicle" element={<ElecricVehicle />} />
        <Route path="/foodManufacturing" element={<FoodManufacturing />} />
        <Route path="/banking" element={<Banking />} />
        <Route path="/eCommerce" element={<ECommerce />} />
        {/* Services */}
        <Route path="/idms" element={<Idms />} />
        <Route path="/mobile-application" element={<MobileAppDev />} />
        <Route
          path="/digitalLearningSolution"
          element={<DigitalLearnSolution />}
        />
        <Route path="/managementConsultancy" element={<ProjectConsultancy />} />
        <Route path="/projectDevelopment" element={<ProjectDevelopment />} />
        <Route path="/SIS" element={<SystemIntegration />} />
        <Route path="/uiUxDesign" element={<UiDesign />} />
        <Route path="/cloud" element={<CloudErp />} />
        <Route path="/iotIntegration" element={<IoT />} />
        {/* Products */}
        <Route path="/mobileTicketingSolution" element={<ProductPage />} />
        <Route path="/depoManagement" element={<DepoManageProduct />} />
        <Route path="/lms" element={<LMS />} />
        <Route path="/tms" element={<TMS />} />
        {/* Careers */}
        <Route path="/careers" element={<Careers />} />
        <Route path="/applyNow/:id/:pid" element={<CareerDescription />} />

        {/* HR */}
        {/* {isLoggedIn ? (
          <>
            <Route path="/allRequirements" element={<AllJobs />} />
            <Route path="/login" element={<JobPost />} />
            <Route path="/humanResourceJobPosting" element={<JobPost />} />
          </>
        ) : (
          <Route
            path="/login"
            element={<HrLogin setIsLoggedIn={setIsLoggedIn} />}
          />
        )} */}
      </Routes>
    </div>
  );
}

export default App;
