import React, { useEffect, useState } from "react";
import "./career.css";
import Banner from "../Banner";
import mainImg from "../../Assests/careerMainImg.png";
import Form from "react-bootstrap/Form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation, useParams } from "react-router-dom";
import CareerService from "../../ApiService/career.service";
import { RotatingLines } from "react-loader-spinner";
import thankYouImg from "../../Assests/thankYou.png";
import Modal from "react-bootstrap/Modal";
import { FloatingLabel } from "react-bootstrap";
import Select from "react-select";

function CareerDescription() {
  const location = useLocation();
  const { id, pid } = useParams();
  // console.log(reg_id);

  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({
    fullName: "",
    email: "",
    phone: "",
    resume: null,
    designation: "",
    noticePeriod: "",
    experience: "",
    currentCtc: "",
    expectedCTC: "",
    reason: "",
    location: "",
    jobPostId: "",
    message: "",
    gender: "",
  });
  const [validated, setValidated] = useState(false);

  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    fetchJobDetails();
  }, []);

  const fetchJobDetails = () => {
    const data = {
      Id: pid,
    };

    CareerService.getJobById(data)
      .then((response) => {
        if (response) {
          setData(response.data);
        }
      })
      .catch((e) => alert(e));
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    const mobileNumRegex = /^\d{0,10}$/;
    const isNumberRegex = /^[0-9]*$/;

    if (name === "phone" && !mobileNumRegex.test(value)) {
      return;
    }

    if (
      (name === "experience" || name === "noticePeriod") &&
      !isNumberRegex.test(value)
    ) {
      return;
    }

    if (name === "resume") {
      setUserDetails({ ...userDetails, resume: files[0] });
      return;
    }

    setUserDetails({ ...userDetails, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // console.log(userDetails);

    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      toast("*Please fill all required fields!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      return;
    }

    // For saving in database
    const formData = new FormData();
    formData.append("post_id", pid);
    formData.append("candidate_name", userDetails.fullName);
    formData.append("email", userDetails.email);
    formData.append("phone", userDetails.phone);
    formData.append("designation", userDetails.designation);
    formData.append("current_ctc", userDetails.currentCtc);
    formData.append("exp_ctc", userDetails.expectedCTC);
    formData.append("reasonfor_swich", userDetails.reason);
    formData.append("location", data.location);
    formData.append(
      "comment",
      userDetails.message === "" ? "No message from user" : userDetails.message
    );
    formData.append("attchresume", userDetails.resume);
    formData.append("status", "0");
    formData.append("reg_type", id);
    formData.append("action_status", 1);
    formData.append("Img", null);
    formData.append("Notice_period", userDetails.noticePeriod);
    formData.append("Gender", userDetails.gender);
    formData.append("Experience", userDetails.experience);

    // For sending mail
    const formData1 = new FormData();
    formData1.append("Fullname", userDetails.fullName);
    formData1.append("Email", userDetails.email);
    formData1.append("Phoneno", userDetails.phone);
    formData1.append("ImgFile", userDetails.resume);
    formData.append("Current_CTC", userDetails.currentCtc);
    formData1.append("Position", userDetails.designation);
    formData1.append("Noticeperiod", userDetails.noticePeriod);
    formData1.append("Experience", userDetails.experience);
    formData1.append(
      "message",
      userDetails.message === "" ? "No message from user" : userDetails.message
    );

    setLoading(true);
    handleShow();

    CareerService.userJobRequest(formData1)
      .then(
        (response) => {
          if (response.data === 1) {
            setLoading(false);
          }
        },
        (error) => {
          setLoading(false);
          handleClose();
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          toast(`${resMessage}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      )
      .catch((e) => console.log(e));

    CareerService.addNewCandidate(formData)
      .then(
        (response) => {
          if (response.data === true) {
            setUserDetails({
              fullName: "",
              email: "",
              phone: "",
              resume: null,
              designation: "",
              noticePeriod: "",
              experience: "",
              currentCtc: "",
              expectedCTC: "",
              reason: "",
              location: "",
              jobPostId: "",
              message: "",
              gender: "",
            });
          }
        },
        (error) => {
          handleClose();
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          toast(`${resMessage}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      )
      .catch((e) => console.log(e));
  };

  const handleClose = () => {
    setShow(false);
    setLoading(false);
  };
  const handleShow = () => setShow(true);

  return (
    <div>
      <ToastContainer />
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Careers" />
        <div className="below-banner-container applyNow-banner-container">
          <div className="applyNow-main-container">
            <div className="blue-border"></div>
            <div className="career-heading">
              <div className="career-subhead">{data.designation}</div>
              <div className="career-location">
                <span>
                  <svg
                    id="location_1_"
                    data-name="location (1)"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="15"
                    viewBox="0 0 19.495 22.194"
                  >
                    <g
                      id="Group_16028"
                      data-name="Group 16028"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_8340"
                        data-name="Path 8340"
                        d="M39.639-.5H41.2a7.247,7.247,0,0,1,5.223,11.141q-1.129,1.725-2.232,3.468a21.944,21.944,0,0,1,3.381.932A5.424,5.424,0,0,1,49.8,16.579a2.184,2.184,0,0,1-.173,2.644,6.569,6.569,0,0,1-2.8,1.582,21.5,21.5,0,0,1-4.985.889H38.988a21.8,21.8,0,0,1-4.595-.759,9.847,9.847,0,0,1-2.514-1.127,2.457,2.457,0,0,1-1.192-2.449,2.621,2.621,0,0,1,.759-1.235A7.24,7.24,0,0,1,34,14.78q1.311-.387,2.644-.672-1.127-1.821-2.319-3.6a7.415,7.415,0,0,1-.52-6.632A7.272,7.272,0,0,1,39.639-.5Zm.13,1.344a5.915,5.915,0,0,1,5.744,2.991,6.045,6.045,0,0,1-.087,5.852q-2.516,3.893-5.007,7.8-2.491-3.91-5.007-7.8a6.045,6.045,0,0,1-.087-5.852A5.92,5.92,0,0,1,39.769.844ZM37.211,15.409a.392.392,0,0,1,.325.087q1.206,1.946,2.493,3.836a.729.729,0,0,0,.932-.152Q42.147,17.349,43.3,15.5a.217.217,0,0,1,.2-.087,14.993,14.993,0,0,1,3.685.932,4.022,4.022,0,0,1,1.5,1.019.8.8,0,0,1,.13.65,2.815,2.815,0,0,1-.542.629,8.26,8.26,0,0,1-2.644,1.127,22.29,22.29,0,0,1-6.589.564,17.082,17.082,0,0,1-5.158-1,5.122,5.122,0,0,1-1.582-.932,2.805,2.805,0,0,1-.26-.39.966.966,0,0,1,.043-.52,3.929,3.929,0,0,1,1.582-1.149A16.12,16.12,0,0,1,37.211,15.409Z"
                        transform="translate(-30.673 0.5)"
                        fill-rule="evenodd"
                        opacity="0.963"
                      />
                    </g>
                    <g
                      id="Group_16029"
                      data-name="Group 16029"
                      transform="translate(6.497 3.925)"
                    >
                      <path
                        id="Path_8341"
                        data-name="Path 8341"
                        d="M183.28,90.063a3.293,3.293,0,0,1,2.384,5.96,3.524,3.524,0,0,1-3.164.3,3.274,3.274,0,0,1-1.886-3.75A3.355,3.355,0,0,1,183.28,90.063Zm.13,1.344a1.972,1.972,0,0,0-1.5,2.3,1.926,1.926,0,1,0,3.771-.78A1.966,1.966,0,0,0,183.41,91.407Z"
                        transform="translate(-180.551 -90.044)"
                        fill-rule="evenodd"
                        opacity="0.964"
                      />
                    </g>
                  </svg>
                  <span style={{ textTransform: "capitalize" }}>
                    {data.location}
                  </span>
                </span>
                <span>
                  <svg
                    id="clock_1_"
                    data-name="clock (1)"
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 22.194 22.194"
                  >
                    <g
                      id="Group_16026"
                      data-name="Group 16026"
                      transform="translate(0)"
                    >
                      <path
                        id="Path_8338"
                        data-name="Path 8338"
                        d="M9.643-.5h1.907a11.2,11.2,0,0,1,9.3,6.762,12.1,12.1,0,0,1,.845,3.381v1.907a11.2,11.2,0,0,1-6.762,9.3,12.1,12.1,0,0,1-3.381.845H9.643a11.2,11.2,0,0,1-9.3-6.762A12.1,12.1,0,0,1-.5,11.551V9.643A11.2,11.2,0,0,1,6.262.345,12.1,12.1,0,0,1,9.643-.5Zm.433,1.734a9.44,9.44,0,0,1,8.691,4.812A9.35,9.35,0,0,1,9.253,19.852a9.467,9.467,0,0,1-6.827-4.7A9.336,9.336,0,0,1,2.6,5.742,9.477,9.477,0,0,1,10.077,1.234Z"
                        transform="translate(0.5 0.5)"
                        fill-rule="evenodd"
                        opacity="0.971"
                      />
                    </g>
                    <g
                      id="Group_16027"
                      data-name="Group 16027"
                      transform="translate(10.228 5.115)"
                    >
                      <path
                        id="Path_8339"
                        data-name="Path 8339"
                        d="M236.231,117.5a.9.9,0,0,1,.932.65q.022,2.471.043,4.942l3.208,2.428a.923.923,0,0,1,.043,1.17.994.994,0,0,1-1.235.152l-3.425-2.558a.882.882,0,0,1-.282-.368q-.107-2.879-.043-5.765A.852.852,0,0,1,236.231,117.5Z"
                        transform="translate(-235.449 -117.5)"
                        fill-rule="evenodd"
                        opacity="0.976"
                      />
                    </g>
                  </svg>

                  <span>{data.createdOn}</span>
                </span>
              </div>
              <div className="lightGrey-line"></div>
            </div>

            <div className="mb-4">{data.requirement}</div>
            <div className="lightGrey-line"></div>
            <div className="position-details-container">
              <div className="applyNow-sub-heading">Position Details</div>
              <div className="mb-2 applyNow-jd">
                Experience: {data.experience} years
              </div>
              <div className="applyNow-jd">{data.jobDescription}</div>
            </div>
            <div className="position-details-container">
              <div className="applyNow-sub-heading">Submit Form</div>
              <div className="applyNow-form">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <div className="row">
                    <div className="col-12 col-sm-6 col-lg-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="text"
                          placeholder="Full Name*"
                          name="fullName"
                          required
                          value={userDetails.fullName}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6  col-lg-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="email"
                          placeholder="Email*"
                          required
                          name="email"
                          value={userDetails.email}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6  col-lg-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="text"
                          placeholder="Phone*"
                          name="phone"
                          required
                          value={userDetails.phone}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <div className="form-control upload-form-control">
                          {userDetails.resume ? (
                            <div className="file-name">
                              {userDetails.resume.name}
                            </div>
                          ) : (
                            <div className="file-name">Upload your CV*</div>
                          )}
                          <Form.Control
                            className="file-input"
                            type="file"
                            required
                            name="resume"
                            onChange={handleChange}
                          />
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 19.886 20.799"
                          >
                            <g
                              id="attachment"
                              transform="translate(-10.979 0.5)"
                            >
                              <path
                                id="Path_8342"
                                data-name="Path 8342"
                                d="M25.5-.5h1.056A4.349,4.349,0,0,1,29.95,2.384a3.717,3.717,0,0,1-.65,3.534q-5.435,5.455-10.907,10.867a2.322,2.322,0,0,1-3.189-3.27q3.6-3.665,7.251-7.292a.452.452,0,0,1,.569-.041.461.461,0,0,1,.061.63l-7.109,7.109a1.46,1.46,0,0,0,2.011,2.092l10.7-10.7a2.974,2.974,0,0,0-.162-3.737,3.066,3.066,0,0,0-4-.792l-.325.244L12.929,12.3a4.293,4.293,0,0,0,5.911,6.2L30.093,7.239q.569-.356.772.284l-.061.183q-5.675,5.736-11.395,11.435A5.673,5.673,0,0,1,16.728,20.3H15.468a5.139,5.139,0,0,1-4.408-4.144,5.2,5.2,0,0,1,1.341-4.55L23.552.455A3.892,3.892,0,0,1,25.5-.5Z"
                                fill-rule="evenodd"
                                opacity="0.938"
                              />
                            </g>
                          </svg>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="text"
                          placeholder="Designation*"
                          required
                          name="designation"
                          value={userDetails.designation}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="text"
                          placeholder="Notice Period (in days)*"
                          required
                          name="noticePeriod"
                          value={userDetails.noticePeriod}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="text"
                          required
                          placeholder="Current CTC (in lakhs)*"
                          name="currentCtc"
                          value={userDetails.currentCtc}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="text"
                          required
                          placeholder="Expected CTC (in lakhs)*"
                          name="expectedCTC"
                          value={userDetails.expectedCTC}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-6">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="text"
                          required
                          placeholder="Experience (in years)*"
                          name="experience"
                          value={userDetails.experience}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>

                    <div className="col-12 col-sm-6 col-lg-6">
                      <Form.Group
                        controlId="exampleForm.ControlInput1"
                        className="mb-3"
                      >
                        <Form.Select
                          name="gender"
                          required
                          classNamePrefix="select"
                          onChange={handleChange}
                        >
                          <option selected disabled>
                            Gender*
                          </option>
                          {genderOptions.map((opt) => (
                            <option value={opt.value}>{opt.label}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-12 col-lg-12">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="text"
                          placeholder="Reason for switch*"
                          required
                          name="reason"
                          value={userDetails.reason}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-12">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          as="textarea"
                          maxLength="200"
                          rows={4}
                          placeholder="Message"
                          name="message"
                          value={userDetails.message}
                          onChange={handleChange}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="apply-now">
                    <button>Apply Now</button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body
          className="d-flex justify-content-center align-items-center modal-career"
          style={{ height: "200px", width: "100%" }}
        >
          {loading ? (
            <div className="spinner-container">
              <RotatingLines
                visible={true}
                height="80"
                width="80"
                color="#37466F"
                strokeColor="#37466F"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            </div>
          ) : (
            <div className="thank-you-career text-center">
              <div>Thank you for applying.</div>
              <div>We have received your application.</div>
              <p>Click outside the box to close</p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default CareerDescription;
