import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import img1 from "../../Assests/Domains/explore1.png";
import img2 from "../../Assests/Domains/explore2.png";
import img3 from "../../Assests/Domains/explore3.png";
import img4 from "../../Assests/Domains/explore4.png";
import img5 from "../../Assests/Domains/explore5.png";
import img6 from "../../Assests/Domains/explore6.png";
import img7 from "../../Assests/Domains/explore7.png";
import img8 from "../../Assests/Domains/explore8.png";
import { Link, useNavigate } from "react-router-dom";

function ExploreDomains() {
  const domainImgs = [
    { id: 1, img: img1, title: "Aviation" },
    { id: 2, img: img2, title: "Agriculture" },
    { id: 3, img: img3, title: "Mining" },
    { id: 4, img: img4, title: "Electric Vehicle" },
    { id: 5, img: img5, title: "Banking" },
    { id: 6, img: img6, title: "ECommerce" },
    { id: 7, img: img7, title: "Food Manufacturing" },
    { id: 8, img: img8, title: "Transportation" },
  ];

  const navigate = useNavigate();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleDomainClick = (id) => {
    if (id === 1) {
      navigate("/aviationDomain");
    } else if (id === 2) {
      navigate("/agricultureDomain");
    } else if (id === 3) {
      navigate("/mining");
    } else if (id === 4) {
      navigate("/elecricVehicle");
    } else if (id === 5) {
      navigate("/banking");
    } else if (id === 6) {
      navigate("/eCommerce");
    } else if (id === 7) {
      navigate("/foodManufacturing");
    } else if (id === 8) {
      navigate("/transportationDomain");
    }
  };

  return (
    <div className="domain-explore-carousel-container">
      <Slider {...settings}>
        {domainImgs.map((domain, index) => (
          <div className="domain-explore-slide" key={index}>
            <div className="domain-explore-box">
              {domain.img && (
                <div
                  className="domain-explore-image"
                  onClick={() => handleDomainClick(domain.id)}
                >
                  <img
                    src={domain.img}
                    alt={`img-${index}`}
                    className="img-fluid"
                  />

                  <div className="opaque-screen explore-domain-opq">
                    {domain.title}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default ExploreDomains;
