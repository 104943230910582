import React, { useEffect, useState } from "react";
import mainImg from "../../Assests/Domains/miningDomainImg.png";
import "./domain.css";
import transport1 from "../../Assests/Domains/transportImg.png";
import MiningImg1 from "../../Assests/Domains/mining.png";
import Banner from "../Banner";
import info1 from "../../Assests/Domains/miningInfo1.png";
import info2 from "../../Assests/Domains/miningInfo2.png";
import info3 from "../../Assests/Domains/miningInfo3.png";
import { Link } from "react-router-dom";
import ExploreDomains from "./ExploreDomains";
import Aos from "aos";
import "aos/dist/aos.css";
import domainVideo from "../../Assests/Domains/Videos/Aeon Mining.mp4";
import Modal from "react-bootstrap/Modal";

function Mining() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = () => setShow(true);

  return (
    <div>
      <div className="aboutUs-main-container miningDomain">
        <Banner mainImg={mainImg} title="Domains" />
        <div className="below-banner-container domain-main-container">
          <h1 className="domain-banner-heading">Mining</h1>
          <div className="domain-inner-container">
            <div className="domain-para">
              A mining venture involves a number of operations running in sync.
              We understand how essential it is to optimally distribute your
              resources across all the different processes. Aeon’s customized
              ERP solutions can help you out here, aligning your processes
              efficiently and ensuring that your output is profitable.
            </div>

            <div className="domain-info1">
              <div className="domain-info1-img mining">
                <img src={MiningImg1} alt="Mining" />
                <div className="circle-anim" onClick={handleShow}>
                  <div className="discover-now-btn">
                    <div className="step">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Bold"
                        viewBox="0 0 24 24"
                        width="60"
                        height="35"
                        fill="#37466F"
                      >
                        <path d="M9,17.879V6.707A1,1,0,0,1,10.707,6l5.586,5.586a1,1,0,0,1,0,1.414l-5.586,5.586A1,1,0,0,1,9,17.879Z" />
                      </svg>
                      <div className="ripple"></div>
                      <div className="ripple"></div>
                      <div className="ripple"></div>
                    </div>
                  </div>
                </div>
                <div className="opaque-screen domain-opaque-film"></div>
                <Modal show={show} onHide={handleClose} centered size="lg">
                  <Modal.Body className="domain-video-modal">
                    <div>Click outside the box to close</div>
                    <video
                      src={domainVideo}
                      autoPlay
                      loop
                      controls
                      controlsList="nodownload"
                      muted
                    />
                  </Modal.Body>
                </Modal>
              </div>
            </div>

            <div className="row gy-5">
              <div className="col-lg-6">
                <div
                  className="domain-descrip-left d-flex flex-column justify-content-center align-items-start"
                  data-aos="fade-right"
                >
                  <h1 className="sub-heading-product product-descrip-heading">
                    Optimize your production pipeline
                  </h1>
                  <div className="product-para">
                    Using our smart solutions, track and manage the processes
                    for ore extraction and refining. Ensure smooth operations
                    and increase productivity.
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="domain-descrip-right mining-img"
                  data-aos="fade-left"
                >
                  <img src={info1} alt="Optimize production" />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="domain-descrip-right mining-img"
                  data-aos="fade-right"
                >
                  <img src={info2} alt="Strategic Resourcing" />
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="domain-descrip-left d-flex flex-column justify-content-center align-items-start"
                  data-aos="fade-left"
                >
                  <h1 className="sub-heading-product product-descrip-heading">
                    Strategic Resourcing: Maximize Every Asset
                  </h1>
                  <div className="product-para">
                    Maximize efficiency of your machines by tracking usage and
                    maintenance cycles. Draw insights from data to understand
                    how to best allocate your resources across your operations.
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="domain-descrip-left d-flex flex-column justify-content-center align-items-start"
                  data-aos="fade-right"
                >
                  <h1 className="sub-heading-product product-descrip-heading">
                    Seamless delivery from Mine to Market!
                  </h1>
                  <div className="product-para">
                    Our integrated vendor management tool helps you with
                    outsourcing the transport and logistical operations,
                    ensuring your finished product makes it into the market on
                    time.
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div
                  className="domain-descrip-right mining-img"
                  data-aos="fade-left"
                >
                  <img src={info3} alt="delivering mining" />
                </div>
              </div>

              <div className="contact-us-end-container">
                <span className="contact-us-end-text">
                  Aeon can help you streamline processes and optimize resources.
                  Find out how!
                </span>
                <Link className="contactUs-link" to="/contact-us">
                  Get in touch!
                </Link>
              </div>
            </div>
          </div>
          <div className="explore-domains">
            <div className="heading">
              <h2>Explore Our Other Domains</h2>
            </div>
            <ExploreDomains />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Mining;
