import React, { useEffect } from "react";
import mainImg from "../../Assests/Products/productTMSBanner.png";
import Banner from "../Banner";
import "./products.css";
import TMSProduct1 from "../../Assests/Products/TMSProduct1.png";
import { Link } from "react-router-dom";
import productVideo1 from "../../Assests/Products/TMS1.mp4";
import productVideo2 from "../../Assests/Products/TMS2.mp4";

function TMS() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Products" />
        <div className="below-banner-container product-below-banner-container">
          <h1 className="product-banner-heading">Training Management System</h1>
          <div className="product-inner-container">
            <div className="row mb-5">
              <div className="col-lg-6">
                <div className="product-para mt-4 mb-5">
                  Training programs and managing resources in today's
                  educational world can be incredibly challenging. For a large
                  institute with numerous students spread across multiple
                  campuses, offering a wide variety of courses – from aviation
                  to healthcare, engineering to hospitality- juggling
                  classrooms, instructors, equipment, and course materials is
                  like trying to solve a massive puzzle every single day.
                </div>
                <div className="product-para mt-4 mb-5">
                  What if there was a solution that could untangle this
                  logistical mess, optimizing resource usage and ensuring a
                  smooth learning experience for students and faculty?
                  Introducing Aeon TMS, a training management system that makes
                  running educational institutes much easier.
                </div>
              </div>
              <div className="col-lg-6">
                {/* <div className="product-img">
                  <img
                    src={TMSProduct1}
                    width="100%"
                    alt="Training Management System"
                  />
                </div> */}
                <div className="product-video-container pVideo-two">
                  <video
                    src={productVideo1}
                    controls={false}
                    autoPlay={true}
                    loop
                    muted
                  />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="product-features">
                  <div className="fbox one"></div>
                  <div className="features-heading">
                    <span>Asset Allocation and Resource Management</span>
                  </div>
                  <div className="features-para">
                    <span>
                      One of the key strengths of Aeon TMS is its powerful asset
                      allocation and resource management capabilities. The
                      system allows institutions to centrally manage all their
                      training resources, including classrooms, equipment,
                      instructors, equipment, and course materials. With a
                      comprehensive overview of available assets, administrators
                      can efficiently schedule and allocate resources based on
                      specific program requirements, ensuring optimal
                      utilization and minimizing conflicts.
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="product-features">
                  <div className="fbox two"></div>
                  <div className="features-heading">
                    <span>Automatic Invoice Generation</span>
                  </div>
                  <div className="features-para">
                    <span>
                      Aeon TMS streamlines the billing process by automating
                      invoice generation. Based on student enrollments, course
                      fees, and any additional charges, the system automatically
                      calculates and generates accurate invoices. This feature
                      not only saves time and effort for administrative staff
                      but also reduces the risk of errors, enhancing overall
                      financial management.
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="product-subheading mb-4">Success Story</div>
            <div className="product-para mt-4 mb-5">
              Aeon TMS has proven its effectiveness in managing large-scale
              training operations at the prestigious Gulf Aviation Academy. With
              multiple campuses and a diverse range of aviation-related
              programs, the academy faced challenges in coordinating resources,
              monitoring student progress, and ensuring regulatory compliance.
            </div>
            <div className="product-para mt-4 mb-5">
              The system's intuitive asset allocation capabilities have enabled
              the academy to optimize the use of its state-of-the-art flight
              simulators, aircraft hangers, and specialized training facilities.
              Instructors can now easily access and share course materials,
              while administrators have real-time visibility into program
              performance and student progress.
            </div>

            <div className="product-subheading mb-4">
              Comprehensive Training Management
            </div>

            <div className="product-para mt-4 mb-5">
              In addition to its core features, Aeon TMS offers a comprehensive
              suite of tools to streamline training management processes:
            </div>

            <div className="row">
              <div className="col-12 col-lg-6 col-md-6">
                <div className="product-para mt-4 mb-4">
                  <strong>Learner Management:</strong> Maintain detailed learner
                  profiles, track progress, and facilitate self-enrollment in
                  courses.
                </div>

                <div className="product-para mb-4">
                  <strong>Course Management:</strong> Create and manage a
                  centralized course catalog, schedule sessions, and assign
                  instructors based on expertise.
                </div>

                <div className="product-para mb-4">
                  <strong>Reporting and Analytics:</strong> Generate insightful
                  reports on training effectiveness, compliance, and key
                  performance indicators.
                </div>

                <div className="product-para mb-4">
                  <strong>Compliance Tracking:</strong> Ensure adherence to
                  mandatory training requirements and industry regulations.
                </div>

                <div className="product-para mb-4">
                  <strong>Instructor Collaboration:</strong> Foster
                  collaboration among instructors, enabling resource sharing and
                  feedback exchange.
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-6 mt-4">
                <div className="product-video-container pVideo-two">
                  <video
                    src={productVideo2}
                    controls={false}
                    autoPlay={true}
                    loop
                    muted
                  />
                </div>
              </div>

              <div className="product-para mb-4">
                With its powerful features, proven track record, and commitment
                to continuous improvement, Aeon TMS stands as a comprehensive
                solution for educational institutions seeking to optimize their
                training operations, enhance resource utilization, and deliver
                exceptional learning experiences.
              </div>
            </div>

            <div className="contact-us-end-container">
              <span className="product-para">
                Contact us today to learn more and get started on enhancing your
                organization’s training programs.
              </span>
              <Link className="contactUs-link" to="/contact-us">
                Request a Demo
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TMS;
