import React, { useEffect } from "react";
import mainImg from "../../Assests/Services/serviceBanner.png";
import Banner from "../Banner";
import "./service.css";
import { Link } from "react-router-dom";
import arrowImg from "../../Assests/arrowImg.png";
import info1 from "../../Assests/Domains/uiInfo1.png";
import info2 from "../../Assests/Domains/uiInfo2.png";
import info3 from "../../Assests/Domains/uiInfo3.png";
import info4 from "../../Assests/Domains/uiInfo4.png";
import Aos from "aos";
import "aos/dist/aos.css";

function UiDesign() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    Aos.init();
  }, []);

  return (
    <div>
      <div className="aboutUs-main-container">
        <Banner mainImg={mainImg} title="Services" />
        <div className="below-banner-mad-container">
          <div className="product-banner-heading">UI/UX Design</div>
          <div className="service-para">
            In today's digital landscape, providing exceptional user experiences
            is crucial for the success of any application or website. At Aeon,
            we understand the importance of seamless and intuitive interfaces
            that keep users engaged and drive business growth. Our UI/UX Design
            Services are tailored to help organizations create captivating
            digital experiences that resonate with their target audiences
          </div>
        </div>
        <div className="mad-service-second-container">
          <div className="product-banner-heading mad-third-heading dls-second-heading mb-5">
            Our UI/UX Design Approach
          </div>
          <div className="text-start pmc-approach-points-container">
            <div className="pmc-approach-points-heading">
              1. User Research and Analysis
            </div>
            <div className="pmc-approach-points mx-3">
              <span className="pmc-approach-points-label">User Personas: </span>
              <span>
                We conduct in-depth research to understand your target users,
                their goals, behaviors, and preferences. This allows us to
                create user personas that guide the design process.
              </span>
            </div>
            <div className="pmc-approach-points mx-3 mt-3">
              <span className="pmc-approach-points-label">
                User Journey Mapping:{" "}
              </span>
              <span>
                Our designers map out the complete user journey, identifying
                pain points, opportunities, and touchpoints to ensure a cohesive
                and seamless experience.
              </span>
            </div>
          </div>
          <div className="text-start pmc-approach-points-container mt-5">
            <div className="pmc-approach-points-heading">
              2. Conceptualization and Prototyping
            </div>
            <div className="pmc-approach-points mx-3">
              <span className="pmc-approach-points-label">
                Information Architecture:{" "}
              </span>
              <span>
                We design intuitive information architectures that prioritize
                user needs and facilitate easy navigation across your digital
                platforms.
              </span>
            </div>
            <div className="pmc-approach-points mx-3 mt-3">
              <span className="pmc-approach-points-label">Visual Design: </span>
              <span>
                Our talented designers craft visually appealing and
                brand-consistent interfaces that captivate users and enhance
                their overall experience.
              </span>
            </div>
            <div className="pmc-approach-points mx-3 mt-3">
              <span className="pmc-approach-points-label">
                Wireframing and Prototyping:{" "}
              </span>
              <span>
                We create low and high-fidelity wireframes and interactive
                prototypes to validate design concepts and gather user feedback
                early in the process.
              </span>
            </div>
          </div>
          <div className="text-start pmc-approach-points-container mt-5">
            <div className="pmc-approach-points-heading">
              3. Iterative Design and Testing
            </div>
            <div className="pmc-approach-points mx-3">
              <span className="pmc-approach-points-label">
                Usability Testing:{" "}
              </span>
              <span>
                We conduct comprehensive usability testing sessions to identify
                areas for improvement and ensure that our designs meet user
                expectations and accessibility standards.
              </span>
            </div>
            <div className="pmc-approach-points mx-3 mt-3">
              <span className="pmc-approach-points-label">
                {" "}
                Continuous Improvement:{" "}
              </span>
              <span>
                Through an iterative design process, we incorporate user
                feedback and refine the designs to achieve optimal usability and
                engagement.
              </span>
            </div>
          </div>
          <div className="text-start pmc-approach-points-container mt-5">
            <div className="pmc-approach-points-heading">
              4.Implementation and Deployment
            </div>
            <div className="pmc-approach-points mx-3">
              <span className="pmc-approach-points-label">
                Collaboration with Development Teams:{" "}
              </span>
              <span>
                Our designers work closely with your development teams to ensure
                a smooth transition from design to implementation, ensuring that
                the final product accurately reflects the intended user
                experience.
              </span>
            </div>
            <div className="pmc-approach-points mx-3 mt-3">
              <span className="pmc-approach-points-label">
                {" "}
                Style Guides and Design Systems:{" "}
              </span>
              <span>
                We provide comprehensive style guides and design systems to
                maintain consistency across your digital platforms and
                facilitate future updates and expansions.
              </span>
            </div>
          </div>
        </div>
        <div className="below-banner-mad-container">
          <div className="product-banner-heading mad-third-heading dls-second-heading mb-5">
            Our UI/UX Design Expertise
          </div>
          <div className="row px-4 ui-ux-info-container gx-5">
            <div className="col col-lg-3">
              <img src={info1} alt="Mobile App Design" />
              <div>
                <div className="ui-info-title">Mobile App Design</div>
                <div className="ui-info-descrip">
                  We specialize in designing intuitive and visually appealing
                  mobile app experiences that engage users and drive adoption.
                </div>
              </div>
            </div>
            <div className="col col-lg-3">
              <img src={info2} alt="Responsive Web Design" />
              <div>
                <div className="ui-info-title">Responsive Web Design</div>
                <div className="ui-info-descrip">
                  Our designers ensure that your websites and web applications
                  provide consistent and optimized experiences across various
                  devices and screen sizes.
                </div>
              </div>
            </div>
            <div className="col col-lg-3">
              <img src={info3} alt="Accessibility and Inclusive" />
              <div>
                <div className="ui-info-title">Accessibility and Inclusive</div>
                <div className="ui-info-descrip">
                  Design We prioritize accessibility and inclusive design
                  principles to create digital experiences that cater to users
                  with diverse abilities and needs.
                </div>
              </div>
            </div>
            <div className="col col-lg-3">
              <img src={info4} alt="Design Thinking and Innovation" />
              <div>
                <div className="ui-info-title">
                  Design Thinking and Innovation
                </div>
                <div className="ui-info-descrip">
                  We specialize in designing intuitive and visually appealing
                  mobile app experiences that engage users and drive adoption.
                </div>
              </div>
            </div>
          </div>
          <div></div>
          <div className="product-banner-heading text-start dls-second-heading mb-5">
            Why Choose Aeon for UI/UX Design?
          </div>
          <div className="why-sis-container">
            <div className="text-start pmc-approach-points-container">
              <div
                className="pmc-approach-points mx-3 d-flex align-items-start"
                data-aos="fade-right"
                data-aos-duration="200"
              >
                <div className="arrow-point">
                  <img src={arrowImg} alt="arrowImg" />
                </div>
                <span className="service-para">
                  User-Centric Approach: Designs driven by deep user insights
                  for resonating experiences.
                </span>
              </div>
              <div
                className="pmc-approach-points mx-3 d-flex align-items-start mt-4"
                data-aos="fade-right"
                data-aos-duration="500"
              >
                <div className="arrow-point">
                  <img src={arrowImg} alt="arrowImg" />
                </div>
                <span className="service-para">
                  Cross-Functional Collaboration: Seamless teamwork between
                  designers, developers, and stakeholders.
                </span>
              </div>
              <div
                className="pmc-approach-points mx-3 d-flex align-items-start mt-4"
                data-aos="fade-right"
                data-aos-duration="800"
              >
                <div className="arrow-point">
                  <img src={arrowImg} alt="arrowImg" />
                </div>
                <span className="service-para">
                  Industry Expertise: Extensive experience across domains,
                  tailoring solutions to your needs.
                </span>
              </div>
              <div
                className="pmc-approach-points mx-3 d-flex align-items-start mt-4"
                data-aos="fade-right"
                data-aos-duration="1100"
              >
                <div className="arrow-point">
                  <img src={arrowImg} alt="arrowImg" />
                </div>
                <span className="service-para">
                  Cutting-Edge Solutions: Leveraging the latest tools,
                  techniques, and best practices.
                </span>
              </div>
              <div
                className="pmc-approach-points mx-3 d-flex align-items-start mt-4"
                data-aos="fade-right"
                data-aos-duration="1700"
              >
                <div className="arrow-point">
                  <img src={arrowImg} alt="arrowImg" />
                </div>
                <span className="service-para">
                  Quality Commitment: Delivering designs that meet the highest
                  usability, accessibility, and visual standards.
                </span>
              </div>
            </div>
          </div>
          <div className="contact-us-end-container mt-5">
            <span className="service-para">
              Elevate your digital presence and captivate your users with Aeon's
              UI/UX Design Services.
            </span>
            <span className="contact-us-end-text">
              Contact us today to discuss your project requirements and take the
              first step towards creating exceptional digital experiences.
            </span>
            <Link className="contactUs-link" to="/contact-us">
              Contact Us!
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UiDesign;
